import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs';

import { RechercheBase } from '../recherche-base';
import { RechercheBackendValue } from '../../../models/recherche.enum';
import * as rechercheUtils from '../../../recherche-utils';

@Component({
    selector: 'app-recherche-ligne',
    templateUrl: './recherche-ligne.component.html',
    styleUrls: ['./recherche-ligne.component.scss']
})
export class RechercheLigneComponent extends RechercheBase implements OnInit {
    public inputMinMaxLength = 'null';
    public totalCaracteres = 0;

    private _showForm: boolean = false;

    @Input() public set showForm(value: boolean) {
        this._showForm = value;
        if (!value) {
            this.resetValue();
        } else {
            this.specificInitForm();
        }
    }

    public get showForm(): boolean {
        return this._showForm;
    }

    constructor(
        protected store: Store,
    ) {
        super(store);
    }

    public ngOnInit(): void {
        this.subscribeToQueryStringOnChange();
    }

    protected subscribeToQueryStringOnChange() {
        this.rechercheForm.controls.queryString.valueChanges
            .pipe(
                filter((query: string) => query?.length > 0),
                takeUntil(this.destroyed)
            ).subscribe((query: string) => {
                this.rechercheForm.controls.queryString.patchValue(query.toUpperCase(), { emitEvent: false });
            });
    }

    protected specificInitForm(): void {
        this.rechercheForm.controls.includeSig.setValue(true);
        this.rechercheForm.controls.includeSig.markAsDirty();
        this.rechercheForm.controls.includeSig.updateValueAndValidity();
    }

    public onRecherche() {
        if (this.rechercheForm.controls.queryString.value.length > 0) {
            this.showResult = true;
            this.rechercher();
        } else {
            this.resetValue();
        }
    }

    protected rechercher() {
        const rechercheValue = this.rechercheLigneFormatValue(this.rechercheForm.controls.queryString.value);

        this.rechercheForm.controls.queryString.setValue(rechercheValue, { emitEvent: false });

        this.dispatchRecherche(RechercheBackendValue.RECHERCHE_LIGNE);
    }

    private rechercheLigneFormatValue(rechercheValue: string) {
        const separateurType = rechercheUtils.getSeparatorType(rechercheValue);
        if (separateurType) {
            return rechercheUtils.formatSeparatedValue(rechercheValue, separateurType);
        } else {
            return rechercheUtils.formatUnseparatedValue(rechercheValue);
        }
    }
}
