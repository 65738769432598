/* eslint-disable @typescript-eslint/naming-convention */
import { DatePipe } from '@angular/common';
import { Feature, FeatureCollection, MultiPolygon, Polygon } from '@turf/helpers';
import { isEmpty, merge, mergeWith, flatMap, min, pick } from 'lodash';
import { Photo } from './models/photo.model';
import { Projet } from '../features/projet/models/projet.model';
import { StatutPointInspection, StatutPointInspectionNumberValue } from '../features/inspection/models/statut-point-inspection.enum';
import { ProjetTypes, projetTypesMap } from '../features/projet/models';
import { PointInspectionProperties } from '../enums/point-inspection-properties.enum';
import { PointAuditProperties } from '../features/audit/models/point-audit-properties.enum';
import { AnomalieProperties } from '../features/anomalie/models/anomalie-properties.enum';
import { ProjetProperties } from '../enums/projet-properties.enum';
import { StatutProjet, statutProjetName } from '../features/projet/models/statut-projet.enum';
import { v4 as uuidv4 } from 'uuid';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { PhotoBackend } from './models/photo-backend.model';
import { ProjetAuditProperties } from '../features/audit/models/projet-audit-properties.enum';
import { ObjectType } from './enums/photo-object-type.enum';
import {
    ProjetAuditDto,
    PointAuditDto,
    AnomalieBaseDto,
    AnomalieAuditDto,
    ProjetCompletDto,
    PointInspectionDto,
    PoteauBaseDto,
    AnomaliePilotageDto
} from '../core/api/client/models';

export function polygonsToMultipolygon(polygons: Feature[]): MultiPolygon {
    const multipolygon: MultiPolygon = {
        type: 'MultiPolygon',
        coordinates: []
    };
    polygons.forEach(p => {
        multipolygon.coordinates.push((p.geometry as Polygon).coordinates);
    });
    return multipolygon;
}

export function generateProjetAuditFeatures(projetsAudit: ProjetAuditDto[]): FeatureCollection {
    const projetCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };
    projetsAudit?.forEach((projet) => {
        if (projet.geometrie) {
            const feature: Feature = {
                type: 'Feature', geometry: JSON.parse(projet.geometrie as string),
                properties: {
                    [ProjetAuditProperties.assigneA]: projet.assigneA,
                    [ProjetAuditProperties.assigneLe]: projet.assigneLe,
                    [ProjetAuditProperties.assignePar]: projet.assignePar,
                    [ProjetAuditProperties.firme]: projet.firme,
                    [ProjetAuditProperties.geometrie]: projet.geometrie,
                    [ProjetAuditProperties.id]: projet.id,
                    [ProjetAuditProperties.nom]: projet.nom,
                    [ProjetAuditProperties.nombreTotalDePoteaux]: projet.nombreTotalDePoteaux,
                    [ProjetAuditProperties.projetId]: projet.projetId,
                    [ProjetAuditProperties.remarques]: projet.remarques,
                    [ProjetAuditProperties.seuilAudit]: projet.seuilAudit,
                    [ProjetAuditProperties.statut]: projet.statut,
                    [ProjetAuditProperties.statutGlobal]: projet.statutGlobal,
                    [ProjetAuditProperties.typeDeSeuil]: projet.typeDeSeuil
                }
            };
            projetCollection.features.push(feature);
        };
    });

    return projetCollection;
}



export function generateProjetFeatures(projets: Projet[]): FeatureCollection { // TODO DELETE WHEN PDL-655 IS DONE
    const projetCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };
    projets.forEach((projet) => {
        if (projet.geometrie) {
            const feature: Feature = {
                type: 'Feature', geometry: JSON.parse(projet.geometrie as string),
                properties: {
                    [ProjetProperties.projetId]: projet.id,
                    [ProjetProperties.nomProjet]: projet.nom,
                    [ProjetProperties.typeProjet]: projetTypesMap.get(projet.type!.toLowerCase() as ProjetTypes),
                    [ProjetProperties.statut]: statutProjetName.get(projet.statut as StatutProjet),
                    [ProjetProperties.numeroOrdreDeTravail]: projet.numeroOrdreDeTravail,
                    [ProjetProperties.creeLe]: dateTimeChange(projet && projet.creeLe, 'dd/MM/YYYY'),
                    [ProjetProperties.firme]: projet.firme,
                    [ProjetProperties.territoire]: projet.territoire,
                    [ProjetProperties.anneeInspection]: projet.inspectionAnnee,
                    [ProjetProperties.assigneA]: projet.assigneA,
                }
            };
            projetCollection.features.push(feature);
        };
    });

    return projetCollection;
}

export function generateProjetInspectionFeatures(projets: ProjetCompletDto[]): FeatureCollection {
    const projetCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };
    projets?.forEach((projet) => {
        if (projet.geometrie) {
            const projetPolygone: Feature = {
                type: 'Feature', geometry: JSON.parse(projet.geometrie as string),
                properties: {
                    [ProjetProperties.projetId]: projet.id,
                    [ProjetProperties.nomProjet]: projet.nom,
                    [ProjetProperties.typeProjet]: projetTypesMap.get(projet.type!.toLowerCase() as ProjetTypes),
                    [ProjetProperties.statut]: statutProjetName.get(projet.statut as StatutProjet),
                    [ProjetProperties.numeroOrdreDeTravail]: projet.numeroOrdreDeTravail,
                    [ProjetProperties.creeLe]: dateTimeChange(projet?.creeLe.toString(), 'dd/MM/YYYY'),
                    [ProjetProperties.firme]: projet.firme,
                    [ProjetProperties.territoire]: projet.territoire,
                    [ProjetProperties.anneeInspection]: projet.inspectionAnnee,
                    [ProjetProperties.assigneA]: projet.assigneA,
                }
            };
            projetCollection.features.push(projetPolygone);
        };
    });
    return projetCollection;
}

export function generatePointsAuditFeatures(pointsAudit: Partial<PointAuditDto[]>): FeatureCollection {
    const pointsAuditCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };
    pointsAudit.forEach((point) => {
        if (point?.geometrie) {
            const statutsAnomalies = (point?.anomaliesAudit || []).map(anomalie => anomalie.statut);
            const containsAnomalieNonConforme = statutsAnomalies.includes('nonConforme');

            const pointAudit: Feature = {
                type: 'Feature',
                geometry: typeof (point?.geometrie) === 'string' ? JSON.parse(point?.geometrie) : point!.geometrie,
                properties: {
                    [PointAuditProperties.projetAuditId]: point!.projetAuditId,
                    [PointAuditProperties.auditeLe]: point!.auditeLe,
                    [PointAuditProperties.auditePar]: point!.auditePar,
                    [PointAuditProperties.remarque]: point!.remarque,
                    [PointAuditProperties.justification]: point!.justification,
                    [PointAuditProperties.statut]: point!.statut,
                    [PointAuditProperties.statutGlobal]: point!.statutGlobal,
                    [PointAuditProperties.containsAnomalieNonConforme]: containsAnomalieNonConforme,
                    [PointAuditProperties.type]: point!.type,
                    [PointAuditProperties.geometrie]: point!.geometrie,
                    [PointAuditProperties.pointInspectionId]: point!.pointInspectionId,
                    [PointAuditProperties.id]: point!.id,
                    [PointAuditProperties.photos]: point!.photos,
                    [PointAuditProperties.anomaliesAudit]: point!.anomaliesAudit,
                },
            };
            pointsAuditCollection.features.push(pointAudit);
        }
    });
    return pointsAuditCollection;
}

export function generatePointsInspectionFeatures(pointsInspection: Partial<PointInspectionDto[]>): FeatureCollection {
    const pointsInspectionCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };

    pointsInspection.forEach((pointInspection) => {
        if (pointInspection?.geometrie) {
            const inspectionPoint: Feature = {
                type: 'Feature',
                geometry: typeof (pointInspection?.geometrie) === 'string' ? JSON.parse(pointInspection?.geometrie) : pointInspection?.geometrie,
                properties: {
                    [PointInspectionProperties.inspectionId]: pointInspection!.id,
                    [PointInspectionProperties.poteauId]: pointInspection!.poteau && pointInspection!.poteau!.id,
                    [PointInspectionProperties.projetId]: pointInspection!.projetId,
                    [PointInspectionProperties.statut]: pointInspection!.statut,
                    [PointInspectionProperties.justification]: pointInspection!.justification,
                    [PointInspectionProperties.remarque]: pointInspection!.remarque,
                    [PointInspectionProperties.remarquePoteau]: pointInspection!.remarquePoteau,
                    [PointInspectionProperties.inspecteLe]: dateTimeChange(pointInspection!.poteau && pointInspection!.inspecteLe?.toString(), 'dd/MM/YYYY'),
                    [PointInspectionProperties.inspectePar]: pointInspection!.inspectePar,
                    [PointInspectionProperties.codeABarres]: pointInspection!.poteau && pointInspection!.poteau!.codeABarres,
                    [PointInspectionProperties.lclclDistant]: pointInspection!.poteau && pointInspection!.poteau!.lclclDistant,
                    [PointInspectionProperties.lclclPoteau]: pointInspection!.poteau && pointInspection!.poteau!.lclclPoteau,
                    [PointInspectionProperties.proprietaire]: pointInspection!.poteau && pointInspection!.poteau.proprietaire,
                    [PointInspectionProperties.usage]: pointInspection!.poteau && pointInspection!.poteau!.usage,
                    [PointInspectionProperties.materiau]: pointInspection!.poteau && pointInspection!.poteau!.materiau,
                    [PointInspectionProperties.montage]: pointInspection!.poteau && pointInspection!.poteau!.montage,
                    [PointInspectionProperties.classe]: pointInspection!.poteau && pointInspection!.poteau!.classe,
                    [PointInspectionProperties.longueur]: pointInspection!.poteau && pointInspection!.poteau!.longueur,
                    [PointInspectionProperties.hauteurHorsSol]: pointInspection!.poteau && pointInspection!.poteau!.hauteurHorsSol,
                    [PointInspectionProperties.inclinaison]: pointInspection!.poteau && pointInspection!.poteau!.inclinaison,
                    [PointInspectionProperties.anneeInstallation]: dateTimeChange(pointInspection?.poteau?.anneeInstallation?.toString(), 'YYYY'),
                    [PointInspectionProperties.anneeFabrication]: dateTimeChange(pointInspection?.poteau?.anneeFabrication?.toString(), 'YYYY'),
                    [PointInspectionProperties.statutPoteau]: pointInspection!.poteau && pointInspection!.poteau!.statut,
                    [PointInspectionProperties.accessibleCamion]: pointInspection! && pointInspection!.accessibleCamion,
                    [PointInspectionProperties.indEquipementMajeur]: pointInspection! && pointInspection!.indEquipementMajeur!,
                    [PointInspectionProperties.adresseTravaux]: pointInspection!.poteau && pointInspection!.poteau!.adresseTravaux,
                    [PointInspectionProperties.localisation]: pointInspection!.poteau && pointInspection!.poteau!.localisation,
                    [PointInspectionProperties.essence]: pointInspection!.poteau && pointInspection!.poteau!.essence,
                    [PointInspectionProperties.typePosition]: pointInspection!.poteau && pointInspection!.poteau!.typePosition,
                    [PointInspectionProperties.fabricant]: pointInspection!.poteau && pointInspection!.poteau!.fabricant,
                    [PointInspectionProperties.traitementInitial]: pointInspection!.poteau && pointInspection!.poteau!.traitementInitial,
                    [PointInspectionProperties.photos]:
                        !navigator.onLine ? pointInspection?.photos : generatePhotos(pointInspection?.photos ? pointInspection!.photos : [],
                            pointInspection!.id, ObjectType.INSPECTIONS, 'PointInspection'),
                    [PointInspectionProperties.pointsAudit]: generatePointAuditProperties(pointInspection?.pointsAudit as PointAuditDto[]),
                    [PointInspectionProperties.anomalies]: generateAnomaliesProprietes(pointInspection?.anomalies as AnomalieBaseDto[]),
                    [PointInspectionProperties.equipementsMineurs]: generateEquipementsMineursProprietes(pointInspection!),
                    [PointInspectionProperties.malt]: pointInspection!.poteau && pointInspection!.poteau!.malt!, // PDL-1197
                    [PointInspectionProperties.tuteur]: pointInspection!.poteau && pointInspection!.poteau!.tuteur!,
                    [PointInspectionProperties.luminaire]: pointInspection!.poteau && pointInspection!.poteau!.luminaire!,
                    [PointInspectionProperties.jambeDeForce]: pointInspection!.poteau && pointInspection!.poteau!.jambeDeForce!,
                    [PointInspectionProperties.liaisonAerosouterraine]: pointInspection!.poteau && pointInspection!.poteau!.liaisonAerosouterraine!,
                    [PointInspectionProperties.panneau]: pointInspection!.poteau && pointInspection!.poteau!.panneau!,
                    [PointInspectionProperties.modifieLe]: pointInspection!.modifieLe,
                    [PointInspectionProperties.modifiePar]: pointInspection!.modifiePar,
                },
            };
            pointsInspectionCollection.features.push(inspectionPoint);

            const anomaliesCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };
            const anomalies = pointInspection!.anomalies;

            anomalies?.forEach((anomalie) => {
                const anomalieFeature: Feature = {
                    type: 'Feature',
                    geometry: typeof (pointInspection!.geometrie) === 'string' ? JSON.parse(pointInspection!.geometrie) : pointInspection!.geometrie,
                    properties: {
                        [AnomalieProperties.ANOMALIE_ID]: anomalie!.id,
                        [AnomalieProperties.ELEMENT]: anomalie!.element,
                        [AnomalieProperties.TYPE]: anomalie!.type,
                        [AnomalieProperties.CAUSE]: anomalie!.cause,
                        [AnomalieProperties.INDICATEUR_CREATION_AVIS]: anomalie!.indCreationAvis,
                        [AnomalieProperties.DATE_TRANSFERT_SAP]: anomalie!.dateTransfertSap,
                        [AnomalieProperties.REMARQUE]: anomalie!.remarque,
                    }
                };
                anomaliesCollection.features.push(anomalieFeature);
            });
        }
    });
    return pointsInspectionCollection;
}

export function generateAnomaliesFeatures(anomalies: AnomalieBaseDto[]): FeatureCollection {
    const anomaliesCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };
    anomalies?.forEach((anomalie) => {
        const anomalieFeature: Feature = {
            type: 'Feature',
            geometry: typeof anomalie!.geometrie === 'string' ? JSON.parse(anomalie!.geometrie as string) : anomalie!.geometrie,
            properties: {
                [AnomalieProperties.ANOMALIE_ID]: anomalie!.id,
                [AnomalieProperties.ELEMENT]: anomalie!.element,
                [AnomalieProperties.TYPE]: anomalie!.type,
                [AnomalieProperties.CAUSE]: anomalie!.cause,
                [AnomalieProperties.INDICATEUR_CREATION_AVIS]: anomalie!.indCreationAvis,
                [AnomalieProperties.DATE_TRANSFERT_SAP]: anomalie!.dateTransfertSap,
                [AnomalieProperties.REMARQUE]: anomalie!.remarque,
            }
        };
        anomaliesCollection.features.push(anomalieFeature);
    });
    return anomaliesCollection;
}

export function getTypeObjetPopUp(description: object): string {
    if (description.hasOwnProperty(PointInspectionProperties.inspectionId)) {
        return 'PointInspection';
    } else if (description.hasOwnProperty(ProjetProperties.projetId)) {
        return 'Projet';
    } else if (description.hasOwnProperty(AnomalieProperties.ANOMALIE_ID)) {
        return 'Anomalie';
    }
    return '';
}

export function dateTimeChange(timeDate: string | undefined, format?: string): string {
    if (!timeDate || timeDate === 'undefined' || timeDate === 'Inconnu' || Number(timeDate) === 0 || isNaN(Number(timeDate))) {
        return '';
    } else {
        const datepipe: DatePipe = new DatePipe('en-US');
        const formattedDate: string | null = datepipe.transform(timeDate, format);
        if (formattedDate) {
            return formattedDate;
        } else {
            return 'Inconnu';
        };
    };
}

export function generatePointAuditProperties(points: PointAuditDto[]): PointAuditDto[] {
    const pointAuditArray: PointAuditDto[] = [];
    points?.forEach((point) => {
        const pointAuditProperties = {
            [PointAuditProperties.projetAuditId]: point!.projetAuditId,
            [PointAuditProperties.auditeLe]: point!.auditeLe,
            [PointAuditProperties.auditePar]: point!.auditePar,
            [PointAuditProperties.remarque]: point!.remarque,
            [PointAuditProperties.justification]: point!.justification,
            [PointAuditProperties.statut]: point!.statut,
            [PointAuditProperties.type]: point!.type,
            [PointAuditProperties.geometrie]: point!.geometrie,
            [PointAuditProperties.pointInspectionId]: point!.pointInspectionId,
            [PointAuditProperties.id]: point!.id,
            [PointAuditProperties.photos]: point!.photos,
            [PointAuditProperties.anomaliesAudit]: point!.anomaliesAudit,
        };
        pointAuditArray.push(pointAuditProperties as PointAuditDto);
    });
    return pointAuditArray;

}

export function generateAnomaliesProprietes(anomalies: AnomalieBaseDto[]): AnomalieBaseDto[] {
    if (containsSpace(anomalies)) {
        return anomaliesFromObject(anomalies);
    } else {
        return anomaliesFromKnowElement(anomalies);
    }

}

function anomaliesFromObject(anomalies: AnomalieBaseDto[]): AnomalieBaseDto[] {
    const anomalieArray: AnomalieBaseDto[] = [];
    anomalies?.forEach((anomalie) => {
        const anomaliePropriete = {
            [AnomalieProperties.ANOMALIE_ID]: anomalie[AnomalieProperties.ANOMALIE_ID],
            [AnomalieProperties.ELEMENT]: anomalie[AnomalieProperties.ELEMENT],
            [AnomalieProperties.TYPE]: anomalie[AnomalieProperties.TYPE],
            [AnomalieProperties.CAUSE]: anomalie[AnomalieProperties.CAUSE],
            [AnomalieProperties.INDICATEUR_CREATION_AVIS]: booleanToOuiNonNull(anomalie[AnomalieProperties.INDICATEUR_CREATION_AVIS]),
            [AnomalieProperties.PRIORITE]: anomalie[AnomalieProperties.PRIORITE],
            [AnomalieProperties.DATE_TRANSFERT_SAP]: anomalie[AnomalieProperties.DATE_TRANSFERT_SAP]
                ? dateTimeChange(anomalie[AnomalieProperties.DATE_TRANSFERT_SAP].toString(), 'dd/MM/YYYY') : '',
            [AnomalieProperties.REMARQUE]: anomalie[AnomalieProperties.REMARQUE],
            [AnomalieProperties.URGENT]: booleanToOuiNonNull(anomalie[AnomalieProperties.URGENT]),
            [AnomalieProperties.PHOTOS]: generatePhotos(anomalie[AnomalieProperties.PHOTOS]
                ? anomalie[AnomalieProperties.PHOTOS] : [], anomalie[AnomalieProperties.ANOMALIE_ID]!, ObjectType.ANOMALIES, 'Anomalie'),
            [AnomalieProperties.STATUT]: anomalie[AnomalieProperties.STATUT],
            [AnomalieProperties.modifieLe]: anomalie[AnomalieProperties.modifieLe],
            [AnomalieProperties.modifiePar]: anomalie[AnomalieProperties.modifiePar],
        };
        anomalieArray.push(anomaliePropriete as any);
    });
    return anomalieArray;
}

function anomaliesFromKnowElement(anomalies: AnomalieBaseDto[]): AnomalieBaseDto[] {
    const anomalieArray: AnomalieBaseDto[] = [];
    anomalies?.forEach((anomalie) => {
        const anomaliePropriete = {
            [AnomalieProperties.ANOMALIE_ID]: anomalie.id,
            [AnomalieProperties.ELEMENT]: anomalie.element,
            [AnomalieProperties.TYPE]: anomalie.type,
            [AnomalieProperties.CAUSE]: anomalie.cause,
            [AnomalieProperties.INDICATEUR_CREATION_AVIS]: booleanToOuiNonNull(anomalie.indCreationAvis!),
            [AnomalieProperties.PRIORITE]: anomalie.priorite,
            [AnomalieProperties.DATE_TRANSFERT_SAP]: anomalie.dateTransfertSap ? dateTimeChange(anomalie.dateTransfertSap.toString(), 'dd/MM/YYYY') : '',
            [AnomalieProperties.REMARQUE]: anomalie.remarque,
            [AnomalieProperties.URGENT]: booleanToOuiNonNull(anomalie.urgence!),
            [AnomalieProperties.PHOTOS]: generatePhotos(anomalie.photos ? anomalie.photos : [], anomalie.id!, ObjectType.ANOMALIES, 'Anomalie'),
            [AnomalieProperties.STATUT]: anomalie.statut,
            [AnomalieProperties.modifieLe]: anomalie.modifieLe,
            [AnomalieProperties.modifiePar]: anomalie.modifiePar,
        };
        anomalieArray.push(anomaliePropriete as any);
    });
    return anomalieArray;
}

export function containsSpace(obj: any[]) {
    let haveSpace = false;
    if (obj && obj.length) {
        Object.keys(obj[0]).forEach((key: string) => {
            if (key.indexOf(' ') !== -1) {
                haveSpace = true;
            }
        });
    }
    return haveSpace;
}

export function booleanToOuiNonNull(value?: boolean | null): string {
    if (value) {
        return 'Oui';
    } else if (value === undefined || value === null) {
        return '';
    } else {
        return 'Non';
    };
}

export function generatePhotosAnomalieAudit(anomaliesAudit: AnomalieAuditDto[]) {
    const convertedPhotos = anomaliesAudit.map(anomalieAudit => {
        return generatePhotosFromParameters(anomalieAudit.photos, {
            getParentId: () => `${anomalieAudit.pointAuditId}/anomalies/${anomalieAudit.id}`,
            getObjectType: () => ObjectType.POINTS_AUDIT,
            getPhotoId: photo => photo.id,
            alt: 'AnomaliesAudit'
        });
    });

    return flatMap(convertedPhotos, x => x);
}

export function generatePhotosFromParameters(photos: PhotoBackend[], params: {
    getParentId: (photo: PhotoBackend) => string,
    getObjectType: (photo: PhotoBackend) => string,
    getPhotoId: (photo: PhotoBackend) => string,
    alt: string
}
): Photo[] {
    const { getParentId, getObjectType, getPhotoId, alt } = params;
    const photoCollections = photos.map(aPhoto => {
        const basePath = `/${getObjectType(aPhoto)}/${getParentId(aPhoto)}/photos/${getPhotoId(aPhoto)}`;
        const photo: Photo = {
            id: aPhoto.id,
            previewImageSrc: basePath,
            thumbnailImageSrc: `${basePath}/thumbnails`,
            alt,
            title: `${aPhoto.nom} / ${aPhoto.nomOriginal}`,
            nomOriginal: aPhoto.nomOriginal,
            nom: aPhoto.nom,
        };
        return photo;
    });

    return photoCollections;
}

export function generatePhotos(photos: PhotoBackend[], parentId: string, type: ObjectType, alt?: string): Photo[] {
    return generatePhotosFromParameters(photos, {
        getParentId: () => parentId,
        getObjectType: () => type,
        getPhotoId: photo => photo.nom,
        alt
    });
}

export function transformeProprietiesToInspection(feature: any): Partial<PointInspectionDto> {

    const anneeInstallation = feature.properties![PointInspectionProperties.anneeInstallation];
    const anneeFabrication = feature.properties![PointInspectionProperties.anneeFabrication];

    const poteau: PoteauBaseDto = {
        id: feature.properties![PointInspectionProperties.poteauId],
        codeABarres: feature.properties![PointInspectionProperties.codeABarres] ? feature.properties![PointInspectionProperties.codeABarres] : '',
        statut: feature.properties![PointInspectionProperties.statutPoteau] ? feature.properties![PointInspectionProperties.statutPoteau] : '',
        longueur: feature.properties![PointInspectionProperties.longueur] ? feature.properties![PointInspectionProperties.longueur] : '',
        hauteurHorsSol: feature.properties![PointInspectionProperties.hauteurHorsSol] ? feature.properties![PointInspectionProperties.hauteurHorsSol] : null,
        proprietaire: feature.properties![PointInspectionProperties.proprietaire] ? feature.properties![PointInspectionProperties.proprietaire] : null,
        usage: feature.properties![PointInspectionProperties.usage] ? feature.properties![PointInspectionProperties.usage] : null,
        montage: feature.properties![PointInspectionProperties.montage] ? feature.properties![PointInspectionProperties.montage] : null,
        classe: feature.properties![PointInspectionProperties.classe] ? feature.properties![PointInspectionProperties.classe] : '',
        materiau: feature.properties![PointInspectionProperties.materiau] ? feature.properties![PointInspectionProperties.materiau] : null,
        anneeFabrication: anneeFabrication ? dateParse(anneeFabrication ? `01-02-${anneeFabrication}` : anneeFabrication) : 0,
        anneeInstallation: anneeInstallation ? dateParse(anneeInstallation ? `01-02-${anneeInstallation}` : anneeInstallation) : 0,
        inclinaison: feature.properties![PointInspectionProperties.inclinaison] ? feature.properties![PointInspectionProperties.inclinaison] : null,
        typePosition: feature.properties![PointInspectionProperties.typePosition] ? feature.properties![PointInspectionProperties.typePosition] : '',
        geometrie: JSON.stringify(feature.geometry),
        lclclDistant: feature.properties![PointInspectionProperties.lclclDistant] ? feature.properties![PointInspectionProperties.lclclDistant] : '',
        localisation: feature.properties![PointInspectionProperties.localisation] ? feature.properties![PointInspectionProperties.localisation] : '',
        lclclPoteau: feature.properties![PointInspectionProperties.lclclPoteau] ? feature.properties![PointInspectionProperties.lclclPoteau] : '',
        adresseTravaux: feature.properties![PointInspectionProperties.adresseTravaux] ? feature.properties![PointInspectionProperties.adresseTravaux] : '',
        essence: feature.properties![PointInspectionProperties.essence] ? feature.properties![PointInspectionProperties.essence] : null,
        traitementInitial: feature.properties![PointInspectionProperties.traitementInitial] ? feature.properties![PointInspectionProperties.traitementInitial] : null,
        fabricant: feature.properties![PointInspectionProperties.fabricant] ? feature.properties![PointInspectionProperties.fabricant] : null,
        luminaire: feature.properties![PointInspectionProperties.luminaire], // PDL-1197
        malt: feature.properties![PointInspectionProperties.malt],
        jambeDeForce: feature.properties![PointInspectionProperties.jambeDeForce],
        panneau: feature.properties![PointInspectionProperties.panneau],
        tuteur: feature.properties![PointInspectionProperties.tuteur],
        liaisonAerosouterraine: feature.properties![PointInspectionProperties.liaisonAerosouterraine],
    };

    const pointInspection: Partial<PointInspectionDto> = {
        id: feature.properties![PointInspectionProperties.inspectionId],
        poteau: poteau,
        geometrie: JSON.stringify(feature.geometry),
        statut: feature.properties![PointInspectionProperties.statut],
        justification: feature.properties![PointInspectionProperties.justification] &&
            feature.properties![PointInspectionProperties.statut] === StatutPointInspection.ignore ?
            feature.properties![PointInspectionProperties.justification] : '',
        projetId: feature.properties![PointInspectionProperties.projetId],
        accessibleCamion: feature.properties![PointInspectionProperties.accessibleCamion] ? feature.properties![PointInspectionProperties.accessibleCamion] : '',
        indEquipementMajeur: feature.properties![PointInspectionProperties.indEquipementMajeur] ?? '',
        inspecteLe: feature.properties![PointInspectionProperties.inspecteLe] ? dateParse(feature.properties![PointInspectionProperties.inspecteLe]) : 0,
        inspectePar: feature.properties![PointInspectionProperties.inspectePar],
        remarquePoteau: feature.properties![PointInspectionProperties.remarquePoteau],
    };

    return pointInspection;
}

export function parseDate(date: string): number | string { // TODO DELETE IF UNUSED
    if (!date || date === 'Inconnu') {
        return '';
    } else {
        if (date.toString().includes('/')) {
            const nouvelleDate: string = date.split('/').reverse().join('-').concat(`T00:00:00.000-05:00`);
            const epochDate: number = Date.parse(nouvelleDate);
            return epochDate;
        } else if (date.toString().includes('-')) {
            const nouvelleDate: string = date.split('-').reverse().join('-').concat(`T00:00:00.000-05:00`);
            const epochDate: number = Date.parse(nouvelleDate);
            return epochDate;
        } else {
            return date.toString();
        };
    };
}

export function dateParse(date: string): number {
    if (date?.includes('/')) {
        const newDate: string = date.split('/').reverse().join('-').concat(`T00:00:00.000-05:00`);
        const epochDate: number = Date.parse(newDate);
        return epochDate;
    } else if (date?.includes('-')) {
        const newDate: string = date.split('-').reverse().join('-').concat(`T00:00:00.000-05:00`);
        const epochDate: number = Date.parse(newDate);
        return epochDate;
    } else {
        return 0;
    };
}

export function generateEquipementsMineursProprietes(pointInspection: Partial<PointInspectionDto>): any {
    const equipementsMineurs: any = {
        [PointInspectionProperties.malt]: pointInspection!.poteau && pointInspection!.poteau!.malt,
        [PointInspectionProperties.tuteur]: pointInspection!.poteau && pointInspection!.poteau!.tuteur,
        [PointInspectionProperties.luminaire]: pointInspection!.poteau && pointInspection!.poteau!.luminaire,
        [PointInspectionProperties.jambeDeForce]: pointInspection!.poteau && pointInspection!.poteau!.jambeDeForce,
        [PointInspectionProperties.liaisonAerosouterraine]: pointInspection!.poteau && pointInspection!.poteau!.liaisonAerosouterraine,
        [PointInspectionProperties.panneau]: pointInspection!.poteau && pointInspection!.poteau!.panneau,
    };

    return equipementsMineurs;
}

export function generateUuid(): string {
    return uuidv4();
}

export function mergeDeep(original: any, dataToUpdate: any): any {
    return mergeWith(original, dataToUpdate, customizer);
}

function customizer(objValue: any, srcValue: any) {
    if (isEmpty(srcValue)) {
        return srcValue;
    } else {
        merge(objValue, srcValue);
    }
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export function extractFilenameFromHeaders(headers: HttpHeaders): string {
    let filename = '';
    const contentDispositionHeader = headers.get('Content-Disposition');
    if (contentDispositionHeader && contentDispositionHeader.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDispositionHeader);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    return filename;
}

export function saveFile(buffer: any, fileName: string, fileType: string, extension: string) {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName + extension);
}

export function transformToFormData(data: any): FormData {
    const myFormData = new FormData();
    Object.keys(data).forEach((key: string) => {
        if (data[key] !== undefined) {
            myFormData.append(key, data[key]);
        }
    });

    return myFormData;
}

export function extractFileName(response: HttpResponse<Blob>): string {
    const fileName: string = (response.headers.get('content-disposition').split('filename'))[1].split('"')[1];
    return fileName;
}

export function convertNumberToLatitude(degrees: string, direction: string) {
    let dd = Number(degrees.substring(0, 2)) + Number(degrees.substring(2)) / 60;
    if (direction === 'S') {
        dd = dd * -1;
    }
    return dd;
}

export function convertNumberToLongitude(degrees: string, direction: string) {
    let dd = Number(degrees.substring(0, 3)) + Number(degrees.substring(3)) / 60;
    if (direction === 'W') {
        dd = dd * -1;
    }
    return dd;
}

export function getAnomaliePriorite(anomalie: AnomalieBaseDto, anomaliesPilotage: AnomaliePilotageDto[]): string {
    const anomaliePilotage = anomaliesPilotage.find(ap => {
        if (ap.type) {
            return ap.element === anomalie.element &&
                ap.type === anomalie.type &&
                ap.cause === anomalie.cause;
        } else {
            return ap.element === anomalie.element &&
                ap.cause === anomalie.cause;
        }
    }

    ) as AnomaliePilotageDto;

    return getPrioriteMapping(anomaliePilotage.prioriteOFRP);
}

export function getPrioriteMapping(priorite: string): StatutPointInspection {
    switch (priorite) {
        case 'C':
            return StatutPointInspection.anomaliePrioriteC;
        case 'E':
            return StatutPointInspection.anomaliePrioriteE;
        case 'G':
            return StatutPointInspection.anomaliePrioriteG;
        case 'K':
            return StatutPointInspection.anomaliePrioriteK;
        case 'M':
            return StatutPointInspection.anomaliePrioriteM;
        case 'N':
            return StatutPointInspection.anomaliePrioriteN;
        default:
            return priorite as any;
    }
}

export function getStatutPointInspection(projet: ProjetCompletDto, index: number): StatutPointInspection {
    const currentAnomalies = projet.pointInspections[index].anomalies;
    const hasUrgence = currentAnomalies.find(anomalie => anomalie.urgence);

    let statutPriorite: number = min(currentAnomalies.map((anomalie: AnomalieBaseDto) => StatutPointInspectionNumberValue[anomalie.priorite]));
    if (!statutPriorite) {
        statutPriorite = StatutPointInspectionNumberValue.nonInspecte;
    }

    return hasUrgence ? StatutPointInspection.urgence : StatutPointInspectionNumberValue[statutPriorite] as StatutPointInspection;
}

export function valueToSearchInSubObject<T>(selectedValues: (keyof T)[]): { [key: string]: string[]; } {
    const valuesWithDot = selectedValues.filter(value => value.toString().includes('.'));

    return valuesWithDot.reduce((acc, value: any) => {
        const [key, subKey] = value.toString().split('.');
        if (!acc[key]) {
            acc[key] = [subKey];
        } else if (!acc[key].includes(subKey)) {
            acc[key].push(subKey);
        }
        return acc;
    }, {} as { [key: string]: string[] });
}

export function pickDataAndCreateTypeFromArray<T>(object: T[], arrayValue: string[]): Partial<Pick<T, keyof T>>[] {
    // On récupère toutes les "keys" qui compose l'interface
    type TKeys = keyof T;
    // Crée un "type" typescript union ( | ) a partir d'un array de string
    type UnionFromKeys<P extends TKeys[]> = Record<P[number], string>;
    // On récupère les colonnes sélectionnées et on s'assure qu'elle font partie des "keys" de l'interface
    const selectedValues: TKeys[] = arrayValue.map(v => v as TKeys);
    // On crée un type qui correspond au type de l'objet qu'on veut exporter
    type PickType = Pick<T, keyof UnionFromKeys<typeof selectedValues>>;
    // On vérifie si les colonnes sélectionnées contiennent des sous-objets
    const objectToSearch = valueToSearchInSubObject<T>(selectedValues);

    return object.map((ob: any) => ({
        ...pick<PickType>(ob, selectedValues),
        // On récupère les valeurs des sous-objets
        ...Object.entries(objectToSearch).reduce((acc: { [name: string]: any }, [searchKey, searchValue]) => {
            if (ob.hasOwnProperty(searchKey) && Array.isArray(searchValue)) {
                searchValue.forEach(sValue => {
                    acc[`${searchKey}.${sValue}`] = ob[searchKey][sValue];
                });
            }
            return acc;
        }, {})
    }));
}
