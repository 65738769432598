import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { filter, takeUntil } from 'rxjs';

import { BaseComponent } from '../../../shared/components/abstract-base-component';
import {
    canAnnulerProjet,
    canApprouverProjet,
    canAssignerFirmeProjet,
    canAssignerProjet,
    canAssignerProjetAQ,
    canAssignerProjetPourCorrection,
    canCreateProjetAudit,
    canEnvoyerAvis,
    canModifierProjet,
    canRapportCreationAvis,
    canRejeterProjet,
    canValiderProjet,
    getUserRoles
} from '../../../core/store/selectors/user.selectors';
import { PatchProjetInspectionDto, ProjetCompletDto } from '../../../core/api/client/models';
import { selectUserInformation } from '../../../core/store/selectors/userInformation.selector';
import { UtilisateurService } from '../../../services/utilisateur.service';

import { UserRole } from '../../../shared/models/user-roles.model';
import { UserInformation } from '../../../shared/models/user-informations.model';

import { ProjetsListDialog } from '../models/projets-list-dialog.model';
import { MapService } from '../../../map/services/map.service';

import * as ProjetsActions from '../../projets/state/projets.actions';
import { RapportCreationAvisDialogComponent } from '../components/dialog/rapport-creation-avis-dialog/rapport-creation-avis-dialog.component';
import {
    getAnnuleProjetPermission,
    getApprouveProjetPermission,
    getAssigneFirmeProjetPermission,
    getAssigneProjetAQPermission,
    getAssigneProjetPermission,
    getAssigneProjetPourCorrectionPermission,
    getCreateProjetAuditPermission,
    getEnvoyerAvisPermission,
    getModifierProjetPermission,
    getProjetHistoriquePermission,
    getRapportCreationAvisPermission,
    getRejeterProjetPermission,
    getValiderProjetPermission
} from '../../../features/inspection/utils/inspection-permissions.utils';

import { ProjetInspectionActions } from '../models/projets-inspection-actions.enum';
import { StatutProjetsInspection } from '../models/statut-projets-inspection.enum';
import { TaxonomieGroupeValue } from '../../../features/pilotage/models/taxonomie-groupe.enum';
import { AnomalieListComponent } from '../../../features/anomalie/components/anomalie-list/anomalie-list.component';

import { AssignProjetInspectionDialogComponent } from '../components/dialog/assign-projet-inspection-dialog/assign-projet-inspection-dialog.component';
import { ModifyProjetInspectionDialogComponent } from '../components/dialog/modify-projet-inspection-dialog/modify-projet-inspection-dialog.component';
import { CreateProjetAuditDialogComponent } from '../components/dialog/create-projet-audit-dialog/create-projet-audit-dialog.component';
import { RejeterProjetInspectionDialogComponent } from '../components/dialog/rejeter-projet-inspection-dialog/rejeter-projet-inspection-dialog.component';

@Injectable()
export class ProjetInspectionContextualMenu extends BaseComponent {
    public taxonomieGroupes: string[];

    private currentUserRole: UserRole;
    private currentUserInfos: UserInformation;

    private canAssignerFirmeProjet = false;
    private canAssignerProjet = false;
    private canAssignerProjetAQ = false;
    private canRapportCreationAvis = false;
    private canValiderProjet = false;
    private canApprouverProjet = false;
    private canModifierProjet = false;
    private canAnnulerProjet = false;
    private canEnvoyerAvis = false;
    private canCreateProjetAudit = false;
    private canAssignPourCorrection = false;
    private canRejeterProjet = false;

    private disableAssigneProjet = false;
    private disableAssignerProjetPourCorrection = false;
    private disableAssigneProjetAQ = false;
    private disableAssigneFirmeProjet = false;
    private disableAnnuleProjet = false;
    private disableModifierProjet = false;
    private disableValiderProjet = false;
    private disableApprouverProjet = false;
    private disableEnvoyerAvis = false;
    private disableCreateProjetAudit = false;
    private disableRapportCreationAvis = false;
    private disableRejeterProjet = false;
    private disableProjetHistorique = false;


    constructor(
        private store: Store,
        private mapService: MapService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private readonly utilisateurService: UtilisateurService,
        private ref: DynamicDialogRef,
        private readonly dialogService: DialogService,
    ) {
        super();
        this.subscribeToAccessRight();
    }

    public isProjetHistoriqueDisabled(): boolean {
        return this.disableProjetHistorique;
    }

    public subscribeToAccessRight() {
        this.subscribeToGetUserRoles();
        this.subscribeToSelectUserInformations();
        this.subscribeToCanAssignerFirmeProjet();
        this.subscribeToCanAssignerProjet();
        this.subscribeToCanAssignerProjetAQ();
        this.subscribeToCanRapportCreationAvis();
        this.subscribeToCanValiderProjet();
        this.subscribeToCanApprouverProjet();
        this.subscribeToCanModifierProjet();
        this.subscribeToCanAnnulerProjet();
        this.subscribeToCanEnvoyerAvis();
        this.subscribeToCanCreateProjetAudit();
        this.subscribeToCanAssignProjetPourCorrection();
        this.subscribeToCanRejeterProjet();
    }

    public setMenuItemStatusForInspection(selectedProjet: ProjetCompletDto) {
        this.setDisableAssigneProjet(selectedProjet);
        this.setDisableAnnuleProjet(selectedProjet);
        this.setDisableModifierProjet(selectedProjet);
        this.setDisableValiderProjet(selectedProjet);
        this.setDisableRapportCreationAvis(selectedProjet);
        this.setDisableApprouverProjet(selectedProjet);
        this.setDisableRejeterProjet(selectedProjet);
        this.setDisableEnvoyerAvis(selectedProjet);
        this.setDisableCreateProjetAudit(selectedProjet);
        this.setDisableAssigneProjetAQ(selectedProjet);
        this.setDisableAssigneFirmeProjet(selectedProjet);
        this.setDisableAssignerProjetPourCorrection(selectedProjet);
        this.setDisableHistorique(selectedProjet);
    }

    public getZoomSurMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? 'Zoom sur',
            icon: 'pi pi-fw pi-search-plus',
            disabled: false,
            command: () => { this.mapService.zoomProjetInspection(selectedProjet); }
        };
    }

    public getAssignerInspecteurMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? 'Assigner un inspecteur',
            icon: 'pi pi-fw pi-user',
            visible: this.canAssignerProjet,
            disabled: this.disableAssigneProjet,
            command: () => this.assigneProjetConfirm(
                selectedProjet,
                [TaxonomieGroupeValue.INSPECTEURS],
                `Sélectionner l'inspecteur qui réalisera le projet: :`,
                `Assigner un inspecteur`,
                ProjetInspectionActions.ASSIGNER
            )
        };
    }

    public getAssignerPourCorrectionMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? 'Assigner pour correction',
            icon: 'pi pi-fw pi-user',
            visible: this.canAssignPourCorrection,
            disabled: this.disableAssignerProjetPourCorrection,
            command: () => this.assigneProjetConfirm(
                selectedProjet,
                [TaxonomieGroupeValue.INSPECTEURS, TaxonomieGroupeValue.CONTROLEUR_QUALITE],
                `Sélectionner l'utilisateur qui réalisera les corrections :`,
                `Assigner pour correction`,
                ProjetInspectionActions.ASSIGNER_DEMANDE_CORRECTION)
        };
    }

    public getAssignerAQMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? 'Assigner AQ',
            icon: 'pi pi-fw pi-check-circle',
            visible: this.canAssignerProjetAQ,
            disabled: this.disableAssigneProjetAQ,
            command: () => this.assigneProjetConfirm(
                selectedProjet,
                [TaxonomieGroupeValue.CONTROLEUR_QUALITE],
                `Sélectionner le controlleur qualité qui réalisera les travaux :`,
                `Assigner un controlleur qualité`,
                ProjetInspectionActions.ASSIGNER_AQ)
        };
    }

    public getAssignerFirmeMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? 'Assigner une firme',
            icon: 'pi pi-fw pi-reply',
            visible: this.canAssignerFirmeProjet,
            disabled: this.disableAssigneFirmeProjet,
            command: () => this.assigneProjetConfirm(
                selectedProjet,
                [TaxonomieGroupeValue.FIRME],
                `Sélectionner la firme qui réalisera l'inspection :`,
                `Assigner une firme`,
                ProjetInspectionActions.ASSIGNER_FIRME)
        };
    }

    public getAnnulerMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? 'Annuler',
            icon: 'pi pi-times',
            visible: this.canAnnulerProjet,
            disabled: this.disableAnnuleProjet,
            command: () => this.annuleProjetConfirm(selectedProjet)
        };
    }

    public getModifierMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? 'Modifier',
            icon: 'pi pi-pencil',
            visible: this.canModifierProjet,
            disabled: this.disableModifierProjet,
            command: () => this.openModifierProjet(selectedProjet)
        };
    }

    public getValiderMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? 'Valider',
            icon: 'fas fa-check-double',
            visible: this.canValiderProjet,
            disabled: this.disableValiderProjet,
            command: () => this.validerProjetInspection(selectedProjet)
        };
    }

    public getApprouverMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? 'Approuver',
            icon: 'fas fa-flag-checkered',
            visible: this.canApprouveProjet(selectedProjet),
            disabled: this.disableApprouverProjet,
            command: () => this.approuverProjetInspection(selectedProjet)
        };
    }

    public getCreerAvisPrioritaireMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? 'Créer les avis prioritaires',
            icon: 'pi pi-fw pi-exclamation-circle',
            visible: this.canEnvoyerAvis,
            disabled: this.disableEnvoyerAvis,
            command: () => this.creerAvisSap(selectedProjet)
        };
    }

    public getCreerProjetAuditMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? `Créer projet d'audit`,
            icon: 'fas fa-clipboard-check',
            visible: this.canCreateProjetAudit,
            disabled: this.disableCreateProjetAudit,
            command: () => this.openCreateProjetAuditDialog(selectedProjet)
        };
    }

    public getRapportCreationAvisMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? `Rapport de création d'avis`,
            icon: 'pi pi-fw pi-file',
            visible: this.canRapportCreationAvis,
            disabled: this.disableRapportCreationAvis,
            command: () => this.showRapportCreationAvis(selectedProjet)
        };
    }

    public getRapportAnomalieMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? `Rapport d'anomalies`,
            icon: 'pi pi-fw pi-list',
            command: () => {
                this.ref.close(true);
                this.showRapportAnomalie(selectedProjet);
            }
        };
    }

    public getRejeterMenuItem(selectedProjet: ProjetCompletDto, title?: string): MenuItem {
        return {
            label: title ?? 'Rejeter',
            icon: 'pi pi-ban',
            visible: this.canRejeterProjet,
            disabled: this.disableRejeterProjet,
            command: () => this.openRejeterDialog(selectedProjet)
        };
    }

    public getExtraireHistoriqueMenuItem(selectedProjet: ProjetsListDialog, title?: string): MenuItem {
        return {
            label: title ?? `Extraire historique`,
            icon: 'pi pi-fw pi-download',
            disabled: this.disableProjetHistorique,
            command: () => this.extraireProjetInspectionHistorique(selectedProjet)
        };
    }

    public getFullInspectionMenu(selectedProjet: ProjetsListDialog): MenuItem[] {
        return [
            this.getRapportSubMenu(selectedProjet),
            { separator: true },
            this.getZoomSurMenuItem(selectedProjet),
            this.getAssignerInspecteurMenuItem(selectedProjet),
            this.getAssignerPourCorrectionMenuItem(selectedProjet),
            this.getAssignerAQMenuItem(selectedProjet),
            this.getAssignerFirmeMenuItem(selectedProjet),
            this.getAnnulerMenuItem(selectedProjet),
            this.getModifierMenuItem(selectedProjet),
            this.getValiderMenuItem(selectedProjet),
            this.getApprouverMenuItem(selectedProjet),
            this.getCreerAvisPrioritaireMenuItem(selectedProjet),
            this.getCreerProjetAuditMenuItem(selectedProjet),
            this.getRejeterMenuItem(selectedProjet),
            { separator: true },
            this.getExtraireHistoriqueMenuItem(selectedProjet),
        ];
    }

    public getRapportSubMenu(selectedProjet: ProjetsListDialog): MenuItem {
        return {
            label: 'Rapports...',
            items: [
                this.getRapportCreationAvisMenuItem(selectedProjet),
                this.getRapportAnomalieMenuItem(selectedProjet)
            ]
        };
    }

    public getAssignationSubMenu(selectedProjet: ProjetsListDialog): MenuItem[] {
        return [
            this.getAssignerFirmeMenuItem(selectedProjet),
            this.getAssignerInspecteurMenuItem(selectedProjet),
            this.getAssignerAQMenuItem(selectedProjet),
            this.getAssignerPourCorrectionMenuItem(selectedProjet)
        ];
    }

    public getActionSubMenu(selectedProjet: ProjetsListDialog): MenuItem[] {
        return [
            this.getValiderMenuItem(selectedProjet),
            this.getApprouverMenuItem(selectedProjet),
            this.getModifierMenuItem(selectedProjet),
            this.getRejeterMenuItem(selectedProjet),
            this.getAnnulerMenuItem(selectedProjet),
            this.getCreerAvisPrioritaireMenuItem(selectedProjet),
            this.getCreerProjetAuditMenuItem(selectedProjet),
        ];
    }

    /////////////////////////////////////
    //          Subscriptions          //
    /////////////////////////////////////

    private subscribeToGetUserRoles() {
        this.store.select(getUserRoles).pipe(
            filter(userRole => !!userRole),
            takeUntil(this.destroyed)
        ).subscribe(_currentUserRole => this.currentUserRole = _currentUserRole[0]);
    }

    private subscribeToSelectUserInformations() {
        this.store.select(selectUserInformation).pipe(
            filter(userInfos => !!userInfos),
            takeUntil(this.destroyed)
        ).subscribe(_currentUserInfos => this.currentUserInfos = _currentUserInfos);
    }

    private subscribeToCanAssignProjetPourCorrection() {
        this.store.select(canAssignerProjetPourCorrection).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canAssignPourCorrection => this.canAssignPourCorrection = _canAssignPourCorrection);
    }

    private subscribeToCanRejeterProjet() {
        this.store.select(canRejeterProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canRejeterProjet => this.canRejeterProjet = _canRejeterProjet);
    }

    private subscribeToCanAssignerFirmeProjet() {
        this.store.select(canAssignerFirmeProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe((_canAssignerFirme) => this.canAssignerFirmeProjet = _canAssignerFirme);
    }

    private subscribeToCanAssignerProjet() {
        this.store.select(canAssignerProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe((canAssigner) => this.canAssignerProjet = canAssigner);
    }
    private subscribeToCanAssignerProjetAQ() {
        this.store.select(canAssignerProjetAQ).pipe(
            takeUntil(this.destroyed)
        ).subscribe((canAssignerAQ) => this.canAssignerProjetAQ = canAssignerAQ);
    }
    private subscribeToCanRapportCreationAvis() {
        this.store.select(canRapportCreationAvis).pipe(
            takeUntil(this.destroyed)
        ).subscribe((canRapport) => this.canRapportCreationAvis = canRapport);
    }
    private subscribeToCanValiderProjet() {
        this.store.select(canValiderProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe((canValider) => this.canValiderProjet = canValider);
    }
    private subscribeToCanApprouverProjet() {
        this.store.select(canApprouverProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe((canApprouver) => this.canApprouverProjet = canApprouver);
    }
    private subscribeToCanModifierProjet() {
        this.store.select(canModifierProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe((canModifier) => this.canModifierProjet = canModifier);
    }
    private subscribeToCanAnnulerProjet() {
        this.store.select(canAnnulerProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe((canAnnuler) => this.canAnnulerProjet = canAnnuler);
    }
    private subscribeToCanEnvoyerAvis() {
        this.store.select(canEnvoyerAvis).pipe(
            takeUntil(this.destroyed)
        ).subscribe((canEnvoyer) => this.canEnvoyerAvis = canEnvoyer);
    }
    private subscribeToCanCreateProjetAudit() {
        this.store.select(canCreateProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe((canProjetAudit) => this.canCreateProjetAudit = canProjetAudit);
    }

    //////////////////////////////////////////
    //          Disabled functions          //
    //////////////////////////////////////////

    private setDisableAssigneProjet(selectedProjet: ProjetCompletDto) {
        this.disableAssigneProjet = !getAssigneProjetPermission(selectedProjet, this.currentUserRole, this.currentUserInfos);
    }
    private setDisableAnnuleProjet(selectedProjet: ProjetCompletDto) {
        this.disableAnnuleProjet = !getAnnuleProjetPermission(selectedProjet, this.currentUserRole, this.currentUserInfos);
        // Même si l'utilisateur a les permissions pour annuler un projet, dès qu'il y a un point d'inspection inspecté,
        //   on ne permet plus d'annuler le projet
        if (selectedProjet.nombrePoteauxInspectes) {
            this.disableAnnuleProjet = true;
        }
    }
    private setDisableModifierProjet(selectedProjet: ProjetCompletDto) {
        this.disableModifierProjet = !getModifierProjetPermission(selectedProjet, this.currentUserRole, this.currentUserInfos);
    }
    private setDisableValiderProjet(selectedProjet: ProjetCompletDto) {
        this.disableValiderProjet = !getValiderProjetPermission(selectedProjet, this.currentUserRole, this.currentUserInfos);
    }
    private setDisableRapportCreationAvis(selectedProjet: ProjetCompletDto) {
        this.disableRapportCreationAvis = !getRapportCreationAvisPermission(selectedProjet);
    }
    private setDisableApprouverProjet(selectedProjet: ProjetCompletDto) {
        this.disableApprouverProjet = !getApprouveProjetPermission(selectedProjet);
    }
    private setDisableRejeterProjet(selectedProjet: ProjetCompletDto) {
        this.disableRejeterProjet = !getRejeterProjetPermission(selectedProjet);
    }
    private setDisableEnvoyerAvis(selectedProjet: ProjetCompletDto) {
        this.disableEnvoyerAvis = true;
        this.disableEnvoyerAvis = !getEnvoyerAvisPermission(selectedProjet, this.currentUserRole, this.currentUserInfos);
        // Même si l'utilisateur a les permissions pour créer les avis prioritaires, s'il n'y a pas d'anomalie,
        //   on ne lui permet pas de créer les avis
        if (!selectedProjet.nombrePoteauxAvecAnomalie) {
            this.disableEnvoyerAvis = true;
        }
    }
    private setDisableCreateProjetAudit(selectedProjet: ProjetCompletDto) {
        this.disableCreateProjetAudit = !getCreateProjetAuditPermission(selectedProjet);
    }
    private setDisableAssigneProjetAQ(selectedProjet: ProjetCompletDto) {
        this.disableAssigneProjetAQ = !getAssigneProjetAQPermission(selectedProjet);
    }
    private setDisableAssigneFirmeProjet(selectedProjet: ProjetCompletDto) {
        this.disableAssigneFirmeProjet = !getAssigneFirmeProjetPermission(selectedProjet);
    }
    private setDisableAssignerProjetPourCorrection(selectedProjet: ProjetCompletDto) {
        this.disableAssignerProjetPourCorrection = !getAssigneProjetPourCorrectionPermission(selectedProjet);
    }
    private setDisableHistorique(selectedProjet: ProjetCompletDto) {
        this.disableProjetHistorique = !getProjetHistoriquePermission(selectedProjet);
    }

    /////////////////////////////////////////
    //          Actions functions          //
    /////////////////////////////////////////
    private assigneProjetConfirm(projet: ProjetCompletDto, groupes: string[], text: string, header: string, action: ProjetInspectionActions) {
        this.taxonomieGroupes = groupes;
        if ((groupes.length === 1 && groupes[0] === TaxonomieGroupeValue.INSPECTEURS && projet.assigneA) ||
            (groupes.length === 1 && groupes[0] === TaxonomieGroupeValue.CONTROLEUR_QUALITE && projet.assigneAQA)) {
            this.confirmationService.confirm({
                header: `Confirmer l'assignation`,
                message: `Voulez-vous vraiment assigner le projet de nouveau ?<br><br>
                    La réassignation entrainera la perte des données non synchronisées.`,
                icon: 'pi pi-exclamation-triangle',
                rejectLabel: 'Non',
                acceptLabel: 'Oui',
                accept: () => {
                    this.openAssigneProjetDialog(projet, text, header, action);
                },
                key: 'global'
            });
        } else {
            this.openAssigneProjetDialog(projet, text, header, action);
        }
    }

    private annuleProjetConfirm(selectedProjet: ProjetCompletDto) {
        if (selectedProjet.statut === StatutProjetsInspection.enCreation || selectedProjet.statut === StatutProjetsInspection.nouveau ||
            selectedProjet.statut === StatutProjetsInspection.inspectionAssigne || selectedProjet.statut === StatutProjetsInspection.erreur) {
            this.confirmationService.confirm({
                header: `Confirmer l'annulation`,
                message: 'Voulez-vous vraiment annuler le projet ?',
                icon: 'pi pi-exclamation-triangle',
                rejectLabel: 'Non',
                acceptLabel: 'Oui',
                accept: () => {
                    if (selectedProjet.statut === StatutProjetsInspection.nouveau || selectedProjet.statut === StatutProjetsInspection.erreur) {
                        this.dispatchDelete(selectedProjet);
                    } else {
                        this.dispatchCancel(selectedProjet);
                    }
                },
                key: 'global'
            });
        }
    }

    private openModifierProjet(selectedProjet: ProjetCompletDto) {
        this.ref = this.dialogService.open(ModifyProjetInspectionDialogComponent, {
            header: `Modifier le projet`,
            modal: true,
            draggable: false,
            closable: false,
            data: {
                selectedProjet
            }
        });
    }

    private validerProjetInspection(projet: ProjetCompletDto) {
        const validateData: PatchProjetInspectionDto = { action: ProjetInspectionActions.VALIDER };
        this.store.dispatch(ProjetsActions.validateProjetInspection({ projetInspectionId: projet.id, validateData }));
    }

    private canApprouveProjet(selectedProjet: ProjetCompletDto): boolean {
        const isAllowed = this.canApprouverProjet;
        if (isAllowed && this.utilisateurService.isRole(UserRole.TECH_INGENIEURS_RESEAU)) {
            return selectedProjet?.creePar?.toLowerCase() === this.utilisateurService.email?.toLowerCase();
        };
        return isAllowed;
    }

    private approuverProjetInspection(projet: ProjetCompletDto) {
        const approveData: PatchProjetInspectionDto = { action: ProjetInspectionActions.TERMINER };
        this.store.dispatch(ProjetsActions.approveProjetInspection({ projetInspectionId: projet.id, approveData }));
    }

    private creerAvisSap(projet: ProjetCompletDto) {
        this.store.dispatch(ProjetsActions.createAvisSap({ projetId: projet.id }));
    }

    private extraireProjetInspectionHistorique(projet: ProjetCompletDto) {
        this.store.dispatch(ProjetsActions.loadProjetInspectionHistory({ projetInspection: projet }));
    }

    private openCreateProjetAuditDialog(selectedProjet: ProjetCompletDto) {
        this.ref = this.dialogService.open(CreateProjetAuditDialogComponent, {
            header: `Créer un projet d'audit`,
            modal: true,
            draggable: false,
            closable: false,
            data: {
                selectedProjet,
            },
        });
    }

    private showRapportCreationAvis(selectedProjet: ProjetCompletDto) {
        this.ref = this.dialogService.open(RapportCreationAvisDialogComponent,
            {
                header: `Rapport de création d'avis`,
                width: '100%',
                height: '100%',
                modal: false,
                styleClass: 'desktop-dialog',
                data: {
                    selectedProjet
                }
            });
    }

    private showRapportAnomalie(selectedProjet: ProjetCompletDto) {
        this.ref = this.dialogService.open(AnomalieListComponent,
            {
                header: `Rapport d'anomalies - ` + selectedProjet.nom,
                width: '100%',
                height: '45%',
                modal: false,
                maximizable: true,
                styleClass: 'desktop-dialog',
                style: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'align-self': 'flex-start',
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'margin-top': 'var(--spacing-xxl)',
                },
                data: {
                    projet: selectedProjet
                }
            });
    }

    private openRejeterDialog(selectedProjet: ProjetCompletDto) {
        this.ref = this.dialogService.open(RejeterProjetInspectionDialogComponent, {
            header: `Rejeter le projet`,
            modal: true,
            draggable: false,
            closable: false,
            data: {
                selectedProjet,
            },
        });
    }

    private dispatchDelete(selectedProjet: ProjetCompletDto) {
        this.store.dispatch(ProjetsActions.deleteProjetInspection({ projetInspectionId: selectedProjet.id }));
        this.messageService.add(
            {
                severity: 'info',
                closable: true,
                summary: 'Annulation de projet',
                detail: `Le projet ${selectedProjet.nom} sera supprimé`
            });
    }

    private dispatchCancel(selectedProjet: ProjetCompletDto) {
        const cancelData: PatchProjetInspectionDto = { action: ProjetInspectionActions.ANNULER };
        this.store.dispatch(ProjetsActions.cancelProjetInspection({ projetInspectionId: selectedProjet.id, cancelData }));
        this.messageService.add(
            {
                severity: 'info',
                closable: true,
                summary: 'Annulation de projet',
                detail: `Le projet ${selectedProjet.nom} sera annulé`
            });
    }

    private openAssigneProjetDialog(projet: ProjetCompletDto, assignText: string, headerText: string, action: ProjetInspectionActions) {
        if (projet && assignText && action && this.taxonomieGroupes) {
            this.ref = this.dialogService.open(AssignProjetInspectionDialogComponent, {
                header: headerText,
                modal: true,
                draggable: false,
                data: {
                    projet: projet,
                    assignText: assignText,
                    taxonomieGroupes: this.taxonomieGroupes,
                    action: action,
                }
            });
        }
    }

}
