import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../shared/components/abstract-base-component';
import { TableColumn } from '../../../../../shared/models/table-column.model';
import { FinalRecherche, RechercheListe } from '../../../models/recherche-liste.model';
import { FeatureCollection, Feature } from 'geojson';
import { RechercheSource } from '../../../models/recherche.enum';
import { cloneDeep } from 'lodash';
import { MapService } from '../../../../../map/services/map.service';
import { EquipementDto, PointInspectionDto, ProjetCompletDto } from '../../../../../core/api/client/models';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { map, Observable, takeUntil } from 'rxjs';
import { StoreName } from '../../../../offline/models/indexed-db-store-name.enum';
import { Severite } from '../../../../../enums/severite';
import { ConfirmationService, MessageService } from 'primeng/api';
import { getRechercheList } from '../../../state/recherche.selectors';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-recherche-results-cab-lclcl',
    templateUrl: './recherche-results-cab-lclcl.component.html',
    styleUrls: ['./recherche-results-cab-lclcl.component.scss']
})
export class RechercheResultCabLclclComponent extends BaseComponent implements OnInit {
    public colonnes: TableColumn[] = [];
    public liste: any = [];
    public premierePage: number = 0;
    public showNoResult = false;

    public rechercheListe: RechercheListe | null = null;
    public rechercheListe$: Observable<RechercheListe | null> = this.store.select(getRechercheList);

    @Input() public valueHeaderName: string = '';
    @Input() public isLocalSearch = false;
    @Input() public isUserMobile = false;
    @Input() public set rechercheLocalCab(value: string | null) {
        this.liste = [];
        if (value) {
            this.getLocalDbValuesCAB(value);
        } else {
            this.subscribreToRechercheListe();
        }
    }

    @Input() public set rechercheLocalLclcl(value: string | null) {
        this.liste = [];
        if (value) {
            this.getLocalDbValuesLclcl(value);
        } else {
            this.subscribreToRechercheListe();
        }
    }

    constructor(
        private mapService: MapService,
        private readonly dbService: NgxIndexedDBService,
        private messageService: MessageService,
        private store: Store,
        private confirmationService: ConfirmationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.colonnes = [
            { field: 'value', header: this.valueHeaderName }
        ];
        if (!this.isUserMobile) {
            this.colonnes.push({ field: 'territoire', header: 'Territoire' });
        }
        this.colonnes.push({ field: 'source', header: 'Source' });
        this.colonnes.push({ field: 'nomProjet', header: 'Projet' });
    }

    private subscribreToRechercheListe() {
        this.rechercheListe$.pipe(
            takeUntil(this.destroyed)
        ).subscribe((rechercheListe: RechercheListe | null) => {
            if (rechercheListe) {
                this.getRechercheListResultat(rechercheListe);
            }
        });
    }

    private getRechercheListResultat(liste: RechercheListe) {
        this.liste = this.concatAndSortResult(liste);
        this.showNoResult = !(this.liste.length > 0);
    }

    private concatAndSortResult(results: RechercheListe): Feature[] {
        const capture = this.buildRechercheList(results.capture, RechercheSource.CAPTURE)
            .sort((a: Feature, b: Feature) => (a.properties?.value && b.properties?.value)
                ? (a.properties.value >= b.properties.value) ? 1 : -1 : 0);
        const sig = this.buildRechercheList(results.sig, RechercheSource.SIG)
            .sort((a: Feature, b: Feature) => (a.properties?.value && b.properties?.value)
                ? (a.properties.value >= b.properties.value) ? 1 : -1 : 0);
        return capture.concat(sig);
    }

    private buildRechercheList(features: FeatureCollection | undefined, source: string): Feature[] {
        const buildedFeature: Feature[] = [];
        if (features) {
            features.features.forEach((currentFeature: any) => {
                const properties = this.buildProperties(currentFeature, source);
                const copy = cloneDeep(currentFeature);
                copy.properties = properties;
                buildedFeature.push(copy);
            });
        }

        return buildedFeature;
    }

    private getShortSource(source: string): string {
        return source.substring(0, 3);
    }

    private getFirstLetterAndCapital(value: string): string {
        return value.charAt(0).toUpperCase();
    }

    private getSourceValue(features: Feature, source: string) {
        let addDash = false;
        if (features.properties?.sourceRecherche && features.properties?.sourceRecherche !== '') {
            addDash = true;
        }
        let sourceValue = source;
        if (addDash) {
            sourceValue = this.getShortSource(features.properties?.source ?? source);
        }
        return `${sourceValue}${addDash ? '-' + this.getFirstLetterAndCapital(features.properties?.sourceRecherche ?? '') : ''}`;
    }

    private buildProperties(features: Feature, source: string): FinalRecherche {
        return {
            territoire: features.properties?.propriete,
            value: features.properties?.valeur,
            pointInspectionId: features.properties?.pointInspectionId,
            source: this.getSourceValue(features, source),
            nomProjet: features.properties?.nomProjet ?? '',
            sourceRecherche: features.properties?.sourceRecherche ?? '',
        } as FinalRecherche;
    }

    public zoomFeature(event: Feature) {
        this.mapService.zoomByFeature(event, (event?.properties?.pointInspectionId || null));
    }

    private getLocalDbValuesCAB(recharcheCab: string) {
        this.dbService.getAll<ProjetCompletDto>(StoreName.PROJETS).pipe(
            map((projetsDownloaded: ProjetCompletDto[]) => {
                if (!projetsDownloaded?.length) {
                    this.messageErreur();
                    this.liste = [];
                } else {
                    const liste: FeatureCollection = { features: [], type: 'FeatureCollection' };

                    projetsDownloaded.forEach(projet => {
                        const pis = projet.pointInspections?.filter(pi => pi.poteau.codeABarres?.toUpperCase()
                            .includes(recharcheCab.toUpperCase()));

                        pis?.forEach((feature: PointInspectionDto) => {
                            if (feature.poteau && feature.poteau.geometrie) {
                                const pointInspection: Feature = {
                                    type: 'Feature',
                                    geometry: JSON.parse(feature.poteau.geometrie),
                                    properties: {
                                        'valeur': feature.poteau.codeABarres?.toUpperCase(),
                                        'propriete': feature.poteau.territoire,
                                        'pointInspectionId': feature.id,
                                        'nomProjet': projet.nom,
                                    },
                                };
                                liste.features.push(pointInspection);
                            }
                        });
                    });
                    this.liste = this.buildRechercheList(liste, RechercheSource.LOCAL);
                    this.showNoResult = !(this.liste.length > 0);
                }
            })
        ).subscribe();
    }

    private getLocalDbValuesLclcl(recharcheLclcl: string) {
        this.dbService.getAll<ProjetCompletDto>(StoreName.PROJETS).pipe(
            map((projetsDownloaded: ProjetCompletDto[]) => {
                if (!projetsDownloaded?.length) {
                    this.messageErreur();
                    this.liste = [];
                } else {
                    const liste: FeatureCollection = { features: [], type: 'FeatureCollection' };
                    projetsDownloaded.forEach(projet => {
                        if (projet.pointInspections) {
                            liste.features.push(...this.rechercheLocalLclclEquipement(projet, recharcheLclcl, RechercheSource.LOCAL));
                        }
                    });
                    this.liste = this.buildRechercheList(liste, RechercheSource.LOCAL);
                    this.showNoResult = !(this.liste.length > 0);
                }
            })
        ).subscribe();
    }

    private rechercheLocalLclclEquipement(projet: ProjetCompletDto | undefined, recharcheLclcl: string, source: string): Feature[] {
        const liste: Feature[] = [];
        const listeLclcl: string[] = [];

        projet.pointInspections?.forEach(pi => {
            if (pi.poteau.equipements && pi.poteau.equipements.length > 0) {
                pi.poteau.equipements.forEach((equipment: EquipementDto) => {
                    if (equipment.lclcl?.toUpperCase() === recharcheLclcl.toUpperCase()) {
                        if (pi.poteau && pi.poteau.geometrie) {
                            const pointInspection: Feature = {
                                type: 'Feature',
                                geometry: JSON.parse(pi.poteau.geometrie),
                                properties: {
                                    'valeur': equipment.lclcl?.toUpperCase(),
                                    'propriete': pi.poteau.territoire ? pi.poteau.territoire : '',
                                    'pointInspectionId': pi.id,
                                    'nomProjet': projet.nom,
                                    'source': `${source}`,
                                    'sourceRecherche': `equipement`
                                },
                            };
                            liste.push(pointInspection);
                            listeLclcl.push(equipment.lclcl?.toUpperCase());
                        }
                    }
                });
            }
            // Si un lclcl a été entré manuellement, mais qu'il n'est pas déjà inclus dans la listeLclcl, on l'ajoute
            // à la liste des résultats
            if ((pi.poteau.lclclPoteau?.toUpperCase() === recharcheLclcl.toUpperCase() && !listeLclcl.includes(pi.poteau.lclclPoteau?.toUpperCase())) ||
                (pi.poteau.lclclDistant?.toUpperCase() === recharcheLclcl.toUpperCase() && !listeLclcl.includes(pi.poteau.lclclDistant?.toUpperCase()))
            ) {
                const isDistant = pi.poteau.lclclDistant?.toUpperCase() === recharcheLclcl.toUpperCase();
                const pointInspection: Feature = {
                    type: 'Feature',
                    geometry: JSON.parse(pi.poteau.geometrie),
                    properties: {
                        'valeur': isDistant ? recharcheLclcl.toUpperCase() : recharcheLclcl.toUpperCase(),
                        'propriete': pi.poteau.territoire ? pi.poteau.territoire : '',
                        'pointInspectionId': pi.id,
                        'nomProjet': projet.nom,
                        'source': `${source}`,
                        'sourceRecherche': `${isDistant ? 'distant' : 'poteau'}`,
                    },
                };
                liste.push(pointInspection);
            }
        });

        return liste;
    }

    private messageErreur() {
        this.messageService.add({
            severity: Severite.erreur,
            closable: true,
            summary: `Erreur`,
            detail: `Un projet doit être téléchargé pour faire une recherche`
        });
    }

    public onSourceInfoClick(event: Event) {
        const message = `SIG: Sig
            Cap: Capture
            Loc: Local
            Cap-D: Capture-Distant
            Cap-P: Capture-Poteau
            Cap-E: Capture-Equipement
        `;
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            rejectVisible: false,
            icon: 'pi pi-info-circle',
            acceptButtonStyleClass: 'p-button-sm',
            acceptLabel: 'OK',
            header: 'Informations',
            message: `${message}`,
            key: 'recherche-info'
        });
    }
}
