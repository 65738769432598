<p-dialog header="Recherche" (onHide)="onCloseDialog(false)" [(visible)]="showRechercheDialog" position="bottom-right"
    [breakpoints]="$any({'960px': '75vw', '640px': '100vw'})" [style]="{'min-width': 'min(550px, 95%)'}">
    <form [formGroup]="rechercheForm">
        <div class="params__type">
            <div class="p-mb-4 p-d-inline-flex p-ai-end gap">
                <span>Recherche par :</span>
                <p-dropdown [options]="dropdownRechercheList" placeholder="Choisir un élément" appendTo="body"
                    formControlName="layerSelect" class="p-ml-1"></p-dropdown>
            </div>
        </div>
    </form>
    <app-recherche-lclcl [isMobile]="isUserMobile"
        [showForm]="selectedLayer === rechercheConfig.RECHERCHE_LCLCL"></app-recherche-lclcl>
    <app-recherche-cab [isMobile]="isUserMobile"
        [showForm]="selectedLayer === rechercheConfig.RECHERCHE_CODE_BARRES"></app-recherche-cab>
    <app-recherche-ligne [showForm]="selectedLayer === rechercheConfig.RECHERCHE_LIGNE"></app-recherche-ligne>
    <app-recherche-sqrc [showForm]="selectedLayer === rechercheConfig.RECHERCHE_SQRC"></app-recherche-sqrc>

    <app-waiting *ngIf="rechercheIsLoading$ | async" [displayLabel]="true"></app-waiting>
</p-dialog>
