<div class="legend-button">
    <button pButton type="button" label="Réinitialiser" icon="pi pi-undo" (click)="resetLegende()"></button>
</div>
<p-tree [value]="legendeOptions" selectionMode="single">
    <ng-template let-node pTemplate="rect">
        <div class="p-d-flex p-ac-start p-jc-start">
            <p-checkbox [label]="node.label" [binary]="true" [(ngModel)]="node.data.visibilite"
                (onChange)="toggleLayerVisibilite(node)"></p-checkbox>
        </div>
        <div class="p-ml-5"><svg style="height: 20px;width:20px;background-color: rgb(0 0 0 / 5%);">
                <g [attr.fill]="node.data.paint['fill-color']">
                    <rect x="0" y="0" width="20" height="20" />
                </g>
            </svg>
        </div>
    </ng-template>
    <ng-template let-node pTemplate="circle">
        <div class="p-d-flex p-ac-start p-jc-start">
            <p-checkbox [label]="node.label" [binary]="true" [(ngModel)]="node.data.visibilite"
                (onChange)="toggleLayerVisibilite(node)">
            </p-checkbox>
        </div>
        <div class="p-ml-5">
            <svg style="height:20px;width:20px;background-color: rgb(0 0 0 / 1%);">
                <g [attr.fill]="node.data.paint['circle-color']" [attr.stroke]="node.data.paint['circle-stroke-color']"
                    [attr.stroke-width]="node.data.paint['circle-stroke-width']">
                    <circle cx="9" cy="9" [attr.r]="node.data.paint['circle-radius']"></circle>
                </g>
            </svg>
        </div>
    </ng-template>
    <ng-template let-node pTemplate="symbol">
        <div class="p-d-flex p-ac-start p-jc-start">
            <p-checkbox [label]="node.label" [binary]="true" [(ngModel)]="node.data.visibilite"
                (onChange)="toggleLayerVisibilite(node)">
            </p-checkbox>
        </div>
        <div class="p-ml-5">
            <ng-template ngFor let-image [ngForOf]="node.data.src">
                <img [src]="image" [style]="node.data.cssText" class="legend-icon" />
            </ng-template>
        </div>
    </ng-template>
</p-tree>
