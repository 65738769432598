import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../shared/components/abstract-base-component';
import { TableColumn } from '../../../../../shared/models/table-column.model';
import { BBox, bbox, } from '@turf/turf';
import { MapService } from '../../../../../map/services/map.service';
import { LngLatBoundsLike, } from 'mapbox-gl';
import { FeatureCollection } from 'geojson';

@Component({
    selector: 'app-recherche-results-sqrc',
    templateUrl: './recherche-results-sqrc.component.html',
    styleUrls: ['./recherche-results-sqrc.component.scss']
})
export class RechercheResultSqrcComponent extends BaseComponent implements OnInit {
    public colonnes: TableColumn[] = [];
    public liste: { value: string, data: FeatureCollection }[] = [];
    public premierePage: number = 0;
    public showNoResult = false;

    @Input() public query: string;

    private _recherche: FeatureCollection | null = null;
    @Input() public set recherche(value: FeatureCollection | null) {
        this._recherche = value;
        if (value) {
            this.buildTableData(this.recherche);
        }
    };

    public get recherche(): FeatureCollection | null {
        return this._recherche;
    }

    private _valueHeaderName: string = '';
    @Input() public set valueHeaderName(value: string) {
        this._valueHeaderName = value;
    }

    public get valueHeaderName(): string {
        return this._valueHeaderName;
    }

    constructor(
        private mapService: MapService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.colonnes = [
            { field: 'value', header: this.valueHeaderName },
        ];
    }

    private buildTableData(data: FeatureCollection | null) {
        if (data.features.length > 0) {
            this.showNoResult = false;
            this.liste = [{
                value: this.query,
                data: data
            }];
        } else {
            this.liste = [];
            this.showNoResult = true;
        }
    }

    private clearDrawing() {
        this.mapService.draw.deleteAll();
    }

    public zoomFeature(event: any) {
        this.clearDrawing();
        const sqrcFeature = event.data.data as FeatureCollection;
        if (sqrcFeature.features.length > 0) {
            const bounds: BBox = bbox(sqrcFeature);
            this.mapService.map.fitBounds(bounds as LngLatBoundsLike, { padding: 225 });
            this.mapService.draw.add(sqrcFeature as FeatureCollection);
        }
    }

    public onUnselectRow() {
        this.clearDrawing();
    }
}
