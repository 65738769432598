import { createAction, props, union } from '@ngrx/store';
import { AnomalieList } from '../../models/anomalie-list.model';
import { PhotoExtraction } from '../../models/photo-extraction.model';

export enum AnomalieListActionTypes {
    LOAD_ANOMALIE_LIST = '[Anomalie List] Load Anomalie List',
    LOAD_ANOMALIE_LIST_SUCCESS = '[Anomalie List] Load Anomalie List Success',
    LOAD_ANOMALIE_LIST_FAILURE = '[Anomalie List] Load Anomalie List Failure',
    LOAD_STATUT_PHOTO_EXTRACTION = '[Anomalie List] Load Statut Photo Extraction',
    LOAD_STATUT_PHOTO_EXTRACTION_SUCCESS = '[Anomalie List] Load Statut Photo Extraction Success',
    LOAD_STATUT_PHOTO_EXTRACTION_FAILURE = '[Anomalie List] Load Statut Photo Extraction Failure',
    CREATE_PHOTO_EXTRACTION = '[Anomalie List] Create Photos Extraction',
    CREATE_PHOTO_EXTRACTION_SUCCESS = '[Anomalie List] Create Photo Extraction Success',
    CREATE_PHOTO_EXTRACTION_FAILURE = '[Anomalie List] Create Photo Extraction Failure'
}

/*** LOAD ANOMALIE LIST */
export const loadAnomalieList = createAction(AnomalieListActionTypes.LOAD_ANOMALIE_LIST, props<{ projetId: string }>());

export const loadAnomalieListSuccess = createAction(AnomalieListActionTypes.LOAD_ANOMALIE_LIST_SUCCESS, props<{ anomalieList: AnomalieList[] }>());

export const loadAnomalieListFailure = createAction(AnomalieListActionTypes.LOAD_ANOMALIE_LIST_FAILURE, props<{ error: any }>());

/*** LOAD STATUT PHOTO EXTRACTION */
export const loadStatutPhotoExtraction = createAction(AnomalieListActionTypes.LOAD_STATUT_PHOTO_EXTRACTION, props<{ projetId: string }>());

export const loadStatutPhotoExtractionSuccess = createAction(AnomalieListActionTypes.LOAD_STATUT_PHOTO_EXTRACTION_SUCCESS,
    props<{ statutPhotoExtraction: PhotoExtraction }>());

export const loadStatutPhotoExtractionFailure = createAction(AnomalieListActionTypes.LOAD_STATUT_PHOTO_EXTRACTION_FAILURE, props<{ error: any }>());

/*** CREATE PHOTO EXTRACTION */
export const createPhotoExtraction = createAction(AnomalieListActionTypes.CREATE_PHOTO_EXTRACTION, props<{ projetId: string }>());

export const createPhotoExtractionSuccess = createAction(AnomalieListActionTypes.CREATE_PHOTO_EXTRACTION_SUCCESS, props<{ photoExtraction: PhotoExtraction }>());

export const createPhotoExtractionFailure = createAction(AnomalieListActionTypes.CREATE_PHOTO_EXTRACTION_FAILURE, props<{ error: any }>());

const anomalieListActions = union({
    loadAnomalieList: loadAnomalieList,
    loadAnomalieListSuccess: loadAnomalieListSuccess,
    loadAnomalieListError: loadAnomalieListFailure,

    loadStatutPhotoExtraction: loadStatutPhotoExtraction,
    loadStatutPhotoExtractionSuccess: loadStatutPhotoExtractionSuccess,
    loadStatutPhotoExtractionError: loadStatutPhotoExtractionFailure,

    createPhotoExtraction: createPhotoExtraction,
    createPhotoExtractionSuccess: createPhotoExtractionSuccess,
    createPhotoExtractionError: createPhotoExtractionFailure,
});

export type AnomalieListActions = typeof anomalieListActions;
