<p-table *ngIf="liste !== null" [columns]="colonnes" [value]="liste" [(first)]="premierePage" responsiveLayout="scroll"
    selectionMode='single' [paginator]="liste.length > 0" [rows]="6" [showCurrentPageReport]="false"
    (onRowSelect)="zoomFeature($event.data)">
    <ng-template pTemplate="header" let-columns>
        <tr *ngIf="liste.length > 0">
            <th *ngFor="let col of columns " class="p-flex-column">
                <div class="header">
                    <div>{{col.header}}</div>
                    <div *ngIf="col.field === 'source' && valueHeaderName === 'LCLCL'" class="container-info">
                        <button class="info-button" type="button" pButton pRipple icon="pi pi-info-circle"
                            (click)="onSourceInfoClick($event)"></button>
                    </div>
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns" class="p-flex-column">
                {{rowData.properties[col.field]}}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length" *ngIf="showNoResult">
                Aucun résultat ne correspond à votre recherche
            </td>
        </tr>
    </ng-template>
</p-table>
<p-confirmDialog key="recherche-info"></p-confirmDialog>
