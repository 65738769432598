<div *ngIf="anomalieList.length" (onHide)="closeAnomalieListDialog()">
    <p-table dataKey="id" editMode="row" #dt [autoLayout]="true" [value]="anomalieList" [columns]="columns"
        [rowHover]="false" [scrollable]="true" [paginator]="true" [rows]="15"
        [rowsPerPageOptions]="[15,30,45, { showAll: 'Tous' }]" paginatorDropdownAppendTo="body"
        [sortField]="defaultSortField" [sortOrder]="defaultSortOrder" [styleClass]="isMaximize ? '' : 'anomalie-table'">
        <ng-template pTemplate="header">
            <tr>
                <th scope="col" *ngFor="let col of columns" [style.width]="col.width" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Trier"
                        aria-label-desc="Trier en ordre descendant" aria-label-asc="Trier en ordre ascendant">
                    </p-sortIcon>
                </th>
            </tr>
            <tr>
                <th scope="col" *ngFor="let col of columns" [ngSwitch]="col.field">
                    <ng-container *ngIf="col.filterable">
                        <p-dropdown *ngSwitchCase="'priorite'" appendTo="body" [options]="prioriteItems"
                            autoWidth="false" autoWidth="false" [style]="{'minWidth':'140px', 'width': '140px'}"
                            placeholder="Sélectionner une priorité"
                            (onChange)="dt.filter($event.value, col.field, 'equals')" styleClass="p-column-filter"
                            pTooltip="Sélectionner une priorité" tooltipPosition="top" [showClear]="true">
                        </p-dropdown>
                        <p-dropdown
                            *ngSwitchCase="col.field === 'urgence' || col.field === 'indCreationAvis' ? col.field : '' "
                            appendTo="body" [options]="yesNoItems" autoWidth="false" autoWidth="false"
                            [style]="{'minWidth':'90px', 'width': '90px'}" placeholder="Sélectionner oui ou non"
                            (onChange)="dt.filter($event.value, col.field, 'equals')" styleClass="p-column-filter"
                            pTooltip="Sélectionner oui ou non" tooltipPosition="top" [showClear]="true">
                        </p-dropdown>
                        <input *ngSwitchDefault pInputText type="text" styleClass="p-column-filter"
                            (input)="dt.filter($any($event.target).value, col.field, 'contains')" placeholder="Filtrer"
                            aria-placeholder="Filtrer">
                    </ng-container>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pTooltip]="rowData.statut ? statutAnomalieLabel.StatutEnum.Supprime : null" tooltipPosition="top"
                [pEditableRow]="rowData" [ngClass]="{ deleted: rowData.statut }">
                <td *ngFor="let col of columns" [style.width]="col.width" [style.text-align]="col.textAlign">
                    <ng-container [ngSwitch]="col.field">
                        <span *ngSwitchCase="'priorite'">{{this.priorite[rowData.priorite]}}</span>
                        <ng-container *ngSwitchCase="'photos'">
                            <div *ngIf="rowData.photosAnomalie.length || rowData.photosPoteau.length">
                                <span>
                                    <i class="pi pi-images"
                                        (click)="onOpenPhotoListDialog(rowData.photosAnomalie, rowData.photosPoteau)">
                                    </i>
                                </span>
                                {{ rowData.photosAnomalie.length + rowData.photosPoteau.length }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'urgence'">
                            <span *ngIf="rowData.urgence">
                                <i class="pi pi-check"></i>
                            </span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'indCreationAvis'">
                            <span *ngIf="rowData.indCreationAvis">
                                <i class="pi pi-check"></i>
                            </span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'dateTransfertSap'">
                            <span *ngIf="rowData.dateTransfertSap">
                                {{ rowData.dateTransfertSap | date: 'YYYY-MM-dd'}}
                            </span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'prioriteAnalyse'">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-dropdown *ngIf="prioriteAnalyseItems.length" [options]="prioriteAnalyseItems"
                                        [(ngModel)]="rowData.prioriteAnalyse" [autoDisplayFirst]="false"
                                        appendTo="body"></p-dropdown>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.prioriteAnalyse}}
                                </ng-template>
                            </p-cellEditor>
                        </ng-container>
                        <ng-container *ngSwitchCase="'remarqueAnalyse'">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData[col.field]">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.remarqueAnalyse}}
                                </ng-template>
                            </p-cellEditor>
                        </ng-container>
                        <ng-container *ngSwitchCase="'modification'">
                            <div class="flex align-items-center justify-content-center gap-2">
                                <button pButton *ngIf="!editing" [disabled]="rowData.dateTransfertSap > 0" pRipple
                                    type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(rowData)"
                                    class="p-button-rounded p-button-text"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow
                                    icon="pi pi-check" (click)="onRowEditSave(rowData, rowData.pointInspection)"
                                    class="p-button-rounded p-button-text p-button-success mr-2"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow
                                    icon="pi pi-times" (click)="onRowEditCancel(rowData)"
                                    class="p-button-rounded p-button-text p-button-danger"></button>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'actions'">
                            <div>
                                <button pButton pRipple class="p-button-rounded p-button-secondary"
                                    icon="pi pi-search-plus"
                                    (click)="onZoomPointInspection(rowData.pointInspection)"></button>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{rowData[col.field]}}
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div *ngIf="!anomalieList.length && !anomalieListLoading">
    Aucune anomalie pour le projet sélectionné.
</div>

<p-dialog header="Liste des photos" [(visible)]="photoListDialogVisible" [modal]="true" [maximizable]="false">
    <ul>
        <p *ngIf="photoAnomalieList.length"><b>Anomalie</b></p>
        <li *ngFor="let photoAnomalie of photoAnomalieList">
            {{ photoAnomalie.nom }}
        </li>
        <p *ngIf="photoPoteauList.length"><b>Poteau</b></p>
        <li *ngFor="let photoPoteau of photoPoteauList">
            {{ photoPoteau.nom }}
        </li>
    </ul>
    <p-footer>
        <p-button (click)="onClosePhotoListDialog()" label="Fermer"></p-button>
    </p-footer>
</p-dialog>
