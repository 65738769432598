import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { RechercheBase } from '../recherche-base';
import { HQValidators } from '../../../../../shared/validators/hq-validators';
import { RechercheBackendValue } from '../../../models/recherche.enum';

@Component({
    selector: 'app-recherche-cab',
    templateUrl: './recherche-cab.component.html',
    styleUrls: ['./recherche-cab.component.scss']
})
export class RechercheCabComponent extends RechercheBase implements OnInit {
    public inputMinMaxLength = '6';
    public totalCaracteres = 6;
    private _showForm: boolean = false;

    @Input() public set isMobile(value: boolean) {
        this.isUserMobile = value;
    }

    @Input() public set showForm(value: boolean) {
        this._showForm = value;
        if (!value) {
            this.resetValue();
        }
    }

    public get showForm(): boolean {
        return this._showForm;
    }

    constructor(
        protected store: Store,
    ) {
        super(store);
    }

    public ngOnInit(): void {
        this.subscribeToQueryStringOnChange();
        this.subscribeToIncludeSigOnChange();
    }

    protected specificInitForm(): void {
        this.rechercheForm.controls.queryString.setValidators([
            Validators.maxLength(this.totalCaracteres),
            Validators.minLength(this.totalCaracteres),
            HQValidators.codeabarres()
        ]);
        this.rechercheForm.controls.queryString.updateValueAndValidity();
    }

    protected rechercher() {
        this.dispatchRecherche(RechercheBackendValue.RECHERCHE_CODE_BARRES);
    }
}
