import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnomalieListState } from './anomalie-list.state';

const getAnomalieListFeatureState = createFeatureSelector<AnomalieListState>('anomalieList');

export const getAnomalieList = createSelector(
    getAnomalieListFeatureState,
    state => state.anomalieList
);

export const getAnomalieListLoading = createSelector(
    getAnomalieListFeatureState,
    state => state.anomalieListLoading
);

export const getStatutPhotoExtraction = createSelector(
    getAnomalieListFeatureState,
    state => state.statutPhotoExtraction
);

export const getStatutPhotoExtractionLoading = createSelector(
    getAnomalieListFeatureState,
    state => state.statutPhotoExtractionLoading
);

export const getCreatePhotoExtraction = createSelector(
    getAnomalieListFeatureState,
    state => state.photoExtraction
);

export const getCreatePhotoExtractionLoading = createSelector(
    getAnomalieListFeatureState,
    state => state.photoExtractionLoading
);


