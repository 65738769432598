import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjetService } from '../../../core/api/client/services';
import { dateTimeChange } from '../../../shared/utils';
import { AnomalieList } from '../models/anomalie-list.model';
import { PhotoExtraction } from '../models/photo-extraction.model';
import { StatutPhotoExtraction } from '../models/statut-photo-extraction.enum';
import { ProjetCompletDto, ProjetStatutExtractionPhotosDto } from '../../../core/api/client/models';
//import { pointInspection } from '../../../map/models/layers/point-inspection.const';

@Injectable({
    providedIn: 'root'
})
export class AnomalieService {
    constructor(
        private projetApiService: ProjetService
    ) { }

    public getAnomalies(params: ProjetService.GetApiV1ProjetsIdParams): Observable<AnomalieList[]> {
        return this.projetApiService.getApiV1ProjetsId(params)
            .pipe(
                map(data => this.formatAnomaliesList(data)));
    }

    public createPhotoExtraction(projetId: string): Observable<PhotoExtraction> {
        return this.projetApiService.postApiV1ProjetsIdExtractionPhotos(projetId)
            .pipe(
                map((data: ProjetStatutExtractionPhotosDto) => {
                    return {
                        projetId: data.projetId,
                        statut: (StatutPhotoExtraction.StatutEnum as any)[data.statut!],
                    };
                })
            );
    }

    public getStatutPhotoExtraction(projetId: string): Observable<PhotoExtraction> {
        return this.projetApiService.getApiV1ProjetsIdExtractionPhotos(projetId)
            .pipe(
                map(data => {
                    return {
                        projetId: data.projetId,
                        statut: (StatutPhotoExtraction.StatutEnum as any)[data.statut!],
                        dateExtraction: data.dateExtraction ? dateTimeChange(data.dateExtraction!.toString(), 'YYYY-MM-dd') : '',
                        heureExtraction: data.dateExtraction ? dateTimeChange(data.dateExtraction!.toString(), 'H:mm') : '',
                        url: data.url ? data.url : ''
                    };
                })
            );
    }

    private formatAnomaliesList(data: ProjetCompletDto): AnomalieList[] {
        const anomalieList: AnomalieList[] = [];

        data.pointInspections!
            .filter(item => item.anomalies?.length)
            .map(item => {
                item.anomalies?.map(anomalie => {
                    return anomalieList.push({
                        id: anomalie.id,
                        projetId: item.projetId,
                        pointInspectionId: item.id,
                        pointInspection: item,
                        geometrie: item.geometrie,
                        codeABarres: item.poteau?.codeABarres,
                        lclclDistant: item.poteau?.lclclDistant,
                        lclclPoteau: item.poteau?.lclclPoteau,
                        element: anomalie.element,
                        type: anomalie.type,
                        cause: anomalie.cause,
                        urgence: anomalie.urgence,
                        remarque: anomalie.remarque,
                        priorite: anomalie.priorite,
                        statut: anomalie.statut,
                        photos: anomalie.photos,
                        photosAnomalie: anomalie.photos?.map(photo => {
                            return {
                                nom: photo.nom
                            };
                        }),
                        photosPoteau: item.photos?.map(photo => {
                            return {
                                nom: photo.nom
                            };
                        }),
                        dateTransfertSap: anomalie.dateTransfertSap,
                        indCreationAvis: anomalie.indCreationAvis,
                        prioriteAnalyse: anomalie.prioriteAnalyse,
                        remarqueAnalyse: anomalie.remarqueAnalyse,
                    });
                });
            });
        return anomalieList;
    }
}
