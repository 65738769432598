<div class="action-sheet" [@openClose]="actionSheetOpen">
    <div class="action-sheet__header">
        <div class="header__info">
            <div class="info__label">{{ overGridLabel }}</div>
            <div class="info__action">
                <button pButton type="button" (click)="changeDrawerStatus()" [@rotate]="iconState">
                    <i class="fas fa-angle-down"></i>
                </button>
            </div>
            <div></div>
        </div>
    </div>
    <div class="action-sheet__content">
        <ng-container *ngIf="displaySubContent">
            <ng-content></ng-content>
        </ng-container>
        <ng-container *ngIf="!displaySubContent">
            <div class="content__column">
                <div class="column__item justify-content-start" [style]="{'background-color': topLeftBGColor}">
                    <p-button type="button" styleClass="actionSheetButtonBetween" [disabled]="topLeftDisabled"
                        [class.notAllowed]="topLeftDisabled" (click)="topLeftClicked()">
                        <span class="text">{{ topLeftLabel }}</span>
                        <i *ngIf="topLeftIcon" class="fa-solid fa-clipboard-question leftIcon"></i>
                    </p-button>
                </div>
                <div class="column__item justify-content-start" [style]="{'background-color': bottomLeftBGColor}">
                    <p-button type="button" styleClass="actionSheetButtonBetween" [disabled]="bottomLeftDisabled"
                        [class.notAllowed]="bottomLeftDisabled" (click)="bottomLeftClicked()">
                        <span class="text">{{ bottomLeftLabel }}</span>
                        <i *ngIf="bottomLeftIcon" class="fa-solid fa-clipboard-question leftIcon"></i>
                    </p-button>
                </div>
            </div>
            <div class="content__column">
                <div class="column__item justify-content-end" [style]="{'background-color': topRightBGColor}">
                    <p-button type="button" styleClass="actionSheetButtonEnd" [disabled]="topRightDisabled"
                        [class.notAllowed]="topRightDisabled" (click)="topRightClicked()">
                        <span class="text">{{ topRightLabel }}</span>
                    </p-button>
                </div>
                <div class="column__item justify-content-end" [style]="{'background-color': bottomRightBGColor}">
                    <p-button type="button" styleClass="actionSheetButtonEnd" [disabled]="bottomRightDisabled"
                        [class.notAllowed]="bottomRightDisabled" (click)="bottomRightClicked()">
                        <span class="text">{{ bottomRightLabel }}</span>
                    </p-button>
                </div>
            </div>
            <div class="content__center-button" *ngIf="centerDisplay">
                <div class="center-button__container" [style]="{'background-color': centerBGColor}">
                    <p-button type="button" class="container__button" styleClass="actionSheetRoundButtonCenter"
                        [disabled]="centerDisable" [class.notAllowed]="centerDisable" (click)="centerClicked()">
                        <span class="text">{{ centerLabel }}</span>
                    </p-button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
