<div *ngIf="showForm" class="params__value">
    <form [formGroup]="rechercheForm">
        <div class="value__texte">
            <div class="texte__input">
                <span class="p-input-icon-left p-ml-auto" [style]="{width: '100%'}">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" [minlength]="inputMinMaxLength" [maxlength]="inputMinMaxLength"
                        [placeholder]="placeholder" formControlName="queryString" autofocus>
                </span>
            </div>

            <div class="texte__aide">
                <small class="aide__container">
                    <div>
                        <div
                            *ngIf="!displayError || (displayError && rechercheForm.controls.queryString.errors === null)">
                            Min. de {{totalCaracteres}} car.
                        </div>
                        <div *ngIf="displayError && rechercheForm.controls.queryString.errors?.noLength" class="error">
                            La longueur doit être de 14 caractères
                        </div>
                        <div *ngIf="displayError && rechercheForm.controls.queryString.errors?.noLetter" class="error">
                            Le 3 ème car. doit être une lettre
                        </div>
                        <div *ngIf="displayError && rechercheForm.controls.queryString.errors?.noDash" class="error">
                            Le 6 ème car. doit être un tiret
                        </div>
                        <div *ngIf="displayError && rechercheForm.controls.queryString.errors?.noDash10" class="error">
                            Le 10 ème car. doit être un tiret
                        </div>
                        <div *ngIf="displayError && rechercheForm.controls.queryString.errors?.gt4_10" class="error">
                            Le 11 ème car. doit être inferieur à 4
                        </div>
                        <div *ngIf="displayError && rechercheForm.controls.queryString.errors?.block1_01" class="error">
                            Le 11 ème et 12 ème car. doivent être supérieur ou égale à 01
                        </div>
                        <div *ngIf="displayError && rechercheForm.controls.queryString.errors?.block1_40" class="error">
                            Le 11 ème et 12 ème car. doivent être inferieur ou égale à 40
                        </div>
                        <div *ngIf="displayError && rechercheForm.controls.queryString.errors?.gt4_12" class="error">
                            Le 13 ème car. doit être inferieur à 4
                        </div>
                        <div *ngIf="displayError && rechercheForm.controls.queryString.errors?.block2_01" class="error">
                            Le 13 ème et 14 ème car. doivent être supérieur ou égale à 01
                        </div>
                        <div *ngIf="displayError && rechercheForm.controls.queryString.errors?.block2_40" class="error">
                            Le 13 ème et 14 ème car. doivent être inferieur ou égale à 40
                        </div>
                    </div>
                    <span>{{rechercheForm.controls.queryString.value.length}}/{{totalCaracteres}}</span>
                </small>
            </div>
        </div>
    </form>
    <app-recherche-results-sqrc *ngIf="showResult" valueHeaderName="SQRC" [query]="query" [recherche]="recherche">
    </app-recherche-results-sqrc>
</div>
