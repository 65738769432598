<div class="poteau-details">
    <p-table [value]="[$any(infoPointInspection.feature)]" class="detail-table current-details-table"
        responsiveLayout="scroll">
        <ng-template pTemplate="caption">
            <div class="table-caption">
                <div class="caption-champ">Attribut</div>
                <div class="caption-detail">Détails</div>
                <button pButton pRipple type="button" icon="fa fa-times" class="p-button-rounded btn-fermer"
                    (click)='onClosePoteauDetails()'></button>
            </div>
        </ng-template>
        <ng-template pTemplate="body" let-feature [formGroup]="form" *ngIf="form">
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Code à barres<span class="required"> *</span></span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" formControlName="codeABarres" maxlength="6">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{formValue.codeABarres | uppercase}}
                        </ng-template>
                    </p-cellEditor>
                    <ng-container *ngIf="form.controls.codeABarres?.errors?.codeabarres">
                        <div class="text-erreur">Le format du code à barres est invalide</div>
                    </ng-container>
                    <ng-container
                        *ngIf="canEdit && form.controls.codeABarres.touched && form.controls.codeABarres?.errors?.required">
                        <div class="text-erreur">Le code à barres est obligatoire</div>
                    </ng-container>
                    <div *ngIf='!canEdit'>{{formValue.codeABarres | uppercase}}</div>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Accessible camion<span class="required"> *</span></span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="accessibleCamionListe" formControlName="accessibleCamion"
                                optionLabel="valeur" optionValue="champ" optionDisabled="inactive"
                                [style]="{'width':'100%'}" appendTo="body" placeholder="Valeur requise"
                                [autoDisplayFirst]="false"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{accessibleCamionMap.get(formValue.accessibleCamion)}}
                        </ng-template>
                    </p-cellEditor>
                    <ng-container
                        *ngIf="canEdit && form.controls.accessibleCamion.touched && form.controls.accessibleCamion?.errors?.required">
                        <span class="text-erreur">Accessible camion est obligatoire</span>
                    </ng-container>
                    <span *ngIf='!canEdit'>{{accessibleCamionMap.get(formValue.accessibleCamion)}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Appareil lourd<span class="required"> *</span></span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="indEquipementMajeurListe" formControlName="indEquipementMajeur"
                                optionLabel="label" optionValue="value" [style]="{'width':'100%'}" appendTo="body"
                                placeholder="Valeur requise" [autoDisplayFirst]="false"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{indEquipementMajeurMap.get(formValue.indEquipementMajeur)}}
                        </ng-template>
                    </p-cellEditor>
                    <ng-container
                        *ngIf="canEdit && form.controls.indEquipementMajeur.touched && form.controls.indEquipementMajeur?.errors?.required">
                        <span class="text-erreur">Présence d'appareil lourd est obligatoire</span>
                    </ng-container>
                    <span *ngIf='!canEdit'>{{indEquipementMajeurMap.get(formValue.indEquipementMajeur)}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Adresse des travaux<span class="required"> *</span></span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" formControlName="adresseTravaux">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{formValue.adresseTravaux}}
                        </ng-template>
                    </p-cellEditor>
                    <ng-container
                        *ngIf="canEdit && form.controls.adresseTravaux.touched && form.controls.adresseTravaux?.errors?.required">
                        <span class="text-erreur">L'adresse des travaux est obligatoire</span>
                    </ng-container>
                    <span *ngIf='!canEdit'>{{formValue.adresseTravaux}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">LCLCL distant *</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" formControlName="lclclDistant" maxlength="5">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{formValue.lclclDistant | uppercase}}
                        </ng-template>
                    </p-cellEditor>
                    <div *ngIf='!canEdit'>{{formValue.lclclDistant}}</div>
                    <ng-container *ngIf="form.controls.lclclDistant?.errors?.lclcl">
                        <div class="text-erreur">Le format du LCLCL est invalide</div>
                    </ng-container>
                    <ng-container *ngIf="form.errors?.bothLCLCL">
                        <div class="text-erreur">Vous devez saisir seulement un des deux champs LCLCL</div>
                    </ng-container>
                    <ng-container *ngIf="form.errors?.oneLCLCLRequired">
                        <div class="text-erreur">Vous devez saisir un des deux champs LCLCL</div>
                    </ng-container>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Localisation *</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" formControlName="localisation">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{formValue.localisation}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{formValue.localisation}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">LCLCL poteau *</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" formControlName="lclclPoteau" maxlength="5">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{formValue.lclclPoteau | uppercase}}
                        </ng-template>
                    </p-cellEditor>
                    <div *ngIf='!canEdit'>{{formValue.lclclPoteau}}</div>
                    <ng-container *ngIf="form.controls.lclclPoteau?.errors?.lclcl">
                        <div class="text-erreur">Le format du LCLCL est invalide</div>
                    </ng-container>
                    <ng-container *ngIf="form.errors?.bothLCLCL">
                        <div class="text-erreur">Vous devez saisir seulement un des deux champs LCLCL</div>
                    </ng-container>
                    <ng-container *ngIf="form.errors?.oneLCLCLRequired">
                        <div class="text-erreur">Vous devez saisir un des deux champs LCLCL</div>
                    </ng-container>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Propriétaire</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="proprietaireListe" appendTo="body" optionLabel="valeur"
                                optionValue="champ" formControlName="proprietaire" [style]="{'width':'100%'}"
                                [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{proprietaireMap.get(formValue.proprietaire)}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{proprietaireMap.get(formValue.proprietaire)}} </span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Usage</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="usageListe" formControlName="usage" optionLabel="valeur"
                                optionValue="champ" [style]="{'width':'100%'}" appendTo="body"
                                [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{usageMap.get(formValue.usage)}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{usageMap.get(formValue.usage)}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Matériau</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="materiauListe" formControlName="materiau" optionLabel="valeur"
                                optionValue="champ" [style]="{'width':'100%'}" appendTo="body"
                                [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{materiauMap.get(formValue.materiau)}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{materiauMap.get(formValue.materiau)}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Montage</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="montageListe" formControlName="montage" optionLabel="valeur"
                                optionValue="champ" [style]="{'width':'100%'}" appendTo="body"
                                [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{montageMap.get(formValue.montage)}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{montageMap.get(formValue.montage)}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Classe</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="classeListe" formControlName="classe" optionLabel="valeur"
                                optionValue="champ" [style]="{'width':'100%'}" appendTo="body"
                                [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{classeMap.get(formValue.classe)}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{classeMap.get(formValue.classe)}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Longueur (pied)</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="longueurListe" formControlName="longueur" optionLabel="valeur"
                                optionValue="valeur" [style]="{'width':'100%'}" appendTo="body"
                                [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{formValue.longueur}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{formValue.longueur}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Hauteur hors sol (mètre)</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <input class="numeric" pInputNumber type="text" inputmode="numeric"
                                formControlName="hauteurHorsSol">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{formValue.hauteurHorsSol}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{formValue.hauteurHorsSol}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Inclinaison (degré)</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="inclinaisonListe" formControlName="inclinaison" optionLabel="valeur"
                                optionValue="champ" [style]="{'width':'100%'}" appendTo="body"
                                [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{inclinaisonMap.get(formValue.inclinaison)}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{inclinaisonMap.get(formValue.inclinaison)}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Essence</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="essenceListe" formControlName="essencePoteau" optionLabel="valeur"
                                optionValue="champ" [style]="{'width':'100%'}" appendTo="body"
                                [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{essenceMap.get(formValue.essencePoteau)}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{essenceMap.get(formValue.essencePoteau)}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Fabricant</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="fabricantListe" formControlName="fabricant" optionLabel="valeur"
                                optionValue="champ" [style]="{'width':'100%'}" appendTo="body"
                                [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{formValue.fabricant | uppercase}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{formValue.fabricant | uppercase}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Année de fabrication</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="anneesDisponibles" formControlName="anneeFabrication"
                                [style]="{'width':'100%'}" appendTo="body" [autoDisplayFirst]="false" [showClear]="true"
                                [filter]="true" filterBy="label"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{formValue.anneeFabrication}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{formValue.anneeFabrication}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Année d'installation</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="anneesDisponibles" formControlName="anneeInstallation"
                                [style]="{'width':'100%'}" appendTo="body" [autoDisplayFirst]="false" [showClear]="true"
                                [filter]="true" filterBy="label"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{formValue.anneeInstallation}}
                        </ng-template>

                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{formValue.anneeInstallation}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Traitement initial</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="traitementInitialListe" formControlName="traitementInitial"
                                optionLabel="valeur" optionValue="champ" [style]="{'width':'100%'}" appendTo="body"
                                [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{traitementInitialMap.get(formValue.traitementInitial)}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{traitementInitialMap.get(formValue.traitementInitial)}}</span>
                </td>
            </tr>
            <tr [pEditableColumn]>
                <td>
                    <span class="label-edit">Remarque</span>
                </td>
                <td>
                    <p-cellEditor *ngIf='canEdit'>
                        <ng-template pTemplate="input">
                            <textarea formControlName="remarquePoteau" name="remarquePoteau" rows="5" cols="33"
                                maxlength="500"></textarea>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{formValue.remarquePoteau | uppercase}}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf='!canEdit'>{{formValue.remarquePoteau}}</span>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <div class="photos">
                        <span class="p-column-title photos__title">Photos *</span>
                        <div class="photos__list">
                            <app-photos-container (removePhoto)="removePhoto($event)"
                                [loading]="photosLoading || (photosUploading$ | async)" [canEdit]="canEdit"
                                [photos]="detailsPhotosWithData"></app-photos-container>
                        </div>
                        <div *ngIf="canEdit" class="photos__upload">
                            <app-photos-upload [currentPhotos]="detailsPhotosWithData" (upload)="onUploadPhotos($event)"
                                [maxPhotos]="maxPhotoPointInspection">
                            </app-photos-upload>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div *ngIf="pointAuditDataVisible">
        <app-info-card [infos]="pointAuditData" [loadingPhotos]="auditPhotosLoading">
            <div class="audit-data-title">
                <div>Données de l'audit</div>
                <div class="audit-data-title__icon" *ngIf="isAuditeur">
                    <span pTooltip="Modifier la non-conformité" tooltipPosition="top">
                        <i class="fa-regular fa-pen-to-square" (click)="onPointAuditNonConforme()"></i>
                    </span>
                    <span pTooltip="Supprimer la non-conformité" tooltipPosition="top">
                        <i class="fa-regular fa-trash-can" (click)="deletePointAuditNonConformeConfirm()"></i>
                    </span>
                </div>
            </div>
        </app-info-card>
    </div>

    <div *ngIf="isCreatePoteau || !isAuditeur" class="footer-button">
        <button pButton type="button" label="Annuler" class="btn-annuler" (click)="onClosePoteauDetails()"></button>
        <button pButton type="button" label="Enregistrer" class="btn-enregistrer" (click)="onSavePoteauDetails()"
            [disabled]="form.invalid || uploadedPhotoLoading"></button>
    </div>
    <div *ngIf="!isCreatePoteau && isAuditeur" class="footer-button">
        <button pButton type="button" label="Non conforme" class="btn-non-conforme"
            (click)="onPointAuditNonConforme(false)"
            [disabled]="selectedPointAudit.statut === statutPointAudit.nonConforme"></button>
        <button pButton type="button" label="Conforme" class="btn-conforme" (click)="onPointAuditConforme()"
            [disabled]="selectedPointAudit.statut === statutPointAudit.conforme || selectedPointAudit.statut === statutPointAudit.nonConforme">
        </button>
    </div>
</div>

<app-point-audit-non-conforme-dialog *ngIf='pointAuditNonConformeDialogData'
    [pointAudit]="pointAuditNonConformeDialogData" [currentUserInfo]="currentUserInfo"
    [(visible)]="pointAuditNonConformeDialogVisible" [isCreatePoteau]="isCreatePoteau"
    (canceled)="onCancelNonConform($event)">
</app-point-audit-non-conforme-dialog>

<p-confirmDialog class="line-break" #cd header="Supprimer une non-conformité" icon="pi pi-exclamation-triangle"
    key='deletePointAuditNonConforme' appendTo="body">
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>