const RECHERCHE_SEPARATOR_VALUES = ['  ', ' ', '-', '_', '/'];

export function getSeparatorType(rechercheValue: string): string | null {
    for (const separateur of RECHERCHE_SEPARATOR_VALUES) {
        if (rechercheValue.includes(separateur)) {
            return separateur;
        }
    }
    return null;
}

export function formatSeparatedValue(rechercheValue: string, separateurType: string): string {
    const rechercheValueParts = rechercheValue.split(separateurType);
    return `${rechercheValueParts[0]} ${rechercheValueParts[1]}`;
}

export function formatUnseparatedValue(rechercheValue: string): string {
    if (!hasText(rechercheValue) && !hasNumber(rechercheValue)) {
        return '';
    }

    if (!hasText(rechercheValue) || !hasNumber(rechercheValue)) {
        return rechercheValue;
    }

    return `${extractText(rechercheValue)[0]} ${extractNumber(rechercheValue)[0]}`;
}

function extractNumber(value: string) {
    return value.match(/\d+/g);
}

function extractText(value: string) {
    return value.match(/[a-z,A-Z]+/g);
}

function hasNumber(value: string) {
    const regex = /\d+/g;
    return regex.test(value);
}

function hasText(value: string) {
    return /[a-z,A-Z]/g.test(value);
}
