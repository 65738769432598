import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Feature } from '@turf/helpers';
import { Guid } from 'guid-typescript';
import { rangeRight } from 'lodash';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { concatMap, filter, finalize, map, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { accessibleCamionListe, accessibleCamionMap } from 'src/app/enums/accessibleCamion';
import { classeListe, classeMap } from 'src/app/enums/classe';
import { essenceListe, essenceMap } from 'src/app/enums/essence';
import { fabricantListe, fabricantMap } from 'src/app/enums/fabricant';
import { inclinaisonListe, inclinaisonMap } from 'src/app/enums/inclinaison';
import { longueurListe } from 'src/app/enums/longueur';
import { materiauListe, materiauMap } from 'src/app/enums/materiau';
import { montageListe, montageMap } from 'src/app/enums/montage';
import { PointInspectionProperties } from 'src/app/enums/point-inspection-properties.enum';
import { IndEquipementMajeurLabel, indEquipementMajeurListe, indEquipementMajeurMap } from 'src/app/enums/presence-appareil-lourd';
import { proprietaireListe, proprietaireMap } from 'src/app/enums/proprietaire';
import { Severite } from 'src/app/enums/severite';
import { traitementInitialListe, traitementInitialMap } from 'src/app/enums/traitementInitial';
import { usageListe, usageMap } from 'src/app/enums/usage';
// import { AnomalieProperties } from 'src/app/features/anomalie/models/anomalie-properties.enum';
import { InfoPoint } from 'src/app/map/models/info-point.model';
import { EnumToListe } from 'src/app/shared/models/enum-to-liste.model';
import { dateParse, transformeProprietiesToInspection } from 'src/app/shared/utils';
import { HQValidators } from 'src/app/shared/validators/hq-validators';
import { AnomalieBaseDto, PhotoEntity, PointAuditDto, PointInspectionDto, ProjetAuditDto, ProjetCompletDto } from '../../../../core/api/client/models';
import { StatutPointAudit } from '../../../../features/audit/models/statut-point-audit.enum';
import { StatutProjetAudit } from '../../../../features/audit/models/statut-projet-audit.enum';
import * as AuditActions from '../../../../features/audit/state/audit.actions';
import { getPointAuditById, getPointAuditByInspectionId, getProjetAuditById } from '../../../../features/audit/state/audit.selectors';
import { getStatutGlobal } from '../../../../features/audit/utils/audit.utils';
import { MAX_PHOTO_POINT_INSPECTION } from '../../../../features/inspection/models/inspection.const';
import { StatutPointInspection } from '../../../../features/inspection/models/statut-point-inspection.enum';
import * as InspectionActions from '../../../../features/inspection/state/inspection.actions';
import { getAnomaliesByInspectionId, getProjetInspection, getUpdatePointInspectionSuccess } from '../../../../features/inspection/state/inspection.selectors';
import { StoreName } from '../../../../features/offline/models/indexed-db-store-name.enum';
import { IndexedDbService } from '../../../../features/offline/services/indexed-db.service';
import { StatutProjet } from '../../../../features/projet/models';
import { PhotoService } from '../../../../services/photo.service';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { InfosData } from '../../../../shared/models/infos-data.model';
import { UserInformation } from '../../../../shared/models/user-informations.model';
import * as PhotosActions from '../../../../shared/photos/state/photos.actions';
import {
    arePhotosUploading,
    getPointAuditPhotos,
    getPointAuditPhotosLoading,
    getPointInspectionPhotos,
    getPointInspectionPhotosLoading
} from '../../../../shared/photos/state/photos.selectors';
import { exportPhotosInErrorList, getAddedPhotos } from '../../../../shared/utils/photo.utils';
import { State } from '../../../../state/app.state';
import { MapPermissionsService } from '../../../services/map-permissions.service';
import { lclclsValidator } from '../../../../shared/validators/lclcls-validator';

@Component({
    selector: 'app-poteau-details',
    templateUrl: './poteau-details.component.html',
    styleUrls: ['./poteau-details.component.scss']
})
export class PoteauDetailsComponent extends BaseComponent implements OnDestroy, AfterViewChecked, OnInit {

    private pointInspection$ = new BehaviorSubject<InfoPoint>(this.config.data.pointInspection);
    private selectedPointInspection: Observable<InfoPoint> = this.pointInspection$.asObservable();
    private updatedPointInspection: PointInspectionDto;
    private projetDownloaded: ProjetCompletDto;
    private currentPointInspectionAnomalies: AnomalieBaseDto[] = [];

    public infoPointInspection: InfoPoint;
    public materiauListe: Array<EnumToListe> = materiauListe;
    public materiauMap = materiauMap;
    public montageListe: Array<EnumToListe> = montageListe;
    public montageMap = montageMap;
    public accessibleCamionListe: Array<EnumToListe> = accessibleCamionListe;
    public accessibleCamionMap = accessibleCamionMap;
    public indEquipementMajeurListe: { label: IndEquipementMajeurLabel, value: string | boolean }[] = indEquipementMajeurListe;
    public indEquipementMajeurMap = indEquipementMajeurMap;
    public longueurListe: Array<EnumToListe> = longueurListe;
    public traitementInitialListe: Array<EnumToListe> = traitementInitialListe;
    public traitementInitialMap = traitementInitialMap;
    public essenceListe: Array<EnumToListe> = essenceListe;
    public essenceMap = essenceMap;
    public fabricantListe: Array<EnumToListe> = fabricantListe;
    public fabricantMap = fabricantMap;
    public anneesDisponibles: Array<SelectItem> = this.getAnneesDisponibles();
    public classeMap = classeMap;
    public classeListe: Array<EnumToListe> = classeListe;
    public proprietaireMap = proprietaireMap;
    public proprietaireListe: Array<EnumToListe> = proprietaireListe;
    public usageMap = usageMap;
    public usageListe: Array<EnumToListe> = usageListe;
    public inclinaisonMap = inclinaisonMap;
    public inclinaisonListe: Array<EnumToListe> = inclinaisonListe;

    public pointAuditData?: InfosData = undefined;
    public selectedPointAudit: PointAuditDto;
    public statutPointAudit = StatutPointAudit;
    public pointAuditDataVisible = true;
    public pointAuditNonConformeDialogVisible: boolean = false;
    public pointAuditNonConformeDialogData: PointAuditDto | null = null;
    public isCodeABarresValide = false;

    public isAuditeur: boolean;
    public canEdit: boolean;
    public isCreatePoteau: boolean;
    public currentUserInfo: UserInformation;
    public currentActiveProjetAudit: ProjetAuditDto;
    public currentActiveProjetInspection: ProjetCompletDto;
    public projetsInspectionDownloaded: ProjetCompletDto[];
    public projetId: string;
    public form: FormGroup;
    private cameFromMap: boolean = true;
    private isAuditSelected: boolean = false;

    // Photos
    private addedPhotos: PhotoEntity[] = [];
    public detailsPhotosWithData: PhotoEntity[] = [];
    public originalPhotos: PhotoEntity[] = [];
    public uploadedPhotos: PhotoEntity[] = [];
    public uploadedPhotoLoading = false;
    public photosLoading = false;
    public auditPhotosLoading = false;
    public photosUploading$ = this.store.select(arePhotosUploading);
    public readonly maxPhotoPointInspection = MAX_PHOTO_POINT_INSPECTION;

    public get formValue(): any {
        return this.form.value;
    };

    public get isOffline(): boolean {
        return !navigator.onLine;
    }

    constructor(
        private store: Store<State>,
        public config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        private readonly fb: FormBuilder,
        private readonly messageService: MessageService,
        private cdr: ChangeDetectorRef,
        private photoService: PhotoService,
        private confirmationService: ConfirmationService,
        private mapPermissionsService: MapPermissionsService,
        private dbService: IndexedDbService
    ) {
        super();
        this.initData();
    }

    ngOnInit(): void {
        this.subscribeToSelectedPointInspection();
        this.subscribeToCodeABarresValueChanges();
        this.subscribeToHauteurHorsSolValueChanges();
        if (this.currentActiveProjetInspection) {
            this.projetDownloaded = this.getProjetInspectionDownloaded(this.currentActiveProjetInspection);
        } else {
            this.subscribeToProjetInspection();
        }

        this.isAuditeur ? this.subscribeToPointAuditById() : this.subscribeToPointAuditByInspectionId();
    }

    initData() {
        this.projetId = this.config.data.pointInspection.feature.properties[PointInspectionProperties?.projetId] ?
            this.config.data.pointInspection.feature.properties[PointInspectionProperties.projetId]
            : this.config.data.currentActiveProjetInspection.id;

        this.store.dispatch(InspectionActions.getProjetInspectionById({ projetId: this.projetId }));

        this.isCreatePoteau = this.config.data.isCreatePoteau;
        this.currentUserInfo = this.config.data.currentUserInfo;
        this.currentActiveProjetInspection = this.config.data.currentActiveProjetInspection;
        this.projetsInspectionDownloaded = this.config.data.projetsInspectionDownloaded;
        this.isAuditSelected = this.config.data.isAuditSelected;
        this.isAuditeur = this.markAsAuditeur();
        this.canEdit = this.isCreatePoteau || !this.isAuditeur;
        this.selectedPointAudit = this.config.data.selectedPointAudit;
        this.setCurrentActiveProjetAudit();

        if (this.mapPermissionsService.canEditPointInspection()) {
            this.verifyPointAudit();
        }

    }

    private setCurrentActiveProjetAudit() {
        this.currentActiveProjetAudit = this.config.data.currentActiveProjetAudit;
        if (this.selectedPointAudit && this.selectedPointAudit.projetAuditId) {
            this.subscribeToProjetAudit();
        }
    }

    private subscribeToProjetAudit() {
        this.store.select(getProjetAuditById(this.selectedPointAudit.projetAuditId))
            .pipe(
                filter(projetAudit => !!projetAudit),
                takeUntil(this.destroyed)
            ).subscribe(projetAudit => {
                this.currentActiveProjetAudit = projetAudit;
            });
    }

    private markAsAuditeur(): boolean {
        return this.config.data.isAuditeur || (this.mapPermissionsService.roleIsMarkAsAuditeur() && this.isAuditSelected);
    }

    private subscribeToSelectedPointInspection() {
        this.selectedPointInspection
            .pipe(
                filter(pointInspection => !!pointInspection),
                takeUntil(this.destroyed)
            ).subscribe(pointInspection => {
                this.infoPointInspection = pointInspection;
                this.initForm();
                this.prevalidateForm();
                this.subscribeToPointInspectionAnomalies(this.infoPointInspection.feature.properties['inspectionId']);
            });
    }

    private subscribeToPointInspectionAnomalies(pointInspectionId: string) {
        this.store.select(getAnomaliesByInspectionId(pointInspectionId)).pipe(
            takeUntil(this.destroyed)
        ).subscribe((anomalies: AnomalieBaseDto[]) => {
            this.currentPointInspectionAnomalies = anomalies;
        });
    }

    private subscribeToCodeABarresValueChanges() {
        this.form.controls.codeABarres.valueChanges
            .pipe(
                tap((codeABarres: string) => {
                    this.form.controls.codeABarres.patchValue(codeABarres.toUpperCase(), { emitEvent: false });
                }),
                takeUntil(this.destroyed)
            ).subscribe();
    }

    private subscribeToHauteurHorsSolValueChanges() {
        this.form.controls.hauteurHorsSol.valueChanges.pipe(
            tap((value: string) => {
                const NUMBER_REGEXP = /^[0-9]*[\.]?[0-9]*$/;
                if (value && !NUMBER_REGEXP.test(value[value.length - 1])) {
                    const newValue = value.slice(0, -1);
                    this.form.controls.hauteurHorsSol.patchValue(Number(newValue), { emitEvent: false });
                } else if (value) {
                    const NUMBER_OF_DOT = (value.match(/\./g) || []).length;

                    if (NUMBER_OF_DOT > 1) {
                        const newValue = value.slice(0, -1);
                        this.form.controls.hauteurHorsSol.patchValue(Number(newValue), { emitEvent: false });
                    }
                }
            }),
            takeUntil(this.destroyed)
        ).subscribe();
    }

    private subscribeToPointAuditById() {
        this.store.select(getPointAuditById(this.config.data.selectedPointAudit.id))
            .pipe(
                filter(pointAudit => !!pointAudit),
                take(1)
            ).subscribe(pointAudit => {
                this.selectedPointAudit = pointAudit[0];
                this.initPointAuditDataForAuditeur();
            });
    }

    private subscribeToProjetInspection() {
        this.store.select(getProjetInspection)
            .pipe(
                filter(projet => !!projet),
                takeUntil(this.destroyed)
            ).subscribe(projet => {
                if (this.mapPermissionsService.canEditPointInspection()) {
                    this.projetDownloaded = projet;
                    this.verifyPointAudit();
                }
            });
    }

    private subscribeToPointAuditByInspectionId() {
        this.store.select(getPointAuditByInspectionId(this.config.data.pointInspection.feature!.properties['inspectionId']))
            .pipe(
                filter(pointAudit => !!pointAudit),
                takeUntil(this.destroyed)
            ).subscribe(pointAudit => {
                this.selectedPointAudit = pointAudit[0];
                this.initPointAuditDataForInspecteur();
            });
    }

    private verifyPointAudit() {
        if (this.currentActiveProjetAudit === undefined || this.currentActiveProjetAudit === null) {
            this.store.select(getPointAuditByInspectionId(this.config.data.pointInspection.feature!.properties['inspectionId']))
                .subscribe(() => {
                    this.cameFromMap = false;
                    this.isAuditeur ? this.subscribeToPointAuditById() : this.initPointAuditDataForInspecteur();
                });
        } else {
            this.isAuditeur ? this.subscribeToPointAuditById() : this.initPointAuditDataForInspecteur();
        }
    }

    private getProjetInspectionDownloaded(projetInspection: ProjetCompletDto): ProjetAuditDto {
        return this.projetsInspectionDownloaded?.find(projet => projet.id === projetInspection.id);
    }

    private initForm() {
        const feature = this.infoPointInspection.feature;
        this.form = this.fb.group({
            codeABarres: [feature?.properties![PointInspectionProperties.codeABarres], [HQValidators.codeabarres(), Validators.required]],
            proprietaire: [feature?.properties![PointInspectionProperties.proprietaire]],
            montage: [feature?.properties![PointInspectionProperties.montage]],
            hauteurHorsSol: [feature?.properties![PointInspectionProperties.hauteurHorsSol]],
            classe: [feature?.properties![PointInspectionProperties.classe]],
            materiau: [feature?.properties![PointInspectionProperties.materiau]],
            statutPoteau: [feature?.properties![PointInspectionProperties.statutPoteau]],
            usage: [feature?.properties![PointInspectionProperties.usage]],
            longueur: [feature?.properties![PointInspectionProperties.longueur]],
            inclinaison: [feature?.properties![PointInspectionProperties.inclinaison]],
            accessibleCamion: [feature?.properties![PointInspectionProperties.accessibleCamion], Validators.required],
            indEquipementMajeur: [feature?.properties![PointInspectionProperties.indEquipementMajeur], Validators.required],
            adresseTravaux: [feature?.properties![PointInspectionProperties.adresseTravaux], Validators.required],
            lclclDistant: [feature?.properties![PointInspectionProperties.lclclDistant], [HQValidators.lclcl()]],
            localisation: [feature?.properties![PointInspectionProperties.localisation]],
            lclclPoteau: [feature?.properties![PointInspectionProperties.lclclPoteau], [HQValidators.lclcl()]],
            essencePoteau: [feature?.properties![PointInspectionProperties.essence]],
            fabricant: [feature?.properties![PointInspectionProperties.fabricant]],
            anneeFabrication: [feature?.properties![PointInspectionProperties.anneeFabrication]],
            anneeInstallation: [feature?.properties![PointInspectionProperties.anneeInstallation]],
            traitementInitial: [feature?.properties![PointInspectionProperties.traitementInitial]],
            remarquePoteau: [feature?.properties![PointInspectionProperties.remarquePoteau]],
        }, { validators: lclclsValidator });

        if (this.form.controls.accessibleCamion.value === '' || this.form.controls.accessibleCamion.value === 'inconnu') {
            this.form.controls.accessibleCamion.setValue(null);
        }

        if (this.isCreatePoteau) {
            this.form.reset();
        } else {
            let photos: PhotoEntity[] = [];
            if (typeof feature?.properties!.photos === 'string') {
                photos = JSON.parse(feature?.properties!.photos) || [];
            } else {
                photos = feature?.properties!.photos || [];
            }

            this.originalPhotos = photos.map(photo => {
                return {
                    id: photo.id,
                    pointInspectionId: this.infoPointInspection.feature.properties['inspectionId'],
                    nom: photo.nom,
                    nomOriginal: photo.nomOriginal,
                };
            });

            this.loadPhotos(this.originalPhotos);
        }
    }

    private prevalidateForm() {
        const controlNameRequired = ['codeABarres', 'accessibleCamion', 'indEquipementMajeur', 'adresseTravaux', 'lclclDistant', 'lclclPoteau']; // TODO CHANGE TO ENUM
        controlNameRequired.forEach((controlName: string) => {
            if (this.form.controls[controlName].value) {
                this.form.controls[controlName].markAsDirty();
                this.form.controls[controlName].markAsTouched();
                this.form.controls[controlName].updateValueAndValidity();
            }
        });
    }

    private canSeeAuditDataForInspectorByProjetStatut(): boolean {
        if (this.projetDownloaded) {
            const t = this.projetDownloaded?.statut === StatutProjet.correctionEnCours
                || this.projetDownloaded?.statut === StatutProjet.nonConforme
                || this.projetDownloaded?.statut === StatutProjet.correctionCompletee;
            return t;
        }
        return false;
    }

    private isPointAuditDataAvailable() {
        return (this.selectedPointAudit
            && this.selectedPointAudit.statut === StatutPointAudit.nonConforme
            && this.selectedPointAudit.remarque !== undefined
            && this.selectedPointAudit.remarque !== '') || false;
    }
    // TODO mart : refactoriser
    private pointAuditDataForCard(pointAudit: any) {
        if (this.cameFromMap) {
            return {
                id: pointAudit.id,
                date: pointAudit.auditeLe,
                info: pointAudit.remarque,
                photos: pointAudit.photos,
            };
        } else {
            return this.pointAudiDataObjectForCard(pointAudit);
        }
    }
    // TODO mart : refactoriser
    private pointAudiDataObjectForCard(pointAudit: any) {
        return {
            id: pointAudit.id,
            date: pointAudit.auditeLe,
            info: pointAudit.remarque,
            photos: pointAudit.photos,
        };
    }

    private initPointAuditDataForInspecteur() {
        // TODO mart : Est-ce que je peux regarder le point d'audit pour savoir s'il a des données de non conformité
        this.pointAuditDataVisible = false;
        if (this.canSeeAuditDataForInspectorByProjetStatut() && this.isPointAuditDataAvailable()) {
            this.loadPhotosForPointAuditForInspecteurAndShowInfoCard(this.selectedPointAudit);
        }
    }

    private canSeeAuditDataForAuditeurByStatutProjet(pointAudit: PointAuditDto): boolean {
        if (this.currentActiveProjetAudit) {
            return this.isAuditeur || (
                (pointAudit.statut === StatutPointAudit.nonConforme || (pointAudit as any).Statut === StatutPointAudit.nonConforme) &&
                (this.currentActiveProjetAudit?.statut === StatutProjetAudit.enCours || this.currentActiveProjetAudit?.statut === StatutProjetAudit.auditComplete)
            );
        }
        return false;
    }

    private initPointAuditDataForAuditeur() {
        this.pointAuditDataVisible = false;
        if (this.canSeeAuditDataForAuditeurByStatutProjet(this.selectedPointAudit) && this.isPointAuditDataAvailable()) {
            this.loadPhotosForPointAuditForAuditeurAndShowInfoCard(this.selectedPointAudit);
        }
    }

    public onPointAuditNonConforme(displayRemarque: boolean = true) {
        const pointAudit = {
            ...this.selectedPointAudit,
            remarque: !displayRemarque ? null : this.selectedPointAudit.remarque
        };

        this.pointAuditNonConformeDialogVisible = true;
        this.pointAuditNonConformeDialogData = pointAudit;
    }

    public onCancelNonConform(isCanceled: boolean) {
        if (!isCanceled) {
            this.onClosePoteauDetails();
        }
    }

    public onPointAuditConforme() {
        const pointAudit: PointAuditDto = {
            ...this.selectedPointAudit,
            statut: StatutPointAudit.conforme,
            auditeLe: new Date().getTime(),
            auditePar: this.currentUserInfo.courriel
        };

        this.updatePointAudit(pointAudit);
        this.onClosePoteauDetails();
    }

    public deletePointAuditNonConforme() {
        const pointAudit: PointAuditDto = {
            ...this.selectedPointAudit,
            statut: StatutPointAudit.aAuditer,
            photos: [],
            remarque: null,
            auditeLe: 0,
            auditePar: null
        };

        this.updatePointAudit(pointAudit);
    }

    private updatePointAudit(pointAudit: PointAuditDto) {
        const updatedPointAudit: PointAuditDto = {
            ...pointAudit,
            statutGlobal: getStatutGlobal(pointAudit)
        };

        this.store.dispatch(AuditActions.updatePointAudit({ pointAudit: updatedPointAudit }));
    }

    public onClosePoteauDetails(pointInspection?: PointInspectionDto) {
        this.ref.close(pointInspection);
    }

    public deletePointAuditNonConformeConfirm() {
        this.confirmationService.confirm({
            message: `<strong>Vous allez supprimer la non-conformité signalée pour cet élément.</strong><br><br>
                Le changement que vous êtes en train d'effectuer entraînera la suppression des
                informations de non-conformité déjà enregistrées pour cet élément.<br><br>
                Êtes-vous certain de vouloir supprimer cette non-conformité ?`,
            accept: () => {
                this.deletePointAuditNonConforme();
                this.onClosePoteauDetails();
            },
            key: 'deletePointAuditNonConforme'
        });
    }

    private saveAsNew(projetDownloaded: ProjetCompletDto) {
        const formValue = this.form.value;

        const pointInspection: PointInspectionDto = {
            geometrie: '',
            statut: StatutPointInspection.nonInspecte,
            accessibleCamion: formValue.accessibleCamion,
            indEquipementMajeur: formValue.indEquipementMajeur,
            id: Guid.create().toString(),
            poteau: {
                id: Guid.create().toString(),
                anneeFabrication: dateParse(formValue.anneeFabrication ? `01-02-${formValue.anneeFabrication}` : formValue.anneeFabrication),
                anneeInstallation: dateParse(formValue.anneeInstallation ? `01-02-${formValue.anneeInstallation}` : formValue.anneeInstallation),
                codeABarres: formValue.codeABarres,
                proprietaire: formValue.proprietaire,
                montage: formValue.montage,
                hauteurHorsSol: formValue.hauteurHorsSol,
                classe: formValue.classe,
                materiau: formValue.materiau,
                statut: formValue.statutPoteau,
                usage: formValue.usage,
                longueur: formValue.longueur,
                inclinaison: formValue.inclinaison,
                adresseTravaux: formValue.adresseTravaux,
                lclclDistant: formValue.lclclDistant,
                localisation: formValue.localisation,
                lclclPoteau: formValue.lclclPoteau,
                essence: formValue.essencePoteau,
                fabricant: formValue.fabricant,
                traitementInitial: formValue.traitementInitial,
            },
            remarquePoteau: formValue.remarquePoteau,
            photos: this.uploadedPhotos,
            inspectePar: '',
        };

        this.validerCodeABarres(projetDownloaded, pointInspection.id?.toString() || '');

        if (this.isCodeABarresValide) {
            Object.keys(pointInspection.poteau).forEach((k) => pointInspection.poteau[k] == null && delete pointInspection.poteau[k]);
            return this.onClosePoteauDetails(pointInspection);
        }
    }

    private verifierDateTransfertSAP(projetDownloaded: ProjetCompletDto) {
        let anomalieDejaTransferee = false;
        anomalieDejaTransferee = this.currentPointInspectionAnomalies.filter(anomalie => anomalie.dateTransfertSap !== 0).length > 0;

        if (anomalieDejaTransferee) {
            this.confirmationService.confirm({
                header: 'Avertissement',
                message: `Un avis a déjà été créé dans SAP pour au moins une des anomalies du point d'inspection.\r\n
                    Les modifications effectuées sur le point d'inspection, ne seront pas envoyées vers SAP.\r\n
                    Voulez-vous vraiment enregistrer les modifications ?`,
                accept: () => {
                    this.saveAsUpdate(projetDownloaded, this.currentPointInspectionAnomalies);
                },
                reject: () => {
                    return;
                },
            });
        } else {
            this.saveAsUpdate(projetDownloaded, this.currentPointInspectionAnomalies);
        }
    }

    private saveAsUpdate(projetDownloaded: ProjetCompletDto, anomalies?: AnomalieBaseDto[]) {
        const pointInspection = transformeProprietiesToInspection(this.modifierFeature(this.config.data.pointInspection.feature!));

        this.validerCodeABarres(projetDownloaded, pointInspection.id?.toString() || '');

        if (this.isCodeABarresValide) {
            if (pointInspection.inspecteLe === 0) {
                pointInspection.inspecteLe = new Date().getTime();
            }

            const updatedPointInspectionPhotos: PhotoEntity[] = [
                ...this.detailsPhotosWithData,
                ...this.uploadedPhotos
            ];

            this.addedPhotos = getAddedPhotos(updatedPointInspectionPhotos, this.originalPhotos, []);

            this.updatedPointInspection = {
                ...pointInspection,
                anomalies: anomalies ? anomalies : [],
                photos: this.photoService.removeDataFromPhotoArray(updatedPointInspectionPhotos)
            };

            this.store.dispatch(InspectionActions.updatePointInspection({ pointInspection: this.updatedPointInspection }));
            if (this.addedPhotos.length > 0) {
                this.subscribeToUpdatePointInspectionSuccess();
            } else {
                this.onClosePoteauDetails();
            }
        }
    }

    private subscribeToUpdatePointInspectionSuccess() {
        this.store.select(getUpdatePointInspectionSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed),
        ).subscribe(success => {
            if (success && this.addedPhotos.length > 0) {
                this.savePointInspectionPhoto();
                this.onClosePoteauDetails();
            }
        });
    }

    public savePointInspectionPhoto() {
        this.addedPhotos.forEach((addedPhoto: PhotoEntity) => {
            this.store.dispatch(InspectionActions.addPointInspectionPhoto({ pointInspection: this.updatedPointInspection, photo: addedPhoto }));
        });
    }

    public onSavePoteauDetails() {
        this.form.markAllAsTouched();
        if (!this.form.valid) {
            return this.popupMessage(
                this.isCreatePoteau ? 'Création de poteau' : `Mise à jour de détails poteau`, `Le formulaire est invalide`
            );
        }

        if (this.isCreatePoteau) {
            this.saveAsNew(this.projetDownloaded);
        } else {
            if (this.currentPointInspectionAnomalies && this.currentPointInspectionAnomalies.length > 0) {
                this.verifierDateTransfertSAP(this.projetDownloaded);
            } else {
                this.saveAsUpdate(this.projetDownloaded);
            }
        }
    }

    public validerCodeABarres(projetDownloaded: ProjetCompletDto, inspectionId: string) {
        this.isCodeABarresValide = false;
        const listePi = projetDownloaded.pointInspections?.filter(pi => pi.id !== inspectionId);

        if (String(this.form.controls.codeABarres.value).toUpperCase() !== 'TEST22' &&
            String(this.form.controls.codeABarres.value).toUpperCase() !== 'NR6666' &&
            listePi) {
            for (const pi of listePi) {
                if (String(pi.poteau!.codeABarres).toUpperCase() === String(this.form.controls.codeABarres.value).toUpperCase()) {
                    return this.popupMessage(`Erreur`, `Le code à barres existe déjà dans le projet`);
                }
            }
        }

        this.isCodeABarresValide = true;
    }

    private modifierFeature(feature: Feature): Feature {
        feature.properties![PointInspectionProperties.codeABarres] = this.form.controls.codeABarres.value;
        feature.properties![PointInspectionProperties.proprietaire] = this.form.controls.proprietaire.value;
        feature.properties![PointInspectionProperties.montage] = this.form.controls.montage.value;
        feature.properties![PointInspectionProperties.hauteurHorsSol] = this.form.controls.hauteurHorsSol.value;
        feature.properties![PointInspectionProperties.classe] = this.form.controls.classe.value;
        feature.properties![PointInspectionProperties.materiau] = this.form.controls.materiau.value;
        feature.properties![PointInspectionProperties.usage] = this.form.controls.usage.value;
        feature.properties![PointInspectionProperties.longueur] = this.form.controls.longueur.value;
        feature.properties![PointInspectionProperties.inclinaison] = this.form.controls.inclinaison.value;
        feature.properties![PointInspectionProperties.accessibleCamion] = this.form.controls.accessibleCamion.value;
        feature.properties![PointInspectionProperties.indEquipementMajeur] = this.form.controls.indEquipementMajeur.value;
        feature.properties![PointInspectionProperties.adresseTravaux] = this.form.controls.adresseTravaux.value;
        feature.properties![PointInspectionProperties.lclclDistant] = this.form.controls.lclclDistant.value;
        feature.properties![PointInspectionProperties.localisation] = this.form.controls.localisation.value;
        feature.properties![PointInspectionProperties.lclclPoteau] = this.form.controls.lclclPoteau.value;
        feature.properties![PointInspectionProperties.essence] = this.form.controls.essencePoteau.value;
        feature.properties![PointInspectionProperties.fabricant] = this.form.controls.fabricant.value;
        feature.properties![PointInspectionProperties.anneeFabrication] = this.form.controls.anneeFabrication.value;
        feature.properties![PointInspectionProperties.anneeInstallation] = this.form.controls.anneeInstallation.value;
        feature.properties![PointInspectionProperties.traitementInitial] = this.form.controls.traitementInitial.value;
        feature.properties![PointInspectionProperties.remarquePoteau] = this.form.controls.remarquePoteau.value;
        return feature;
    }

    private popupMessage(summary: string, detail: string, severity: string = Severite.erreur) {
        this.messageService.add(
            {
                severity: severity,
                closable: true,
                summary: summary,
                detail: detail
            }
        );
    }

    private getAnneesDisponibles(): SelectItem[] {
        const startYear: number = 1920;
        const currentYear: number = new Date().getFullYear();

        return rangeRight(startYear, currentYear + 1)
            .map(year => ({ value: year.toString(), label: year.toString() } as SelectItem));
    }

    // Photos
    public removePhoto(photoId: string) {
        this.detailsPhotosWithData = this.photoService.removePhoto(this.detailsPhotosWithData, photoId);
    }

    public onUploadPhotos(photos: File[]) {
        this.uploadedPhotoLoading = true;
        from(photos).pipe(
            concatMap(photo => from(this.photoService.cleanImage(photo))
                .pipe(
                    map((imageBase64: string): PhotoEntity => {
                        return {
                            id: Guid.create().toString(),
                            nomOriginal: photo.name,
                            nom: '',
                            pointInspectionId: this.infoPointInspection.feature.properties['inspectionId'],
                            data: this.photoService.base64ToFile(imageBase64, photo.name)
                        };
                    })
                )
            ),
            toArray(),
            finalize(() => this.uploadedPhotoLoading = false)
        ).subscribe((cleanedPhotos) => this.uploadedPhotos = cleanedPhotos);
    }

    private loadPhotos(photosWithoutData: PhotoEntity[]) {
        // Ici, s'il est admin, on charge les photos de l'inspection actuelle
        if (this.mapPermissionsService.canEditPointInspection()) {
            this.store.dispatch(PhotosActions.loadPointInspectionPhotos({ photos: photosWithoutData }));

            this.store.select(getPointInspectionPhotos)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photosWithData => this.detailsPhotosWithData = [...photosWithData]);

            this.store.select(getPointInspectionPhotosLoading).pipe(
                takeUntil(this.destroyed)
            ).subscribe(loading => this.photosLoading = loading);
            // Sinon, on récupère les photos de indexdb
        } else {
            this.photosLoading = true;
            this.dbService.getAll<PhotoEntity>(StoreName.PHOTOS)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photos => {
                    this.photosLoading = false;
                    const photoIds = photosWithoutData.map(photo => photo.id);
                    this.detailsPhotosWithData = photos.filter(photo => photoIds.includes(photo.id));

                    if (this.detailsPhotosWithData.length !== photosWithoutData.length) {
                        const photosInError = photosWithoutData.filter(photo => this.detailsPhotosWithData.map(displayedPhoto => displayedPhoto.id).includes(photo.id));
                        exportPhotosInErrorList(photosInError);

                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Photos manquantes',
                            detail: `Certaines photos n'ont pas pu être chargées. La liste des photos manquantes a été téléchargée.`,
                            life: 5000
                        });
                    }
                });
        }
    }

    private loadPhotosForPointAuditForInspecteurAndShowInfoCard(pointAudit: PointAuditDto) {
        // Ici, s'il est admin, on charge les photos de le point d'audit actuel
        const workingPointAudit = { ...pointAudit };
        if (this.mapPermissionsService.canEditPointInspection()) {
            this.store.dispatch(PhotosActions.loadPointAuditPhotos({ photos: workingPointAudit.photos }));

            this.store.select(getPointAuditPhotos)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photosWithData => {
                    workingPointAudit.photos = [...photosWithData];
                    this.pointAuditDataVisible = true;
                    this.pointAuditData = this.pointAudiDataObjectForCard(workingPointAudit);
                });

            this.store.select(getPointAuditPhotosLoading).pipe(
                takeUntil(this.destroyed)
            ).subscribe(loading => this.auditPhotosLoading = loading);
            // Sinon, on récupère les photos de indexdb
        } else {
            this.auditPhotosLoading = true;
            this.dbService.getAll<PhotoEntity>(StoreName.PHOTOS)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photos => {
                    this.pointAuditDataVisible = true;
                    this.auditPhotosLoading = false;

                    const photoIds = workingPointAudit.photos.map(photo => photo.id);
                    const photosWithData = photos.filter(photo => photoIds.includes(photo.id));

                    workingPointAudit.photos = [...photosWithData];
                    this.pointAuditData = this.pointAuditDataForCard(workingPointAudit);

                    if (photosWithData.length !== workingPointAudit.photos.length) {
                        const photosInError = workingPointAudit.photos.filter(photo => photosWithData.map(displayedPhoto => displayedPhoto.id).includes(photo.id));
                        exportPhotosInErrorList(photosInError);

                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Photos manquantes',
                            detail: `Certaines photos n'ont pas pu être chargées. La liste des photos manquantes a été téléchargée.`,
                            life: 5000
                        });
                    }
                });
        }
    }

    private loadPhotosForPointAuditForAuditeurAndShowInfoCard(pointAudit: PointAuditDto) {
        // Ici, s'il est admin, on charge les photos de le point d'audit actuel
        const workingPointAudit = { ...pointAudit };
        if (this.mapPermissionsService.canEditPointInspection()) {
            this.store.dispatch(PhotosActions.loadPointAuditPhotos({ photos: workingPointAudit.photos }));

            this.store.select(getPointAuditPhotos)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photosWithData => {
                    workingPointAudit.photos = [...photosWithData];
                    this.pointAuditDataVisible = true;
                    this.pointAuditData = this.pointAudiDataObjectForCard(workingPointAudit);
                });

            this.store.select(getPointAuditPhotosLoading).pipe(
                takeUntil(this.destroyed)
            ).subscribe(loading => this.auditPhotosLoading = loading);
            // Sinon, on récupère les photos de indexdb
        } else {
            this.auditPhotosLoading = true;
            this.dbService.getAll<PhotoEntity>(StoreName.PHOTOS)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photos => {
                    this.auditPhotosLoading = false;

                    const photoIds = workingPointAudit.photos.map(photo => photo.id);
                    const photosWithData = photos.filter(photo => photoIds.includes(photo.id));

                    workingPointAudit.photos = [...photosWithData];
                    this.pointAuditDataVisible = true;
                    this.pointAuditData = this.pointAuditDataForCard(workingPointAudit);

                    if (photosWithData.length !== workingPointAudit.photos.length) {
                        const photosInError = workingPointAudit.photos.filter(photo => photosWithData.map(displayedPhoto => displayedPhoto.id).includes(photo.id));
                        exportPhotosInErrorList(photosInError);

                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Photos manquantes',
                            detail: `Certaines photos n'ont pas pu être chargées. La liste des photos manquantes a été téléchargée.`,
                            life: 5000
                        });
                    }
                });
        }
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }
}
