<p-table [columns]="colonnes" [value]="liste.groupeName" [(first)]="premierePage" responsiveLayout="scroll"
    selectionMode='single' [paginator]="liste.groupeName.length > 0" [rows]="6" [showCurrentPageReport]="false"
    (onRowSelect)="zoomFeature($event.data)">
    <ng-template pTemplate="header" let-columns>
        <tr *ngIf="liste.groupeName.length > 0">
            <th *ngFor="let col of columns " class="p-flex-column">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns" class="p-flex-column">
                {{rowData[col.field]}}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length" *ngIf="showNoResult">
                Aucun résultat ne correspond à votre recherche
            </td>
        </tr>
    </ng-template>
</p-table>
