import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Feature } from 'geojson';
import { InfoPoint } from '../map/models/info-point.model';
import { ApiService } from './api.service';
import mapboxgl from 'mapbox-gl';
import { LocalStorageService } from './local-storage.service';
import { ProjetDataType } from '../features/projets/models/projet-data.type';

@Injectable({
    providedIn: 'root'
})
export class UiService {
    private recherche: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public recherche$: Observable<boolean> = this.recherche.asObservable();

    private projetsList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public projetsList$: Observable<boolean> = this.projetsList.asObservable();

    private projetInspectionList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public projetInspectionList$: Observable<boolean> = this.projetInspectionList.asObservable();

    private projetAuditList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public projetAuditList$: Observable<boolean> = this.projetAuditList.asObservable();

    private projetListRowSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public projetListRowSelected$: Observable<boolean> = this.projetListRowSelected.asObservable();

    private version: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public version$: Observable<boolean> = this.version.asObservable();

    private createProjet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public createProjet$: Observable<boolean> = this.createProjet.asObservable();

    private createPoteau: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public createPoteau$: Observable<boolean> = this.createPoteau.asObservable();

    private createPoteauSansAnomalie: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public createPoteauSansAnomalie$: Observable<boolean> = this.createPoteauSansAnomalie.asObservable();

    private pilotage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public pilotage$: Observable<boolean> = this.pilotage.asObservable();

    public backendVersion: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public backendVersion$: Observable<string> = this.backendVersion.asObservable();

    private downloadProjectZipFile: BehaviorSubject<Blob | unknown> = new BehaviorSubject<Blob | unknown>(new Blob([''], {}));
    public downloadProjectZipFile$: Observable<Blob | unknown> = this.downloadProjectZipFile.asObservable();

    private isPoteauDetailsDialogOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isPoteauDetailsDialogOpened$: Observable<boolean> = this.isPoteauDetailsDialogOpened.asObservable();

    private numberOfExcludePole: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public numberOfExcludePole$: Observable<number> = this.numberOfExcludePole.asObservable();

    private pointInspectionInfo: BehaviorSubject<InfoPoint> = new BehaviorSubject<InfoPoint>({ openActionSheet: false });
    public pointInspectionInfo$: Observable<InfoPoint> = this.pointInspectionInfo.asObservable();

    private pointAuditInfo: BehaviorSubject<InfoPoint> = new BehaviorSubject<InfoPoint>({ openActionSheet: false });
    public pointAuditInfo$: Observable<InfoPoint> = this.pointAuditInfo.asObservable();

    private selectedPointInspectionId: BehaviorSubject<string | number | undefined> = new BehaviorSubject<string | number | undefined>(undefined);
    public selectedPointInspectionId$: Observable<string | number | undefined> = this.selectedPointInspectionId.asObservable();

    private projetDataType: BehaviorSubject<ProjetDataType | undefined> = new BehaviorSubject<ProjetDataType | undefined>(undefined);
    public projetDataType$: Observable<ProjetDataType | undefined> = this.projetDataType.asObservable();

    private mapPopUpClicked: BehaviorSubject<undefined | {
        value: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined } &
        mapboxgl.EventData, avecMasqueButton: boolean
    }> = new BehaviorSubject<undefined | {
        value: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined } &
        mapboxgl.EventData, avecMasqueButton: boolean
    }>(undefined);
    public mapPopUpClicked$: Observable<undefined | {
        value: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined } &
        mapboxgl.EventData, avecMasqueButton: boolean
    }> = this.mapPopUpClicked.asObservable();

    private enableMainMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public enableMainMenu$: Observable<boolean> = this.enableMainMenu.asObservable();

    private openRapportErreurValidation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public openRapportErreurValidation$: Observable<boolean> = this.openRapportErreurValidation.asObservable();

    constructor(
        private apiService: ApiService,
        private localStorageService: LocalStorageService
    ) { }

    public openRechercheModal(value: boolean) {
        this.recherche.next(value);
    }

    public openProjetsListModal(value: boolean) {
        this.projetsList.next(value);
    }

    public openProjetInspectionListModal(value: boolean) {
        this.projetInspectionList.next(value);
    }

    public openProjetAuditListModal(value: boolean) {
        this.projetAuditList.next(value);
    }

    public openVersionModal(value: boolean) {
        this.version.next(value);
    }

    public openCreateProjetModal(value: boolean) {
        this.createProjet.next(value);
    }

    public openCreatePoteauModal(value: boolean) {
        this.createPoteau.next(value);
    }

    public openCreatePoteauSansAnomalieModal(value: boolean) {
        this.createPoteauSansAnomalie.next(value);
    }

    public openPilotageModal(value: boolean) {
        this.pilotage.next(value);
    }

    public closeActionSheet(value: boolean) {
        this.projetListRowSelected.next(value);
    }

    public setDetailPoteauDialogOpened(value: boolean) {
        this.isPoteauDetailsDialogOpened.next(value);
    }

    public setNumberOfExcludePole(value: number) {
        this.numberOfExcludePole.next(value);
    }

    public triggerInfoPointInspectionFromFeature(event: Feature) { // TODO DELETE ??
        this.triggerPointInspection({ openActionSheet: true, feature: event } as InfoPoint);
    }

    public triggerPointInspection(point: InfoPoint) {
        this.pointInspectionInfo.next(point);
    }

    public triggerPointAudit(point: InfoPoint) {
        this.pointAuditInfo.next(point);
    }

    public setSelectedPointInspectionId(value: string | number | undefined = undefined) {
        this.selectedPointInspectionId.next(value);
    }

    public setProjetDataType(value: ProjetDataType | undefined) {
        this.projetDataType.next(value);
    }

    public getBackendVersion() {
        if (navigator.onLine) {
            // eslint-disable-next-line rxjs/no-ignored-error
            this.apiService.get<any>(`/utilitaires/about`).subscribe((backendInfo: any) => {
                this.localStorageService.setItem('backendVersion', backendInfo.version);
                this.backendVersion.next(backendInfo.version);
            });
        } else {
            const version = this.localStorageService.getItem('backendVersion');
            this.backendVersion.next(version || '');
        }
    }

    public setMapPopUpClicked(value: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined } & mapboxgl.EventData,
        avecMasqueButton: boolean) {
        this.mapPopUpClicked.next({ value: value, avecMasqueButton: avecMasqueButton });
    }

    public enablingMainMenu(enable: boolean) {
        this.enableMainMenu.next(enable);
    };

    public setOpenRapportErreurValidation(value: boolean) {
        this.openRapportErreurValidation.next(value);
    }
}
