import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../../shared/shared.module';
import { RechercheEffects } from './state/recherche.effects';
import { rechercheReducer } from './state/recherche.reducer';
import { RechercheCabComponent } from './recherche-main-dialog/recherche-section/recherche-cab/recherche-cab.component';
import { RechercheSqrcComponent } from './recherche-main-dialog/recherche-section/recherche-sqrc/recherche-sqrc.component';
import { RechercheLclclComponent } from './recherche-main-dialog/recherche-section/recherche-lclcl/recherche-lclcl.component';
import { RechercheLigneComponent } from './recherche-main-dialog/recherche-section/recherche-ligne/recherche-ligne.component';
import { RechercheMainDialogComponent } from './recherche-main-dialog/recherche-main-dialog.component';
import { RechercheResultSqrcComponent } from './recherche-main-dialog/recherche-results/recherche-results-sqrc/recherche-results-sqrc.component';
import { RechercheResultLigneComponent } from './recherche-main-dialog/recherche-results/recherche-results-ligne/recherche-results-ligne.component';
import { RechercheResultCabLclclComponent } from './recherche-main-dialog/recherche-results/recherche-results-cab-lclcl/recherche-results-cab-lclcl.component';

@NgModule({
    declarations: [
        RechercheCabComponent,
        RechercheSqrcComponent,
        RechercheLclclComponent,
        RechercheLigneComponent,
        RechercheMainDialogComponent,
        RechercheResultSqrcComponent,
        RechercheResultLigneComponent,
        RechercheResultCabLclclComponent,
    ],
    imports: [
        SharedModule,
        StoreModule.forFeature('recherche', rechercheReducer),
        EffectsModule.forFeature([RechercheEffects]),
    ],
    exports: [
        RechercheMainDialogComponent,
    ],
})
export class RechercheModule { }
