import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Store } from '@ngrx/store';
import { State } from '../../../../../state/app.state';
import { BaseComponent } from '../../../../../shared/components/abstract-base-component';
import { anomalieListDialogColumn } from '../../../models/anomalie-list-dialog.column';
import { prioriteOptions } from '../../../models/priorite-options.const';
import { PrioriteValue } from '../../../../../shared/enums/priorite.enum';
import { AnomalieList } from '../../../models/anomalie-list.model';
import { PhotoEntity, PointInspectionDto } from '../../../../../core/api/client/models';
import { yesNoOptions } from '../../../../../shared/consts/yes-no-options.const';
import { MapService } from '../../../../../map/services/map.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { prioriteAnalyseOptions } from '../../../models/priorite-analyse.enum';
import { StatutAnomalie } from '../../../models/statut-anomalie.enum';
import * as InspectionActions from '../../../../inspection/state/inspection.actions';

@Component({
    selector: 'app-anomalie-list-dialog',
    templateUrl: './anomalie-list-dialog.component.html',
    styleUrls: ['./anomalie-list-dialog.component.scss']
})
export class AnomalieListDialogComponent extends BaseComponent implements AfterViewInit {
    @ViewChild('dt') anomalieListTable: Table;

    anomalieList: AnomalieList[] = [];

    @Input() anomalieListLoading: boolean;
    @Input() set anomalie(value: AnomalieList[]) {
        this.anomalieList = JSON.parse(JSON.stringify(value));
    }

    @Input() visible: boolean;
    @Output() visibleChange = new EventEmitter<boolean>();

    columns = anomalieListDialogColumn;
    yesNoItems: SelectItem[] = yesNoOptions;
    prioriteItems: SelectItem[] = prioriteOptions;
    prioriteAnalyseItems: SelectItem[] = prioriteAnalyseOptions;
    priorite = PrioriteValue;
    defaultSortField = 'codeABarres';
    defaultSortOrder = 1;

    photoListDialogVisible: boolean;
    photoPoteauList: PhotoEntity[] = [];
    photoAnomalieList: PhotoEntity[] = [];

    clonedAnomalie: { [s: string]: AnomalieList } = {};
    statutAnomalieLabel = StatutAnomalie;
    isMaximize = false;

    constructor(
        public ref: DynamicDialogRef,
        private cdr: ChangeDetectorRef,
        private mapService: MapService,
        private store: Store<State>
    ) {
        super();
        this.ref.onMaximize.subscribe((value: any) => {
            this.onMaximize(value.maximized);
        });
    }

    private onMaximize(value: boolean) {
        this.isMaximize = value;
    }

    public onZoomPointInspection(pointInspection: PointInspectionDto) {
        this.mapService.zoomPointInspection(pointInspection);
    }

    public onOpenPhotoListDialog(photoAnomalie: PhotoEntity[], photoPoteau: PhotoEntity[]) {
        this.photoAnomalieList = photoAnomalie;
        this.photoPoteauList = photoPoteau;
        this.photoListDialogVisible = true;
    }

    public onClosePhotoListDialog() {
        this.photoListDialogVisible = false;
    }

    public closeAnomalieListDialog() {
        this.visible = false;
        this.visibleChange.emit(this.visible);
    }

    onRowEditInit(anomalie: AnomalieList) {
        this.clonedAnomalie[anomalie.id] = { ...anomalie };
    }

    onRowEditSave(anomalie: AnomalieList, pointInspection: PointInspectionDto) {
        delete this.clonedAnomalie[anomalie.id];

        const updatedAnomalie = {
            ...pointInspection.anomalies.find(a => a.id === anomalie.id),
            prioriteAnalyse: anomalie.prioriteAnalyse,
            remarqueAnalyse: anomalie.remarqueAnalyse
        };

        this.store.dispatch(InspectionActions.updateAnomalieInspection({ pointInspection, anomalie: updatedAnomalie }));
    }

    onRowEditCancel(anomalie: AnomalieList) {
        anomalie.prioriteAnalyse = this.clonedAnomalie[anomalie.id].prioriteAnalyse;
        anomalie.remarqueAnalyse = this.clonedAnomalie[anomalie.id].remarqueAnalyse;
        delete this.clonedAnomalie[anomalie.id];
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }
}


