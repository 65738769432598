import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { RechercheBase } from '../recherche-base';
import { HQValidators } from '../../../../../shared/validators/hq-validators';
import { FeatureCollection } from 'geojson';
import { MapService } from '../../../../../map/services/map.service';
import { EsriRequeteService } from '../../../../../services/esri-requete.service';

@Component({
    selector: 'app-recherche-sqrc',
    templateUrl: './recherche-sqrc.component.html',
    styleUrls: ['./recherche-sqrc.component.scss']
})
export class RechercheSqrcComponent extends RechercheBase implements OnInit {
    public totalCaracteres = 14;
    public inputMinMaxLength = `${this.totalCaracteres}`;
    public placeholder = `22D07-010-0102`;
    private _showForm: boolean = false;
    public recherche: FeatureCollection | null = null;
    public query: string = '';

    @Input() public unselectRow: boolean = false;

    @Input() public set showForm(value: boolean) {
        this._showForm = value;
        if (!value) {
            this.resetValue();
        }
    }

    public get showForm(): boolean {
        return this._showForm;
    }

    constructor(
        protected store: Store,
        private mapService: MapService,
        private esriRequeteService: EsriRequeteService,
    ) {
        super(store);
    }

    public ngOnInit(): void {
        this.subscribeToQueryStringOnChange();
    }

    protected specificInitForm(): void {
        this.rechercheForm.controls.queryString.setValidators([
            Validators.maxLength(this.totalCaracteres),
            Validators.minLength(this.totalCaracteres),
            HQValidators.sqrc()
        ]);
        this.rechercheForm.controls.queryString.updateValueAndValidity();
    }

    protected async rechercher() {
        this.query = this.rechercheForm.controls.queryString.value;
        this.mapService.draw.deleteAll();

        await this.esriRequeteService.rechercheSQRC(this.query).then((response) => {
            if (response) {
                this.showForm = true;
                this.recherche = response as FeatureCollection;
            }

        });
    }
}
