import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, takeUntil, take } from 'rxjs/operators';
import saveAs from 'file-saver';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AnomalieList } from '../../models/anomalie-list.model';
import { Projet } from '../../../projet/models';
import { State } from '../../state/anomalie-list/anomalie-list.state';
import * as AnomalieListActions from '../../state/anomalie-list/anomalie-list.action';
import {
    getAnomalieList,
    getAnomalieListLoading,
    getStatutPhotoExtraction,
    getCreatePhotoExtraction
} from '../../state/anomalie-list/anomalie-list.selectors';
import { StatutPhotoExtraction } from '../../models/statut-photo-extraction.enum';
import { PhotoExtraction } from '../../models/photo-extraction.model';
import { WorkBook, utils, write } from 'xlsx';
import { dateTimeChange, saveFile } from '../../../../shared/utils';

@Component({
    selector: 'app-anomalie-list',
    templateUrl: './anomalie-list.component.html',
    styleUrls: ['./anomalie-list.component.scss']
})
export class AnomalieListComponent extends BaseComponent implements OnInit, OnDestroy {

    public get projet(): Projet {
        return this.config.data.projet;
    }

    public anomalieList$: Observable<AnomalieList[]> = this.store.select(getAnomalieList);
    public anomalieListLoading$: Observable<boolean> = this.store.select(getAnomalieListLoading);

    public photoExtraction: PhotoExtraction | null;
    public statutPhotoExtractionEnum = StatutPhotoExtraction;

    public anomalieListDialogVisible = false;
    public photoExtractionDialogVisible = false;

    constructor(
        private store: Store<State>,
        public config: DynamicDialogConfig,
        private messageService: MessageService
    ) {
        super();
    }

    ngOnInit() {
        this.store.dispatch(AnomalieListActions.loadAnomalieList({ projetId: this.projet?.id }));

        this.subscribeToLoadAnomalieList();
        this.subscribeToLoadStatutPhotoExtraction();
    }

    private subscribeToLoadAnomalieList() {
        this.store.select(getAnomalieList)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed)
            )
            .subscribe(anomalieList => {
                if (anomalieList) {
                    this.anomalieListDialogVisible = true;
                }
            });
    }

    private subscribeToLoadStatutPhotoExtraction() {
        this.store.dispatch(AnomalieListActions.loadStatutPhotoExtraction({ projetId: this.projet?.id }));

        this.store.select(getStatutPhotoExtraction)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed)
            )
            .subscribe(photoExtraction => {
                this.photoExtraction = photoExtraction;
            });
    }

    private subscribeToCreatePhotoExtraction() {
        this.store.dispatch(AnomalieListActions.createPhotoExtraction({ projetId: this.projet?.id }));

        this.store.select(getCreatePhotoExtraction)
            .pipe(
                filter(success => !!success),
                take(1)
            )
            .subscribe(photoExtraction => {
                this.photoExtraction = photoExtraction;
                this.messageService.add(
                    {
                        severity: 'info',
                        summary: `Extraction des photos`,
                        detail: `Votre demande d'extraction de photos pour le projet ${this.projet.nom} est en cours`,
                        life: 5000,
                        closable: true
                    }
                );
            });
    }

    createPhotoExtraction() {
        this.subscribeToCreatePhotoExtraction();
        const interval = setInterval(() => {
            this.store.dispatch(AnomalieListActions.loadStatutPhotoExtraction({ projetId: this.projet.id! }));

            this.store.select(getStatutPhotoExtraction)
                .pipe(
                    filter(success => !!success),
                    takeUntil(this.destroyed)
                )
                .subscribe(photoExtraction => {
                    this.photoExtraction = photoExtraction;
                    if (photoExtraction?.statut === StatutPhotoExtraction.StatutEnum.Disponible) {
                        clearInterval(interval);
                    }
                });
        }, 5000);
    }

    onPhotoExtraction() {
        switch (this.photoExtraction?.statut) {
            case StatutPhotoExtraction.StatutEnum.Disponible: {
                this.photoExtractionDialogVisible = true;
            } break;
            case StatutPhotoExtraction.StatutEnum.Aucune: {
                this.createPhotoExtraction();
            } break;
            case StatutPhotoExtraction.StatutEnum.EnCours: {
            } break;
        }
    }

    onDownloadPhotoExtraction() {
        saveAs(this.photoExtraction?.url);
    }

    private prepareDataForExcel(anomalieList: AnomalieList[]): any[] {
        const formatDate = (date: Date | number) => date ? dateTimeChange(date.toString(), 'YYYY-MM-dd') : '';
        return anomalieList.map(anomalie => {
            return {
                ...anomalie,
                dateTransfertSap: formatDate(anomalie.dateTransfertSap),
            };
        });
    }

    public exportExcel(anomalieList: AnomalieList[]) {
        const formatedAnomaliesList = this.prepareDataForExcel(anomalieList);
        if (formatedAnomaliesList) {
            const worksheet = utils.json_to_sheet(formatedAnomaliesList);
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const workbook: WorkBook = { Sheets: { 'listeAnomalies': worksheet }, SheetNames: ['listeAnomalies'] };
            const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });
            const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            saveFile(excelBuffer, 'Liste des anomalies ' + this.projet.nom, FILE_TYPE, '.xlsx');
        }
    }
}
