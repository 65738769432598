import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const lclclsValidator: ValidatorFn = (
    control: AbstractControl,
): ValidationErrors | null => {
    const distant = control.get('lclclDistant');
    const poteau = control.get('lclclPoteau');

    const emptyValue = [undefined, null, ''];
    if (distant && poteau && (
        (emptyValue.includes(poteau.value) && !emptyValue.includes(distant.value)) ||
        (!emptyValue.includes(poteau.value) && emptyValue.includes(distant.value))
    )) {
        return null;
    } else if (distant && poteau && (
        (!emptyValue.includes(poteau.value) && !emptyValue.includes(distant.value))
    )) {
        return { bothLCLCL: true };
    } else if (!distant.value && !poteau.value) {
        return { oneLCLCLRequired: true };
    } else {
        return null;
    }
};
