import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BaseComponent } from '../../../../../shared/components/abstract-base-component';
import { filter, takeUntil } from 'rxjs/operators';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PointInspectionDto, ProjetCompletDto } from '../../../../../core/api/client/models';
import { Store } from '@ngrx/store';
import { State } from '../../../../../state/app.state';
import * as InspectionActions from '../../../state/inspection.actions';
import * as OfflineActions from '../../../../offline/state/offline.actions';
import * as SyncActions from '../../../../synchronisation/state/synchronisation.actions';
import { StatutProjet, StatutProjetValue } from '../../../models/statut-projet.enum';
import { TableColumn } from '../../../../../shared/models/table-column.model';
import { getActivateProjetInspectionSuccess, getInvalidPointsInspection } from '../../../state/inspection.selectors';
import { getProjetsDownloaded, getProjetsDownloadedLoading } from '../../../../offline/state/offline.selectors';
import { MapService } from '../../../../../map/services/map.service';
import { UiService } from '../../../../../services/ui.service';
import { SynchronisationService } from '../../../../synchronisation/services/synchronisation.service';
import { ProjetAction } from '../../../models/projet-action.enum';
import { TypeReseau } from '../../../models/type-reseau.enum';
import { getRapportValidationPermission } from '../../../utils/inspection-permissions.utils';
import { getRequests, getSyncErrors } from '../../../../synchronisation/state/synchronisation.selectors';
import { SyncErrors } from '../../../../synchronisation/models/sync-errors.model';
import { MAX_ACTIVE_PROJET_INSPECTION } from '../../../models/inspection.const';
import { CompleteProjetDialogComponent } from '../../complete-projet-dialog/complete-projet-dialog.component';
import { Requests } from '../../../../synchronisation/models/requests.model';
import { selectUtilisateurIsControleurQualite } from '../../../../../state/shared/shared.selectors';
import {
    ProjetsRapportValidationDialogComponent
} from '../../../../projets/components/dialog/projets-rapport-validation-dialog/projets-rapport-validation-dialog.component';

@Component({
    selector: 'app-projet-list-mobile-dialog',
    templateUrl: './projet-inspection-list-mobile-dialog.component.html',
    styleUrls: ['./projet-inspection-list-mobile-dialog.component.scss']
})
export class ProjetInspectionListMobileDialogComponent extends BaseComponent implements AfterViewInit, OnInit {
    @ViewChildren('actionsButton') actionsButton: QueryList<ElementRef>;

    public projetsDownloadedLoading = false;
    private projetsDownloaded: ProjetCompletDto[] = [];
    private _projetInspectionList: ProjetCompletDto[] = [];
    private invalidPointsInspection: PointInspectionDto[] = [];
    private requests: Requests[] = [];
    private syncErrors: SyncErrors[] = [];
    public selectedProjetInspection: ProjetCompletDto | null;
    public statutProjetValue = StatutProjetValue;
    public isControleurQualite = false;
    public typeReseau = TypeReseau;
    public menuItems: MenuItem[];
    public assignationDate: number | null = null;

    @Input()
    set projetInspectionList(value: ProjetCompletDto[]) {
        this._projetInspectionList = value;
        this.selectedProjetInspection = null;
    }

    get projetInspectionList() {
        return this._projetInspectionList;
    }

    @Input() isOffline: boolean;
    @Input() columns: TableColumn[];
    @Input() canInspecterProjet: boolean;
    @Input() canInspecterProjetAQ: boolean;
    @Input() canCompleteProjet: boolean;
    @Input() canCompleteProjetAQ: boolean;
    @Input() canOpenRapportValidation: boolean;

    constructor(
        private store: Store<State>,
        private syncService: SynchronisationService,
        private dialogService: DialogService,
        private confirmationService: ConfirmationService,
        public ref: DynamicDialogRef,
        private cdr: ChangeDetectorRef,
        private mapService: MapService,
        private uiService: UiService
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(OfflineActions.getProjetsDownloaded());
        this.store.dispatch(SyncActions.getSyncErrors());
        this.store.dispatch(SyncActions.getRequests());

        this.subscribeToProjetsDownloaded();
        this.subscribeToProjetsDownloadedLoading();
        this.subscribeToIsControleurQualite();
        this.subscribeToSyncErrors();
        this.subscribeToRequests();
    }

    public selectionChanged(projet: ProjetCompletDto) {
        this.selectedProjetInspection = projet;
        this.getAssignationDate(projet);
    }

    private getAssignationDate(projet: ProjetCompletDto) {
        this.assignationDate = null;
        if (projet) {
            const statusInspection: string[] = [StatutProjet.inspectionAssigne, StatutProjet.inspectionEnCours];
            if (statusInspection.includes(projet.statut)) {
                this.assignationDate = projet.assigneLe;
            }
            const statusAQ: string[] = [StatutProjet.aqAssigne, StatutProjet.aqEnCours];
            if (statusAQ.includes(projet.statut)) {
                this.assignationDate = projet.assigneAQLe;
            }

            const statusCorrection: string[] = [StatutProjet.correctionAssignee, StatutProjet.correctionEnCours];
            if (statusCorrection.includes(projet.statut)) {
                this.assignationDate = projet.correctionLe;
            }

            const acceptedStatus: string[] = [
                ...statusInspection,
                ...statusAQ,
                ...statusCorrection
            ];

            if (!acceptedStatus.includes(projet.statut)) {
                this.assignationDate = projet.statutModifieLe;
            }
        }
    }

    initMenuItems(projet: ProjetCompletDto) {
        this.menuItems = [
            {
                label: 'Zoom sur',
                icon: 'pi pi-fw pi-search-plus',
                command: () => {
                    this.ref.close(true);
                    this.mapService.zoomProjetInspection(projet);
                }
            },
            {
                label: 'Inspecter',
                icon: 'pi pi-fw pi-download',
                visible: this.canInspecterProjet,
                disabled: this.disableInspecterProjet(projet),
                command: () => this.activateProjetInspection(projet, ProjetAction.ACTIVER)
            },
            {
                label: 'Inspecter AQ',
                icon: 'pi pi-fw pi-download',
                visible: this.canInspecterProjetAQ,
                disabled: this.disableInspecterAQProjet(projet),
                command: () => this.activateProjetInspection(projet, ProjetAction.ACTIVER_AQ)
            },
            {
                label: 'Télécharger de nouveau',
                icon: 'pi pi-fw pi-refresh',
                visible: this.canDownloadProjet(projet),
                disabled: this.disableDownloadProjet(projet),
                command: () => this.downloadProjetInspectionConfirm(projet)
            },
            {
                label: 'Rapport de validation',
                icon: 'pi pi-fw pi-file',
                visible: this.canOpenRapportValidation,
                disabled: !getRapportValidationPermission(projet) || !this.isProjetInspectionDownloaded(projet),
                command: () => {
                    this.ref.close(true);
                    this.openRapportValidation(projet);
                }
            },
            {
                label: 'Compléter',
                icon: 'pi pi-flag',
                visible: this.canCompleteProjet || this.canCompleteProjetAQ,
                disabled: this.disableCompleteProjet(projet),
                command: () => this.completeProjet(projet, ProjetAction.COMPLETER)
            },
            {
                label: 'Compléter AQ',
                icon: 'pi pi-flag',
                visible: this.canCompleteProjetAQ,
                disabled: this.disableCompleteAQProjet(projet),
                command: () => this.completeProjet(projet, ProjetAction.COMPLETER_AQ)
            },
            {
                label: `Rapport d’erreurs de synchronisation`,
                icon: 'fas fa-exclamation-circle',
                disabled: !this.syncErrors.find(syncError => syncError.pointInspection?.projetId === projet.id),
                command: () => {
                    this.ref.close(true);
                    this.syncService.openRapportErreursSynchronisationDialog(this.requests, projet.id);
                }
            }
        ];
    }

    onRowSelect(projet: ProjetCompletDto, contextMenu: ContextMenu) {
        this.store.dispatch(InspectionActions.setCurrentActiveProjetInspection({ projetInspection: projet }));
        this.uiService.closeActionSheet(true);
        contextMenu.hide();
    }

    onRowUnselect(contextMenu: ContextMenu) {
        this.store.dispatch(InspectionActions.setCurrentActiveProjetInspection({ projetInspection: null }));
        contextMenu.hide();
    }

    onActionsButtonClick(event: MouseEvent, contextMenu: ContextMenu, selectedProjetInspection: ProjetCompletDto) {
        this.initMenuItems(selectedProjetInspection);
        event.stopPropagation();
        event.preventDefault();
        contextMenu.show(event);
    }

    private activateProjetInspection(projet: ProjetCompletDto, action: ProjetAction) {
        this.store.dispatch(InspectionActions.activateProjetInspection({ projetInspectionId: projet.id, activateData: { action } }));
        this.subscribeToActivateProjetInspectionSuccess();
    }

    private subscribeToProjetsDownloaded() {
        this.store.select(getProjetsDownloaded)
            .pipe(
                filter(projetsInspectionDownloaded => !!projetsInspectionDownloaded),
                takeUntil(this.destroyed)
            )
            .subscribe(projetsInspectionDownloaded => {
                this.projetsDownloaded = projetsInspectionDownloaded;
            });
    }

    private subscribeToProjetsDownloadedLoading() {
        this.store.select(getProjetsDownloadedLoading)
            .pipe(takeUntil(this.destroyed))
            .subscribe(projetsDownloadedLoading => {
                this.projetsDownloadedLoading = projetsDownloadedLoading;
            });
    }

    private subscribeToActivateProjetInspectionSuccess() {
        this.store.select(getActivateProjetInspectionSuccess)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed)
            )
            .subscribe(activateResult => {
                if (activateResult.success) {
                    this.downloadProjetInspection(activateResult.projetInspection);
                    this.ref.close(true);
                }
            });
    }

    private subscribeToInvalidPointsInspection() {
        this.store.select(getInvalidPointsInspection)
            .pipe(
                takeUntil(this.destroyed)
            )
            .subscribe((invalidPointsInspection) => {
                this.invalidPointsInspection = invalidPointsInspection;
            });
    }

    private subscribeToRequests() {
        this.store.select(getRequests)
            .pipe(
                filter(requests => !!requests),
                takeUntil(this.destroyed)
            )
            .subscribe(requests => {
                this.requests = requests;
            });
    }

    private subscribeToSyncErrors() {
        this.store.select(getSyncErrors)
            .pipe(
                filter(syncErrors => !!syncErrors),
                takeUntil(this.destroyed)
            )
            .subscribe(syncErrors => {
                this.syncErrors = syncErrors;
            });
    }

    private subscribeToIsControleurQualite() {
        this.store.select(selectUtilisateurIsControleurQualite)
            .pipe(
                takeUntil(this.destroyed)
            )
            .subscribe((isControleurQualite) => {
                this.isControleurQualite = isControleurQualite;
            });
    }

    private canDownloadProjet(projet: ProjetCompletDto): boolean {
        return projet.statut === StatutProjet.aqEnCours
            || projet.statut === StatutProjet.correctionEnCours
            || projet.statut === StatutProjet.inspectionEnCours;
    }

    private disableDownloadProjet(projet: ProjetCompletDto): boolean {
        return this.isProjetInspectionDownloaded(projet);
    }

    private disableInspecterProjet(projetInspection: ProjetCompletDto): boolean {
        if (this.isControleurQualite) {
            const nbProjetEnCour = this._projetInspectionList.filter(
                projet => projet.statut === StatutProjet.correctionEnCours || projet.statut === StatutProjet.aqEnCours
            ).length;

            return !(projetInspection.statut === StatutProjet.correctionAssignee && nbProjetEnCour <= (MAX_ACTIVE_PROJET_INSPECTION - 1));
        } else {
            const nbProjetEnCour = this._projetInspectionList.filter(
                projet => projet.statut === StatutProjet.correctionEnCours || projet.statut === StatutProjet.inspectionEnCours
            ).length;

            return !((projetInspection.statut === StatutProjet.inspectionAssigne || projetInspection.statut === StatutProjet.correctionAssignee) &&
                nbProjetEnCour <= (MAX_ACTIVE_PROJET_INSPECTION - 1));
        }
    }

    private disableInspecterAQProjet(projetInspection: ProjetCompletDto): boolean {
        const nbProjetEnCour = this._projetInspectionList.filter(
            projet => projet.statut === StatutProjet.correctionEnCours || projet.statut === StatutProjet.aqEnCours
        ).length;

        return !(projetInspection.statut === StatutProjet.aqAssigne && nbProjetEnCour <= (MAX_ACTIVE_PROJET_INSPECTION - 1));
    }

    private disableCompleteProjet(projet: ProjetCompletDto): boolean {
        return !(projet.statut === StatutProjet.inspectionEnCours || projet.statut === StatutProjet.correctionEnCours)
            || (!this.isProjetInspectionDownloaded(projet) || !!this.requests.length) || this.isOffline;
    }

    private disableCompleteAQProjet(projet: ProjetCompletDto): boolean {
        return !(projet.statut === StatutProjet.aqEnCours)
            || (!this.isProjetInspectionDownloaded(projet) || !!this.requests.length) || this.isOffline;
    }

    public isProjetInspectionActivated(projet: ProjetCompletDto): boolean {
        return projet.statut === StatutProjet.aqEnCours
            || projet.statut === StatutProjet.correctionEnCours
            || projet.statut === StatutProjet.inspectionEnCours;
    }

    public isProjetInspectionDownloaded(projetInspection: ProjetCompletDto): boolean {
        return !!this.projetsDownloaded?.find(projet => projet.id === projetInspection.id);
    }

    private downloadProjetInspectionConfirm(projet: ProjetCompletDto) {
        this.confirmationService.confirm({
            message: `Attention:
            Si vous travaillez sur le projet sur un autre appareil, il peut y avoir des erreurs de mise à jour.`,
            accept: () => {
                this.downloadProjetInspection(projet);
                this.ref.close(true);
            },
            key: 'downloadProjetInspectionConfirm',
        });
    }

    private downloadProjetInspection(projet: ProjetCompletDto) {
        void this.mapService.loadTuilesProjet(JSON.parse(projet.geometrie));
        this.store.dispatch(OfflineActions.addProjetToIndexedDb({ projetId: projet.id }));
    }

    private validateInvalidProjetConfirm(projet: ProjetCompletDto, action: ProjetAction) {
        this.confirmationService.confirm({
            message: `Vous ne pouvez pas compléter votre projet puisqu'il y a des points d'inspection non valides.<br><br>
                Veuillez consulter le rapport de validation et effectuer les corrections avant de compléter votre projet.`,
            accept: () => {
                this.openCompleteProjetDialog(projet, action);
            },
            reject: (type: ConfirmEventType) => {
                if (type === ConfirmEventType.REJECT) {
                    this.ref.close(true);
                    this.openRapportValidation(projet);
                }
            },

            key: 'validateInvalidProjetConfirm',
        });
    }

    private completeProjet(projet: ProjetCompletDto, action: ProjetAction) {
        this.subscribeToInvalidPointsInspection();

        if (this.invalidPointsInspection.length) {
            this.validateInvalidProjetConfirm(projet, action);
        } else {
            if (projet.nombrePoteauxInspectes !== projet.nombreTotalPoteaux && action !== ProjetAction.COMPLETER_AQ) {
                this.openCompleteProjetDialog(projet, action);
            } else {
                this.store.dispatch(InspectionActions.completeProjetInspection({ projetId: projet.id, completeData: { action } }));
            }
        }
    }

    private openCompleteProjetDialog(projet: ProjetCompletDto, action: ProjetAction) {
        this.ref = this.dialogService.open(CompleteProjetDialogComponent,
            {
                header: 'Compléter un projet',
                modal: true,
                styleClass: 'mobile-dialog',
                data: {
                    projet,
                    action
                }
            });
    }

    private openRapportValidation(projet: ProjetCompletDto) {
        this.ref = this.dialogService.open(ProjetsRapportValidationDialogComponent,
            {
                header: 'Rapport de validation',
                width: '100%',
                height: '100%',
                modal: false,
                styleClass: 'mobile-dialog',
                data: { projetId: projet.id }
            });
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }
}
