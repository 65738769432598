export enum LocalStorageIndex {
    ESRI_ACCESS_TOKEN = 'esri_access_token',
    FIRME = 'firme',
    USER_ROLES = 'user_roles',
    USER_EMAIL = 'user_email',
    USER_ID = 'user_id',
    LEGEND_LAYERS_MODIFIED = 'legend_layers_modified',
    PROJETS_LIST = 'projets_list',
    PROJETS_LIST_DIALOG_COLUMN_NAMES = 'projets_list_dialog_column_names',
}
