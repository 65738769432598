import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AnomalieService } from '../../services/anomalie.service';
import * as AnomalieListActions from './anomalie-list.action';
import { MessageService } from 'primeng/api';
@Injectable()
export class AnomalieListEffects {

    loadAnomalieList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AnomalieListActions.loadAnomalieList),
            mergeMap(action =>
                this.anomalieService.getAnomalies({ id: action.projetId })
                    .pipe(
                        map(anomalieList => AnomalieListActions.loadAnomalieListSuccess({ anomalieList })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Erreur Liste des anomalies`,
                                        detail: `Une erreur est survenue lors de la récupération de la liste des anomalies.`,
                                        closable: true
                                    });
                            }

                            return of(AnomalieListActions.loadAnomalieListFailure({ error }));
                        })
                    )));
    });

    loadStatutPhotoExtraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AnomalieListActions.loadStatutPhotoExtraction),
            mergeMap(action =>
                this.anomalieService.getStatutPhotoExtraction(action.projetId)
                    .pipe(
                        map(statutPhotoExtraction => AnomalieListActions.loadStatutPhotoExtractionSuccess({ statutPhotoExtraction })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Statut d'extraction des photos`,
                                        detail: `Une erreur est survenue lors de la récupération du statut d'extraction des photos.`,
                                        closable: true
                                    }
                                );
                            }

                            return of(AnomalieListActions.loadStatutPhotoExtractionFailure({ error }));
                        })
                    )));
    });

    createPhotoExtraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AnomalieListActions.createPhotoExtraction),
            mergeMap(action =>
                this.anomalieService.createPhotoExtraction(action.projetId)
                    .pipe(
                        map(photoExtraction => AnomalieListActions.createPhotoExtractionSuccess({ photoExtraction })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Extraction des photos`,
                                        detail: `Une erreur est survenue lors de l'extraction des photos.`,
                                        closable: true
                                    }
                                );
                            }

                            return of(AnomalieListActions.createPhotoExtractionFailure({ error }));
                        })
                    )));
    });

    constructor(
        private actions$: Actions,
        private anomalieService: AnomalieService,
        private messageService: MessageService
    ) { }
}
