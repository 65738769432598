import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, takeUntil, tap } from 'rxjs';
import { Store } from '@ngrx/store';

import { recherche } from '../../state/recherche.actions';
import { RechercheBackendValue } from '../../models/recherche.enum';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';

export abstract class RechercheBase extends BaseComponent {
    public displayError: boolean = false;
    public showResult = false;
    public isLocalSearch = false;
    public rechercheForm: FormGroup;
    public rechercheLocalValue: string | null = null;
    private _isUserMobile: boolean = false;

    // Abstract Properties
    public abstract inputMinMaxLength: string;
    public abstract totalCaracteres: number;

    constructor(
        protected store: Store,
    ) {
        super();
        this.initForm();
    }

    // Abstract Methods
    protected abstract specificInitForm(): void;
    protected abstract rechercher(): void;

    protected set isUserMobile(value: boolean) {
        this._isUserMobile = value;
    }
    protected get isUserMobile(): boolean {
        return this._isUserMobile;
    }

    protected initForm() {
        this.rechercheForm = new FormGroup({
            queryString: new FormControl('', [Validators.required]),
            includeSig: new FormControl({ value: false, disabled: true }),
        });

        this.specificInitForm();
    }

    protected subscribeToQueryStringOnChange() {
        this.rechercheForm.controls.queryString.valueChanges
            .pipe(
                tap((query: string) => {
                    this.evaluer(query);
                }),
                filter((query: string) => this.queryStringFilter(query)),
                takeUntil(this.destroyed)
            )
            .subscribe(() => this.onRecherche());
    }

    protected queryStringFilter(query: string): boolean {
        return query.length === this.totalCaracteres;
    }

    protected subscribeToIncludeSigOnChange() {
        this.rechercheForm.controls.includeSig.valueChanges
            .pipe(
                takeUntil(this.destroyed)
            )
            .subscribe(() => this.onRecherche());
    }

    protected patchQueryStringValue(query: string) {
        this.rechercheForm.controls.queryString.patchValue(query.toUpperCase(), { emitEvent: false });
    }

    protected evaluer(query: string) {
        if (query === '' || query.length === 0) {
            this.displayError = false;
            this.resetValue();
        } else {
            this.patchQueryStringValue(query);
            this.rechercheForm.controls.queryString.patchValue(query.toUpperCase(), { emitEvent: false });
            if (query.length < this.totalCaracteres) {
                this.displayError = true;
                this.rechercheForm.controls.includeSig.setValue(false, { emitEvent: false });
            }
            if (this.totalCaracteres > 0) {
                this.evaluateDisplaySig(query.length === this.totalCaracteres);
            }
        }
    }

    private evaluateDisplaySig(shouldEnableSig: boolean) {
        shouldEnableSig ? this.rechercheForm.controls.includeSig.enable({ emitEvent: false }) : this.rechercheForm.controls.includeSig.disable({ emitEvent: false });
    }

    public onRecherche() {
        if (this.rechercheForm.valid && this.rechercheForm.controls.queryString.value.length > 0) {
            this.displayError = false;
            this.rechercheByContext();
        } else {
            this.displayError = true;
        }
    }

    protected rechercheByContext() {
        this.showResult = true;
        if (this.isUserMobile) {
            this.isLocalSearch = true;
            this.rechercheLocalValue = this.rechercheForm.controls.queryString.value;
        } else {
            this.isLocalSearch = false;
            this.rechercher();
        }
    }

    protected dispatchRecherche(backendParamName: RechercheBackendValue) {
        this.store.dispatch(recherche({
            name: backendParamName,
            value: this.rechercheForm.controls.queryString.value,
            includeSig: this.rechercheForm.controls.includeSig.value,
        }));
    }

    protected resetValue() {
        this.rechercheForm.reset({
            queryString: '',
            includeSig: false
        }, { emitEvent: false });
        this.evaluateDisplaySig(false);
        this.isLocalSearch = false;
        this.rechercheLocalValue = null;
        this.showResult = false;
    }
}
