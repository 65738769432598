// Ce fichier n'est plus utilisé depuis PDL-655
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { cloneDeep, min } from 'lodash';
import { MessageService } from 'primeng/api';
import { lastValueFrom, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { changeInspectionStatut } from 'src/app/features/projet/models/change-inspection-statut.model';
import { PointInspection } from 'src/app/features/projet/models/point-inspection.model';
import { ApiService } from 'src/app/services/api.service';
import { Severite } from '../../../enums/severite';
import { StatutPointInspection, StatutPointInspectionNumberValue } from '../../../features/inspection/models/statut-point-inspection.enum';
import { StoreName } from '../../../features/offline/models/indexed-db-store-name.enum';
import { Anomalie } from '../../../features/anomalie/models/anomalie.model';
import { CreatePointInspection } from '../../../features/projet/models/create-point-inspection.model';
import { Projet } from '../../../features/projet/models/projet.model';
import { AnomaliePilotageService } from '../../../services/anomalie-pilotage.service';
import { IndexedDbService } from '../../../features/offline/services/indexed-db.service';
import { OfflineService } from '../../../features/offline/services/offline.service';
import { generateUuid, mergeDeep, transformToFormData } from '../../../shared/utils';
import { Operation } from 'fast-json-patch';
import * as jsonpatch from 'fast-json-patch';
import {
    createAnomalieSuccess, createPointInspectionSuccess, deleteAnomalieSuccess, forbiddenGetPointsInspectionBbox,
    forbiddenUpdatePointInspection, getPointsInspectionBboxSuccess, patchPointInspectionSuccess,
    PointInspectionActionTypes, setPointInspectionSansAnomalieSuccess, updateAnomalieSuccess, updatePointInspectionSuccess
} from '../actions/pointInspection.action';
import { AnomaliePilotageState } from '../reducers/anomaliesPilotage.reducer';
import { getAllAnomaliesPilotageState } from '../selectors/anomaliesPilotage.selector';
import { Bounds } from '../../../shared/models/bounds.model';
import { mapStyleConfig } from '../../../map/models/map-style-config.const';
import { UtilisateurService } from '../../../services/utilisateur.service';
import { UserRole } from '../../../shared/models/user-roles.model';
import { PhotoBackend } from '../../../shared/models/photo-backend.model';
import { TypeObjetSync } from '../../../features/synchronisation/models/type-objet-sync.enum';
import { StackedRequete } from '../../../features/synchronisation/models/stacked-requete';
//import { updateAnomalieListSuccess } from '../../../features/anomalie/state/anomalie-list/anomalie-list.action';
import { RequestType } from '../../../features/synchronisation/models/request-type.enum';
import { PointInspectionState } from '../reducers/pointInspection.reducer';
import { filterDeletedAnomalies } from '../selectors/pointInspection.selectors';
//import { getCurrentActiveProjetAudit } from '../../../features/audit/state/audit.selectors';
import { State } from '../../../state/app.state';
import { getCurrentActiveProjetInspection } from '../../../features/inspection/state/inspection.selectors';

export interface JSONPatchObj {
    op: string;
    path: string;
    value: string;
}

@Injectable()
export class PointInspectionEffects {

    public getPointsInspectionWithBbox$ = createEffect(() => this.actions$.pipe(
        ofType(PointInspectionActionTypes.GET_POINTS_INSPECTIONS_BBOX),
        debounceTime(200),
        switchMap((action: { bbox: Bounds, zoomLevel: number }) => {
            if (!navigator.onLine || this.offlineModeService.isOfflineMode ||
                this.utilisateurService.isOneOfRoles([
                    UserRole.AUDITEUR_EXTERNE,
                    UserRole.AUDITEUR_HQD,
                    UserRole.CONTROLEUR_QUALITE_EXTERNE,
                    UserRole.INSPECTEUR_EXTERNE,
                    UserRole.INSPECTEUR_HQD
                ])) {
                return this.getLocalProject$().pipe(
                    map(projet => projet?.pointInspections || [])
                );
            }
            if (action.zoomLevel >= mapStyleConfig.poteau.minZoom) {
                const bboxParams = `xmin=${action.bbox.xmin}&ymin=${action.bbox.ymin}&xmax=${action.bbox.xmax}&ymax=${action.bbox.ymax}`;
                return this.apiService.get<PointInspection[]>(`/inspections?pageSize=5000&${bboxParams}`).pipe(
                    map((x: any) => x.items)
                );
            }
            return of([]);
        }),
        mergeMap(pointsInspection => this.filterDeletedAnomaliesPointsInspection(pointsInspection)),
        map((pointsInspection) => {
            if (!pointsInspection) {
                return forbiddenGetPointsInspectionBbox();
            }

            return getPointsInspectionBboxSuccess({ pointsInspection });
        })
    ));

    public updatePointInspection$ = createEffect(() => this.actions$.pipe(
        ofType(PointInspectionActionTypes.UPDATE_POINT_INSPECTION),
        mergeMap((
            action: {
                pointInspection: Partial<PointInspection>,
                originalPointInspection: PointInspection,
                originalPhotos?: PhotoBackend[],
                projets: Projet[],
            }
        ) => {
            const { photos, ...pointInspectionSansPhoto } = action.pointInspection;
            const pointInspectionOriginalSansPhoto = this.removePhotoFromObject(action.originalPointInspection);

            const diff = jsonpatch.compare(pointInspectionOriginalSansPhoto, pointInspectionSansPhoto);

            return this.getLocalProject$().pipe(
                switchMap(async projet => {
                    const index = projet.pointInspections!.findIndex(pointInspection => pointInspection.id === action.pointInspection.id);
                    const pointInspectionOriginal = projet.pointInspections![index];

                    if (this.offlineModeService.isOfflineMode) {
                        if (diff.length > 0) {
                            const requestToAdd = {
                                type: RequestType.PATCH,
                                route: `/inspections/${action.pointInspection.id}`,
                                body: diff,
                                piId: action.pointInspection.id,
                                pi: action.pointInspection,
                                typeOriginal: TypeObjetSync.POINT_INSPECTION,
                                original: action.originalPointInspection,
                                originalId: action.pointInspection.id,
                            };

                            this.addRequestToPile(requestToAdd, action.pointInspection).subscribe();
                            projet.pointInspections![index] = { ...pointInspectionOriginal, ...action.pointInspection as PointInspection };
                            return this.updateLocalProject(projet).pipe(
                                map((p) => p[0].pointInspections as PointInspection[]),
                                map((pointInspection) => {
                                    return {
                                        ...pointInspection[index],
                                        photos,
                                        originalPhotos: action.originalPhotos
                                    };
                                })
                            );
                        } else {
                            return of({
                                ...pointInspectionOriginal,
                                ...action.pointInspection,
                                photos,
                                originalPhotos: action.originalPhotos,
                            });
                        }
                    }
                    return this.apiService.patch<PointInspection, Operation[]>(`/inspections/${action.pointInspection.id}`, diff).pipe(
                        switchMap(updatedPointInspection => {
                            projet.pointInspections![index] = { ...pointInspectionOriginal, ...updatedPointInspection };
                            return this.updateLocalProject(projet).pipe(
                                map((p) => p[0].pointInspections as PointInspection[]),
                                map((pointInspection) => pointInspection[index])
                            );
                        }),
                        map(updatedPointInspection => {
                            if (!updatePointInspectionSuccess) {
                                return null;
                            }
                            const p: any = {
                                ...updatedPointInspection,
                                photos,
                                originalPhotos: action.originalPhotos
                            };
                            return p;
                        })
                    );
                }),
                mergeMap((pointInspection) => pointInspection)
            );
        }),
        mergeMap((pointInspection: PointInspection) => {
            let pointInspectionPhotos: PhotoBackend[] = [];
            const isPointInspectionCompleted$ = new Subject<void>();
            const pointInspection$ = new Subject<void>();

            const deletedPhotos = this.getDeletedPhotos(pointInspection?.photos, (pointInspection as any)?.originalPhotos);
            const newPhotos = this.getNewPhotos(pointInspection?.photos, (pointInspection as any)?.originalPhotos, deletedPhotos);
            const remainingPhotos = this.getRemainingPhotos(pointInspection?.photos, deletedPhotos, newPhotos);

            pointInspectionPhotos = remainingPhotos;

            pointInspection$.pipe(tap(async () => {
                // Route pour ajout de photo et pour supression de photo non sync
                const route = `/inspections/${pointInspection.id}/photos`;

                for (const deletedPhoto of deletedPhotos) {
                    if (this.offlineModeService.isOfflineMode) {
                        if (deletedPhoto.nom) {
                            const requestToAdd = {
                                type: RequestType.DELETE,
                                route: `${route}/${deletedPhoto.nom}`,
                                piId: pointInspection.id,
                                pi: pointInspection,
                                typeOriginal: TypeObjetSync.PHOTO,
                                parentTypePhoto: TypeObjetSync.POINT_INSPECTION,
                                parentTypePhotoId: pointInspection.id,
                                original: deletedPhoto,
                                originalId: deletedPhoto.nom
                            };
                            this.addRequestToPile(requestToAdd).subscribe();
                        } else {
                            // On recherche la photo dans la pile et on la supprime
                            // On prend le path de l'ajout de la photo, car si elle n'a pas de nom c'est qu'elle n'a jamais
                            // été synchronisée, donc elle est encore dans un post.
                            this.searchAndDeletePhotoInStack(deletedPhoto, route).subscribe();
                        }
                    } else {
                        await lastValueFrom(this.apiService.delete(`${route}/${deletedPhoto.nom}`));
                    }
                }

                for (const newPhoto of newPhotos as PhotoBackend[]) {
                    if (this.offlineModeService.isOfflineMode) {
                        const requestToAdd = {
                            type: RequestType.POST,
                            route: route,
                            body: { photo: newPhoto.photo, nomOriginal: newPhoto.nomOriginal },
                            piId: pointInspection.id,
                            pi: pointInspection,
                            typeOriginal: TypeObjetSync.PHOTO,
                            parentTypePhoto: TypeObjetSync.POINT_INSPECTION,
                            parentTypePhotoId: pointInspection.id,
                            original: { photo: newPhoto.photo, nomOriginal: newPhoto.nomOriginal },
                            originalId: newPhoto.nom,
                        };
                        this.addRequestToPile(requestToAdd).subscribe();

                        pointInspectionPhotos.push({
                            id: newPhoto.id,
                            nom: newPhoto.nom,
                            nomOriginal: newPhoto.nomOriginal,
                            photo: newPhoto.photo,
                        });
                    } else {
                        const photoName = await lastValueFrom(this.apiService.post(route,
                            transformToFormData({ photo: (newPhoto as PhotoBackend).photo, nomOriginal: newPhoto.nomOriginal })));

                        pointInspectionPhotos.push({
                            id: newPhoto.id,
                            nom: photoName as string,
                            nomOriginal: newPhoto.nomOriginal,
                            photo: newPhoto.photo,
                        });
                    }
                }
                setTimeout(() => {
                    isPointInspectionCompleted$.next();
                });
            })).subscribe();

            pointInspection$.next();

            return isPointInspectionCompleted$.pipe(
                switchMap(() => {
                    delete pointInspection?.photos;

                    const updatedPointInspection = {
                        ...pointInspection,
                        photos: pointInspectionPhotos,
                        originalPhotos: undefined
                    } as PointInspection;

                    return this.getLocalProject$().pipe(
                        switchMap(projet => {
                            const index = projet.pointInspections!.findIndex(pi => pi.id === updatedPointInspection.id);
                            projet.pointInspections![index] = updatedPointInspection;
                            return this.updateLocalProject(projet);
                        }),
                        map(() => {
                            return updatedPointInspection;
                        })
                    );
                })
            );
        }),
        mergeMap(pointInspection => this.filterDeletedAnomaliesPointInspection(pointInspection)),
        map((updatedInfo) => {
            const updatePointInspection: Update<PointInspection> = {
                id: updatedInfo.id as string,
                changes: updatedInfo
            };
            this.messageService.add({
                severity: Severite.success,
                closable: true,
                summary: `Mise à jour de détails poteau`,
                detail: `Les informations du poteau ont bien été modifiées`
            });
            return updatePointInspectionSuccess({ pointInspection: updatePointInspection });
        })
    ));

    public patchPointInspection = createEffect(() => this.actions$.pipe(
        ofType(PointInspectionActionTypes.PATCH_POINT_INSPECTION),
        mergeMap(({ pointInspectionId, pointInspectionUpdate, patch, pointInspectionOriginal, isDeplacerPoteau }) => {
            const patchPath = isDeplacerPoteau ? `/inspections/${pointInspectionId}?action=deplacement` : `/inspections/${pointInspectionId}`;
            if (this.offlineModeService.isOfflineMode) {
                const requestToAdd = {
                    type: RequestType.PATCH,
                    route: patchPath,
                    body: patch,
                    piId: (pointInspectionOriginal as PointInspection).id,
                    pi: pointInspectionOriginal as PointInspection,
                    typeOriginal: TypeObjetSync.POINT_INSPECTION,
                    original: pointInspectionOriginal,
                    originalId: pointInspectionId,
                };

                this.addRequestToPile(
                    requestToAdd,
                    this.mergeTogether(pointInspectionOriginal as Partial<PointInspection>, pointInspectionUpdate as Partial<PointInspection>, ['geometrie'])
                ).subscribe();

                return this.getLocalProject$().pipe(
                    map(updatedProjet => {
                        const projetPointInspections = updatedProjet?.pointInspections || [];
                        const pointInspection = projetPointInspections.find(pi => pi.id === pointInspectionId);

                        if (pointInspection && pointInspectionUpdate) {
                            const pi = pointInspectionUpdate as PointInspection;
                            if (pi.statut) {
                                pointInspection.statut = pi.statut;
                            }
                        }

                        const updatedPointInspection: PointInspection = mergeDeep(pointInspection, pointInspectionUpdate);

                        updatedProjet.pointInspections = updatedProjet.pointInspections?.map(pi => {
                            if (pi.id === pointInspectionId) {
                                return updatedPointInspection;
                            }
                            return pi;
                        });

                        this.updateLocalProject(updatedProjet).subscribe();

                        return updatedPointInspection;
                    })
                );
            } else if (!navigator.onLine) {
                return of(null);
            } else {
                return this.apiService.patch<PointInspection, Partial<PointInspection>>(patchPath, patch).pipe(
                    switchMap(updatedPointInspection => {
                        return this.getLocalProject$().pipe(
                            switchMap(projet => {
                                const index = projet.pointInspections!.findIndex(pointInspection => pointInspection.id === pointInspectionId);
                                projet.pointInspections![index] = updatedPointInspection;
                                return this.updateLocalProject(projet).pipe(
                                    map((p) => p[0].pointInspections as PointInspection[]),
                                    map((pointInspection) => pointInspection[index])
                                );
                            })
                        );
                    })
                );
            }
        }),
        mergeMap(pointInspection => this.filterDeletedAnomaliesPointInspection(pointInspection)),
        map((pointInspection) => {
            const updatedPointInspection: Update<PointInspection> = { id: pointInspection!.id as string, changes: pointInspection! };
            return patchPointInspectionSuccess({ pointInspection: updatedPointInspection });
        })
    ));

    public getPointInspectionProjetActif = createEffect(() => this.actions$.pipe(
        ofType(PointInspectionActionTypes.GET_POINT_INSPECTION_PROJET_ACTIF),
        mergeMap(({ projetId }) => {
            if (this.offlineModeService.isOfflineMode) {
                return this.getLocalProject$();
            } else if (!navigator.onLine || !this.utilisateurService.isOneOfRoles(
                [UserRole.INSPECTEUR_HQD, UserRole.INSPECTEUR_EXTERNE, UserRole.CONTROLEUR_QUALITE_EXTERNE]
            )) {
                return of(null);
            } else {
                return this.apiService.get<Projet>(`/projets/${projetId}`);
            }
        }),
        mergeMap(projet => this.filterDeletedAnomaliesProjetInspection(projet)),
        map((prj) => {
            if (!prj) {
                return forbiddenUpdatePointInspection();
            }
            return getPointsInspectionBboxSuccess({ pointsInspection: prj.pointInspections as PointInspection[] });
        })
    ));

    public getPointsInspectionByIdProjet = createEffect(() => this.actions$.pipe(
        ofType(PointInspectionActionTypes.GET_POINTS_INSPECTIONS_ID_PROJET),
        mergeMap(({ projetId }) => {
            if (this.offlineModeService.isOfflineMode) {
                return this.getLocalProject$();
            } else if (navigator.onLine && !this.utilisateurService.isOneOfRoles([
                UserRole.INSPECTEUR_HQD,
                UserRole.INSPECTEUR_EXTERNE,
                UserRole.CONTROLEUR_QUALITE_EXTERNE]
            )) {
                return this.apiService.get<Projet>(`/projets/${projetId}`);
            } else {
                return of(null);
            }
        }),
        mergeMap(projet => this.filterDeletedAnomaliesProjetInspection(projet)),
        map((prj) => {
            if (!prj) {
                return forbiddenUpdatePointInspection();
            }
            return getPointsInspectionBboxSuccess({ pointsInspection: prj.pointInspections as PointInspection[] });
        })
    ));

    public setPointInspectionSansAnomalie = createEffect(() => this.actions$.pipe(
        ofType(PointInspectionActionTypes.SET_POINT_INSPECTION_SANS_ANOMALIE),
        mergeMap(({ pointInspectionId, pointInspectionUpdate, patch, pointInspectionOriginal }) => {
            if (this.offlineModeService.isOfflineMode) {
                const requestToAdd = {
                    type: RequestType.PATCH,
                    route: `/inspections/${pointInspectionId}`,
                    body: patch,
                    piId: pointInspectionId,
                    pi: pointInspectionOriginal,
                    typeOriginal: TypeObjetSync.POINT_INSPECTION,
                    original: pointInspectionOriginal,
                    originalId: pointInspectionId,
                };

                this.addRequestToPile(
                    requestToAdd,
                    this.mergeTogether(pointInspectionOriginal as Partial<PointInspection>, pointInspectionUpdate as Partial<PointInspection>, ['geometrie'])
                ).subscribe();

                return this.getLocalProject$().pipe(
                    map(updatedProjet => {
                        const projetPointInspections = updatedProjet?.pointInspections || [];
                        const pointInspection = projetPointInspections.find(pi => pi.id === pointInspectionId) as PointInspection;
                        pointInspection.statut = StatutPointInspection.sansAnomalie;

                        const updatedPointInspection = {
                            ...pointInspection,
                            statut: StatutPointInspection.sansAnomalie,
                        };

                        updatedProjet.pointInspections = updatedProjet.pointInspections?.map(pi => {
                            if (pi.id === pointInspectionId) {
                                return updatedPointInspection;
                            }
                            return pi;
                        });

                        this.updateLocalProject(updatedProjet).subscribe();

                        return updatedPointInspection;
                    }));
            }
            return this.apiService.patch<PointInspection, changeInspectionStatut>(`/inspections/${pointInspectionId}`, patch).pipe(
                switchMap(updatedPointInspection => {
                    return this.getLocalProject$().pipe(
                        switchMap(projet => {
                            const index = projet.pointInspections!.findIndex(pointInspection => pointInspection.id === pointInspectionId);
                            projet.pointInspections![index] = updatedPointInspection;
                            return this.updateLocalProject(projet).pipe(
                                map((p) => p[0].pointInspections as PointInspection[]),
                                map((pointInspection) => pointInspection[index])
                            );
                        })
                    );
                })
            );
        }),
        mergeMap(pointInspection => this.filterDeletedAnomaliesPointInspection(pointInspection)),
        map((pointInspection) => {
            const usedPointInspection = pointInspection as Partial<PointInspection>;
            const updatedPointInspection: Update<PointInspection> = { id: usedPointInspection!.id as string, changes: usedPointInspection! };
            return setPointInspectionSansAnomalieSuccess({ pointInspection: updatedPointInspection });
        })
    ));

    public createAnomalie$ = createEffect(() => this.actions$.pipe(
        ofType(PointInspectionActionTypes.CREATE_ANOMALIE),
        mergeMap((
            action: {
                type: string,
                anomalie: Anomalie,
                inspectionId: string,
                projetId: string,
                originalPhotos?: PhotoBackend[]
            }
        ) => {
            const { photos, ...anomalieSansPhoto } = action.anomalie;
            if (this.offlineModeService.isOfflineMode) {
                if (!anomalieSansPhoto.id) {
                    anomalieSansPhoto.id = generateUuid();
                    anomalieSansPhoto.isTempId = true;
                }

                const requestToAdd = {
                    type: RequestType.PUT,
                    route: `/projets/${action.projetId}/inspection/${action.inspectionId}/anomalies`,
                    body: anomalieSansPhoto,
                    original: action.anomalie,
                    piId: action.inspectionId,
                    typeOriginal: TypeObjetSync.ANOMALIE,
                    originalId: action.anomalie.id,
                };

                this.addRequestToPile(requestToAdd).subscribe();

                return this.anomaliePilotageStore.pipe(select(getAllAnomaliesPilotageState)).pipe(
                    map(anomaliePilotage => {
                        anomalieSansPhoto.dateTransfertSap = 0 as any;
                        const priorite = this.anomaliePilotageService.getAnomaliePriorite(anomalieSansPhoto, anomaliePilotage);
                        return {
                            ...anomalieSansPhoto,
                            priorite,
                            inspectionId: action.inspectionId,
                            photos,
                            originalPhotos: action.originalPhotos,
                        };
                    }
                    ));
            }
            return this.apiService.put<Anomalie, Anomalie>(`/projets/${action.projetId}/inspection/${action.inspectionId}/anomalies`, anomalieSansPhoto).pipe(
                map(createdAnomalie => {
                    const a = {
                        ...createdAnomalie,
                        inspectionId: action.inspectionId,
                        photos,
                        originalPhotos: action.originalPhotos
                    };
                    return a;
                })
            );
        }),
        mergeMap((anomalie) => {
            let anomaliePhotos: PhotoBackend[] = [];
            const isAnomalieCompleted$ = new Subject<void>();
            const anomalie$ = new Subject<void>();

            const deletedPhotos = this.getDeletedPhotos(anomalie.photos, anomalie.originalPhotos);
            const newPhotos = this.getNewPhotos(anomalie.photos, anomalie.originalPhotos, deletedPhotos);
            const remainingPhotos = this.getRemainingPhotos(anomalie.photos, deletedPhotos, newPhotos);

            anomaliePhotos = remainingPhotos;

            anomalie$.pipe(tap(async () => {
                const route = `/anomalies/${anomalie.id}/photos`;

                for (const deletedPhoto of deletedPhotos) {
                    if (this.offlineModeService.isOfflineMode) {
                        if (deletedPhoto.nom) {
                            const requestToAdd = {
                                type: RequestType.DELETE,
                                route: `${route}/${deletedPhoto.nom}`,
                                piId: anomalie.inspectionId,
                                pi: anomalie.pointInspection,
                                typeOriginal: TypeObjetSync.PHOTO,
                                parentTypePhoto: TypeObjetSync.ANOMALIE,
                                parentTypePhotoId: anomalie.id,
                            };
                            this.addRequestToPile(requestToAdd).subscribe();
                        } else {
                            // On recherche la photo dans la pile et on la supprime
                            // On prend le path de l'ajout de la photo, car si elle n'a pas de nom c'est qu'elle n'a jamais
                            // été synchronisée, donc elle est encore dans un post.
                            this.searchAndDeletePhotoInStack(deletedPhoto, route).subscribe();
                        }
                    } else {
                        await lastValueFrom(this.apiService.delete(`${route}/${deletedPhoto.nom}`));
                    }
                }

                for (const newPhoto of newPhotos as PhotoBackend[]) {
                    let photoName: string | undefined = undefined;
                    if (this.offlineModeService.isOfflineMode) {
                        const requestToAdd = {
                            type: RequestType.POST,
                            route: route,
                            body: { photo: newPhoto.photo, nomOriginal: newPhoto.nomOriginal },
                            piId: anomalie.inspectionId,
                            pi: anomalie.pointInspection,
                            typeOriginal: TypeObjetSync.PHOTO,
                            parentTypePhoto: TypeObjetSync.ANOMALIE,
                            parentTypePhotoId: anomalie.id,
                            original: { photo: newPhoto.photo, nomOriginal: newPhoto.nomOriginal },
                            originalId: newPhoto.nom,
                        };
                        this.addRequestToPile(requestToAdd).subscribe();
                    } else {
                        photoName = await lastValueFrom(this.apiService.post(route,
                            transformToFormData({ photo: newPhoto.photo, nomOriginal: newPhoto.nomOriginal }))) as string;
                    }

                    anomaliePhotos.push({
                        id: newPhoto.id,
                        nom: newPhoto.nom || photoName,
                        nomOriginal: newPhoto.nomOriginal,
                        photo: newPhoto.photo,
                    });
                }
                setTimeout(() => {
                    isAnomalieCompleted$.next();
                });
            })).subscribe();

            anomalie$.next();

            return isAnomalieCompleted$.pipe(
                switchMap(() => {
                    delete anomalie.originalPhotos;

                    const updatedAnomalie = {
                        ...anomalie,
                        photos: anomaliePhotos
                    } as Anomalie;

                    return this.getLocalProject$().pipe(
                        switchMap((projet) => {
                            const currentPointInspection = projet.pointInspections?.find(pi => pi.id === updatedAnomalie.inspectionId);
                            let anomalies = currentPointInspection?.anomalies || [];
                            if (anomalies.find(a => a.id === updatedAnomalie.id)) {
                                anomalies = anomalies.map(a => {
                                    if (a.id === updatedAnomalie.id) {
                                        return {
                                            ...updatedAnomalie
                                        };
                                    }
                                    return { ...a };
                                });
                            } else {
                                anomalies.push(updatedAnomalie);
                            }

                            currentPointInspection!.anomalies = anomalies;
                            projet.pointInspections = projet.pointInspections?.map(pi => {
                                if (pi.id === currentPointInspection?.id) {
                                    return {
                                        ...currentPointInspection
                                    };
                                }
                                return {
                                    ...pi
                                };
                            });

                            // Update du statut du point d'inspection dans le projet téléchargé
                            if (currentPointInspection && projet.pointInspections) {
                                const indexPi = projet.pointInspections.findIndex(pointInspection => pointInspection.id === updatedAnomalie.inspectionId);
                                const currentAnomalies = currentPointInspection.anomalies || [];
                                const hasUrgence = currentAnomalies.find(a => a.urgence);
                                let statutPriorite: number = min(currentAnomalies.map((a: Anomalie) =>
                                    StatutPointInspectionNumberValue[a.priorite]));
                                if (!statutPriorite) {
                                    statutPriorite = 11;
                                }
                                const newStatut = hasUrgence ? StatutPointInspection.urgence : StatutPointInspectionNumberValue[statutPriorite];
                                projet.pointInspections[indexPi].statut = newStatut;

                                if (!projet.pointInspections[indexPi].inspecteLe || projet.pointInspections[indexPi].inspecteLe === '') {
                                    projet.pointInspections[indexPi].inspecteLe = String(new Date().getTime());
                                }
                            }

                            return this.updateLocalProject(projet);
                        }),
                        map(() => {
                            return updatedAnomalie;
                        })
                    );
                })
            );
        }),
        map((anomalie) => {
            this.messageService.add(
                {
                    severity: Severite.success,
                    closable: true,
                    summary: `Création d'anomalie`,
                    data: anomalie,
                    key: 'messageAnomalie'
                });
            return createAnomalieSuccess({ anomalie });
        }),
    ));

    public deleteAnomalie$ = createEffect(() => this.actions$.pipe(
        ofType(PointInspectionActionTypes.DELETE_ANOMALIE),
        // eslint-disable-next-line rxjs/no-unsafe-switchmap
        switchMap((action: { anomalie: Anomalie }) => {
            return this.getLocalProject$().pipe(
                switchMap(projet => {
                    if (this.offlineModeService.isOfflineMode) {
                        // Rechercher dans la pile requete pour trouver les photos qui sont associé a cette anomalie
                        // Si les photos ne sont pas sync, on les retire de la pile (que ce soit un type DELETE ou POST)
                        return this.dbService.getAll<StackedRequete>(StoreName.PILE_REQUETE).pipe(
                            switchMap(async (requests: StackedRequete[]) => {
                                if (action.anomalie.id) {
                                    const requestIdToDelete: string[] = [];
                                    requests.forEach((request: StackedRequete) => {
                                        if (request.route.includes(`/anomalies/${action.anomalie.id!}/photos`)) {
                                            requestIdToDelete.push(request.id);
                                        }
                                    });

                                    if (requestIdToDelete.length > 0) {
                                        let remainingFinalRequest;
                                        requestIdToDelete.forEach(async id => {
                                            remainingFinalRequest = this.dbService.delete<StackedRequete>(StoreName.PILE_REQUETE, id).subscribe();
                                        });

                                        remainingFinalRequest;
                                    }
                                }
                            }),
                            switchMap((_: any) => {
                                const requestToAdd = {
                                    type: RequestType.DELETE,
                                    route: `/anomalies/${action.anomalie.id}`,
                                    piId: action.anomalie.inspectionId,
                                    pi: action.anomalie,
                                    typeOriginal: TypeObjetSync.ANOMALIE,
                                    originalId: action.anomalie.id,
                                    original: action.anomalie,
                                };
                                return this.addRequestToPile(requestToAdd).pipe(
                                    // return this.dbService.add(StoreName.PILE_REQUETE, requestToAdd).pipe(
                                    switchMap(() => {
                                        const currentPointInspection = projet.pointInspections?.find(pi => pi.id === action.anomalie.inspectionId);

                                        // Update du statut du point d'inspection dans le projet téléchargé
                                        if (currentPointInspection && projet.pointInspections) {
                                            const indexPi = projet.pointInspections.findIndex(pointInspection => pointInspection.id === action.anomalie.inspectionId);

                                            const anomalies = currentPointInspection.anomalies || [];
                                            const index = anomalies.findIndex(anomalie => anomalie.id === action.anomalie.id) as number;

                                            anomalies.splice(index, 1);
                                            let newStatut: string;
                                            if (anomalies.length) {
                                                const hasUrgence = anomalies.find(a => a.urgence);
                                                const statutPriorite: number = min(anomalies.map(a =>
                                                    StatutPointInspectionNumberValue[a.priorite]));
                                                newStatut = hasUrgence ? StatutPointInspection.urgence : StatutPointInspectionNumberValue[statutPriorite];
                                            } else {
                                                newStatut = StatutPointInspection.nonInspecte;
                                            }
                                            projet.pointInspections[indexPi].statut = newStatut;
                                        }
                                        return this.updateLocalProject(projet);
                                    }),
                                );
                            }),
                        );
                    }
                    return this.apiService.delete<Anomalie>(`/anomalies/${action.anomalie.id}`).pipe(
                        switchMap(() => {
                            const currentPointInspection = projet.pointInspections?.find(pi => pi.id === action.anomalie.inspectionId);

                            // Update du statut du point d'inspection dans le projet téléchargé
                            if (currentPointInspection && projet.pointInspections) {
                                const indexPi = projet.pointInspections.findIndex(pointInspection => pointInspection.id === action.anomalie.inspectionId);
                                const anomalies = currentPointInspection.anomalies || [];
                                const index = anomalies.findIndex(anomalie => anomalie.id === action.anomalie.id) as number;

                                anomalies.splice(index, 1);
                                let newStatut: string;
                                if (anomalies.length) {
                                    const hasUrgence = anomalies.find(a => a.urgence);
                                    const statutPriorite: number = min(anomalies.map(a =>
                                        StatutPointInspectionNumberValue[a.priorite]));
                                    newStatut = hasUrgence ? StatutPointInspection.urgence : StatutPointInspectionNumberValue[statutPriorite];
                                } else {
                                    newStatut = StatutPointInspection.nonInspecte;
                                }
                                projet.pointInspections[indexPi].statut = newStatut;
                            }
                            return this.updateLocalProject(projet);
                        })
                    );
                }),
                map(projets => projets[0]),
                mergeMap(projet => this.filterDeletedAnomaliesProjetInspection(projet)),
                mergeMap(projet => {
                    this.messageService.add({
                        severity: Severite.success,
                        closable: true,
                        summary: `Suppression d'anomalie`,
                        detail: `L'anomalie a bien été supprimée`
                    });

                    const currentPointInspection = projet.pointInspections?.find(pi => pi.id === action.anomalie.inspectionId);
                    const arrayToReturn: any[] = [
                        deleteAnomalieSuccess({ pointInspectionId: action.anomalie.inspectionId as string, anomalieId: action.anomalie.id as string })
                    ];

                    if (currentPointInspection) {
                        const updatePointInspection: Update<PointInspection> = {
                            id: currentPointInspection.id as string,
                            changes: currentPointInspection
                        };
                        arrayToReturn.push(updatePointInspectionSuccess({ pointInspection: updatePointInspection }));
                    }

                    return arrayToReturn;
                })
            );
        })
    ));

    public updateAnomalie$ = createEffect(() => this.actions$.pipe(
        ofType(PointInspectionActionTypes.UPDATE_ANOMALIE),
        mergeMap((
            action: {
                type: string,
                anomalie: Anomalie,
                inspectionId: string,
                projetId: string,
                originalPhotos?: PhotoBackend[]
            }
        ) => {
            const { photos, ...anomalieSansPhoto } = action.anomalie;

            return this.apiService.put<Anomalie, Anomalie>(`/projets/${action.projetId}/inspection/${action.inspectionId}/anomalies`, anomalieSansPhoto).pipe(
                map(createdAnomalie => {
                    const a = {
                        ...createdAnomalie,
                        inspectionId: action.inspectionId,
                        photos,
                        originalPhotos: action.originalPhotos
                    };
                    return a;
                })
            );
        }),
        mergeMap((anomalie) => {
            let anomaliePhotos: PhotoBackend[] = [];
            const isAnomalieCompleted$ = new Subject<void>();
            const anomalie$ = new Subject<void>();

            const deletedPhotos = this.getDeletedPhotos(anomalie.photos, anomalie.originalPhotos);
            const newPhotos = this.getNewPhotos(anomalie.photos, anomalie.originalPhotos, deletedPhotos);
            const remainingPhotos = this.getRemainingPhotos(anomalie.photos, deletedPhotos, newPhotos);

            anomaliePhotos = remainingPhotos;

            anomalie$.pipe(tap(async () => {
                const route = `/anomalies/${anomalie.id}/photos`;

                for (const deletedPhoto of deletedPhotos) {
                    if (this.offlineModeService.isOfflineMode) {
                        if (deletedPhoto.nom) {
                            const requestToAdd = {
                                type: RequestType.DELETE,
                                route: `${route}/${deletedPhoto.nom}`,
                                piId: anomalie.inspectionId,
                                pi: anomalie.pointInspection,
                                typeOriginal: TypeObjetSync.PHOTO,
                                parentTypePhoto: TypeObjetSync.ANOMALIE,
                                parentTypePhotoId: anomalie.id,
                            };
                            this.addRequestToPile(requestToAdd).subscribe();
                        } else {
                            // On recherche la photo dans la pile et on la supprime
                            // On prend le path de l'ajout de la photo, car si elle n'a pas de nom c'est qu'elle n'a jamais
                            // été synchronisée, donc elle est encore dans un post.
                            this.searchAndDeletePhotoInStack(deletedPhoto, route).subscribe();
                        }
                    } else {
                        await lastValueFrom(this.apiService.delete(`${route}/${deletedPhoto.nom}`));
                    }
                }

                for (const newPhoto of newPhotos as PhotoBackend[]) {
                    let photoName: string | undefined = undefined;
                    if (this.offlineModeService.isOfflineMode) {
                        const requestToAdd = {
                            type: RequestType.POST,
                            route: route,
                            body: { photo: newPhoto.photo, nomOriginal: newPhoto.nomOriginal },
                            piId: anomalie.inspectionId,
                            pi: anomalie.pointInspection,
                            typeOriginal: TypeObjetSync.PHOTO,
                            parentTypePhoto: TypeObjetSync.ANOMALIE,
                            parentTypePhotoId: anomalie.id,
                            original: { photo: newPhoto.photo, nomOriginal: newPhoto.nomOriginal },
                            originalId: newPhoto.nom,
                        };
                        this.addRequestToPile(requestToAdd).subscribe();
                    } else {
                        photoName = await lastValueFrom(this.apiService.post(route,
                            transformToFormData({ photo: newPhoto.photo, nomOriginal: newPhoto.nomOriginal }))) as string;
                    }

                    anomaliePhotos.push({
                        id: newPhoto.id,
                        nom: newPhoto.nom || photoName,
                        nomOriginal: newPhoto.nomOriginal,
                        photo: newPhoto.photo,
                    });
                }
                setTimeout(() => {
                    isAnomalieCompleted$.next();
                });
            })).subscribe();

            anomalie$.next();

            return isAnomalieCompleted$.pipe(
                switchMap(() => {
                    delete anomalie.originalPhotos;

                    const updatedAnomalie = {
                        ...anomalie,
                        photos: anomaliePhotos
                    } as Anomalie;

                    return this.getLocalProject$().pipe(
                        map(() => {
                            return updatedAnomalie;
                        })
                    );
                })
            );
        }),
        mergeMap(anomalie => {
            this.messageService.add({
                severity: Severite.success,
                closable: true,
                summary: `Modification d'anomalie`,
                detail: `L'anomalie a bien été modifiée`
            });
            return [
                updateAnomalieSuccess({ anomalie }),
                //updateAnomalieListSuccess({ anomalie })
            ];
        }),
    ));

    public createPointInspection$ = createEffect(() => this.actions$.pipe(
        ofType(PointInspectionActionTypes.CREATE_POINT_INSPECTION),
        // eslint-disable-next-line rxjs/no-unsafe-switchmap
        switchMap((action: { pointInspection: CreatePointInspection }) => {
            const { photos, ...pointInspectionSansPhoto } = action.pointInspection;
            return this.getLocalProject$().pipe(
                switchMap(projet => {
                    if (this.offlineModeService.isOfflineMode) {
                        const requeteToAdd = {
                            type: RequestType.POST,
                            route: `/projets/${projet.id}/inspections`,
                            body: pointInspectionSansPhoto,
                            piId: pointInspectionSansPhoto.id,
                            pi: action.pointInspection,
                            typeOriginal: TypeObjetSync.POINT_INSPECTION,
                            original: pointInspectionSansPhoto,
                            originalId: pointInspectionSansPhoto.id,
                        };

                        this.addRequestToPile(requeteToAdd).subscribe();
                        const updatedPointInspection: CreatePointInspection = {
                            ...pointInspectionSansPhoto,
                            photos,
                            statut: pointInspectionSansPhoto.statut === StatutPointInspection.sansAnomalie ?
                                StatutPointInspection.sansAnomalie :
                                StatutPointInspection.nonInspecte,
                            projetId: projet.id
                        };

                        const parsedGeometrie = cloneDeep(JSON.parse(updatedPointInspection.poteau.geometrie as string));
                        updatedPointInspection.geometrie = parsedGeometrie;
                        updatedPointInspection.poteau = {
                            ...updatedPointInspection.poteau,
                            geometrie: parsedGeometrie
                        };

                        return of(updatedPointInspection);
                    }
                    return this.apiService.post<PointInspection, CreatePointInspection>(`/projets/${projet.id}/inspections`, pointInspectionSansPhoto).pipe(
                        map(createdPointInspection => {
                            const pi: PointInspection = {
                                ...createdPointInspection,
                                photos
                            };
                            return pi;
                        }),
                        catchError(() => {
                            return of(null);
                        })
                    );
                })
            );
        }),
        // eslint-disable-next-line rxjs/no-unsafe-switchmap
        switchMap((pointInspection: any) => {
            pointInspection = pointInspection as CreatePointInspection;
            if (!pointInspection) {
                return of(null);
            }
            const isPointInspectionCompleted$ = new Subject<void>();
            const pointInspectionPhotos: PhotoBackend[] = [];
            const pointInspection$ = new Subject<void>();
            const photos = pointInspection?.photos;

            pointInspection$.pipe(tap(async () => {
                for (const photo of photos as PhotoBackend[]) {
                    let photoName: string;
                    if (this.offlineModeService.isOfflineMode) {
                        photoName = photo.nom || photo.nomOriginal;
                        const requestToAdd = {
                            type: RequestType.POST,
                            route: `/inspections/${pointInspection?.id}/photos`,
                            body: { photo: photo.photo, nomOriginal: photo.nomOriginal },
                            piId: pointInspection?.id,
                            pi: pointInspection,
                            typeOriginal: TypeObjetSync.PHOTO,
                            parentTypePhoto: TypeObjetSync.POINT_INSPECTION,
                            parentTypePhotoId: pointInspection.id,
                            original: { photo: photo.photo, nomOriginal: photo.nomOriginal },
                            originalId: photo.nom,
                        };

                        this.addRequestToPile(requestToAdd).subscribe();

                    } else {
                        photoName = await lastValueFrom(this.apiService.post(`/inspections/${pointInspection?.id}/photos`,
                            transformToFormData({ photo: photo.photo, nomOriginal: photo.nomOriginal }))) as string;
                    }
                    pointInspectionPhotos.push({
                        id: photo.id,
                        nom: photoName,
                        nomOriginal: photo.nomOriginal,
                        photo: photo.photo,
                    });
                }
                setTimeout(() => {
                    isPointInspectionCompleted$.next();
                });
            })).subscribe();

            pointInspection$.next();

            return isPointInspectionCompleted$.pipe(
                switchMap(() => {
                    delete pointInspection?.photos;

                    const updatedPointInspection = {
                        ...pointInspection,
                        photos: pointInspectionPhotos
                    } as PointInspection;

                    return this.getLocalProject$().pipe(
                        switchMap(projet => {
                            const pointsInspection = projet.pointInspections || [];
                            pointsInspection.push(updatedPointInspection);

                            return this.updateLocalProject(projet);
                        }),
                        map(() => {
                            return updatedPointInspection;
                        })
                    );
                })
            );
        }),
        mergeMap(pointInspection => this.filterDeletedAnomaliesPointInspection(pointInspection)),
        map((pi) => {
            if (!pi) {
                this.messageService.add(
                    {
                        severity: Severite.erreur,
                        closable: true,
                        summary: `Erreur`,
                        detail: `Une erreur est survenue durant la création d'un poteau`
                    });
                return forbiddenUpdatePointInspection();
            }
            this.messageService.add(
                {
                    severity: Severite.success,
                    closable: true,
                    summary: `Création de poteau`,
                    detail: `Le poteau a été créé`
                });
            return createPointInspectionSuccess({ pointInspection: pi });
        })
        // )).toPromise();
    ));

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private dbService: IndexedDbService,
        private messageService: MessageService,
        private readonly offlineModeService: OfflineService,
        private anomaliePilotageStore: Store<AnomaliePilotageState>,
        private readonly anomaliePilotageService: AnomaliePilotageService,
        private readonly utilisateurService: UtilisateurService,
        private pointInspectionStore: Store<PointInspectionState>,
        private store: Store<State>,
    ) {

    }

    public getLocalProject$ = () => {
        return this.dbService.getAll<Projet>(StoreName.PROJETS).pipe(
            withLatestFrom(this.store.select(getCurrentActiveProjetInspection)),
            mergeMap(([projets, currentActiveProjetInspection]) =>
                this.filterDeletedAnomaliesProjetInspection(projets.find(projet => projet.id === currentActiveProjetInspection?.id))
            ));

        // if (this.utilisateurService.isAuditeur) {  // TODO PDL-655 REMOVE isAuditeur CONDITION
        //     return this.dbService.getAll<Projet>(StoreName.PROJETS).pipe(
        //         withLatestFrom(this.store.select(getCurrentActiveProjetAudit)),
        //         mergeMap(([projets, currentActiveProjetAudit]) =>
        //             this.filterDeletedAnomaliesProjetInspection(projets.find(projet => projet.id === currentActiveProjetAudit?.projetId))
        //         ));
        // } else {
        //     return this.dbService.getAll<Projet>(StoreName.PROJET_TELECHARGE).pipe(
        //         map((projets) => projets[0]),
        //         mergeMap(projet => this.filterDeletedAnomaliesProjetInspection(projet))
        //     );
        // }
    };

    // Cette fonction détermine si on doit ajouter la requête dans la pile ou si l'on met a jour une requête existante de la pile
    private addRequestToPile(requestToAdd: any, objectToUpdate?: any) {
        return this.dbService.getAll<StackedRequete>(StoreName.PILE_REQUETE).pipe(
            switchMap((requests: StackedRequete[]) => {
                if (requests && requests.length && requests.length > 0) {
                    let shouldBreakupLoop = false;
                    let shouldAddRequest = true;

                    if (requestToAdd.typeOriginal === TypeObjetSync.PHOTO) {
                        shouldAddRequest = true;
                    } else {
                        for (let index = 0; index < requests.length; index++) {
                            const pileRequete = requests[index];

                            // Si c'est du type photo on ne fait aucune modifications aux requêtes existante
                            if (pileRequete.typeOriginal !== TypeObjetSync.PHOTO) {
                                // Pour chaque requête on indique le ID du point d'inspection, donc pour faire des modifications ça doit être
                                //  exactement sur le même point d'inspection
                                if (requestToAdd.piId === pileRequete.pointInspectionId) {
                                    switch (requestToAdd.type) {
                                        case RequestType.DELETE: {
                                            if (pileRequete.typeOriginal === TypeObjetSync.ANOMALIE) {

                                                shouldBreakupLoop = true;
                                                // this.dbService.delete<StackedRequete>(StoreName.PILE_REQUETE, pileRequete.id);
                                                return this.addToPileRequest(requestToAdd);
                                            }
                                        } break;
                                        case RequestType.POST: {
                                            if (pileRequete.typeOriginal === TypeObjetSync.POINT_INSPECTION
                                                && pileRequete.route === requestToAdd.route
                                                && JSON.stringify(requestToAdd.body) !== JSON.stringify(pileRequete.original)) {
                                                pileRequete.body = requestToAdd.body;
                                                shouldBreakupLoop = true;
                                                return this.dbService.update(StoreName.PILE_REQUETE, pileRequete);
                                            }
                                        } break;
                                        case RequestType.PUT: {
                                            if (requestToAdd.typeOriginal === TypeObjetSync.ANOMALIE) {
                                                if (pileRequete.route === requestToAdd.route && pileRequete.originalId === requestToAdd.originalId) {
                                                    if (JSON.stringify(requestToAdd.body) !== JSON.stringify(pileRequete.body)) {
                                                        const newBodyWithoutPhotos = this.removePhotoFromObject(requestToAdd.body);
                                                        const previousBodyWithoutPhotos = this.removePhotoFromObject(pileRequete.body);

                                                        // const mergedPointInspection = mergeDeep({ ...postBodyValuesWithoutPhotos }, { ...newValuesWithoutPhotos });
                                                        const mergedRequest = { ...pileRequete };
                                                        mergedRequest.body = this.mergeTogether(
                                                            { ...previousBodyWithoutPhotos },
                                                            { ...newBodyWithoutPhotos },
                                                            ['geometrie']
                                                        );

                                                        shouldBreakupLoop = true;
                                                        return this.dbService.update(StoreName.PILE_REQUETE, mergedRequest);
                                                    } else {
                                                        shouldAddRequest = false;
                                                    }
                                                }
                                            }
                                        } break;
                                        case RequestType.PATCH: {
                                            if (requestToAdd.typeOriginal === TypeObjetSync.POINT_INSPECTION) {
                                                if (Array.isArray(requestToAdd.body)) {
                                                    for (let i = 0; index < requestToAdd.body.length; index++) {
                                                        const patch = requestToAdd.body[i];
                                                        if (patch.path === '/statut' && (
                                                            patch.value === StatutPointInspection.ignore
                                                            || patch.value === StatutPointInspection.sansAnomalie
                                                        )) {
                                                            return this.addToPileRequest(requestToAdd);
                                                        }
                                                    }
                                                }
                                                // Update un point d'inspection qui a été créé mais non sync
                                                if (pileRequete.type === RequestType.POST) {
                                                    if (objectToUpdate) {
                                                        const newValuesWithoutPhotos = this.removePhotoFromObject(objectToUpdate);
                                                        const postBodyValuesWithoutPhotos = this.removePhotoFromObject(pileRequete.body);

                                                        // On update le statut sur le point d'inspection directement
                                                        const pi = newValuesWithoutPhotos as PointInspection;
                                                        if (pi.statut) {
                                                            (postBodyValuesWithoutPhotos as PointInspection).statut = pi.statut;
                                                        }
                                                        // On fusionne les nouvelles valeurs par dessus les valeurs originales, donc seules les nouvelles valeurs seront
                                                        //  garder dans le cas où les mêmes champs auraient été modifiés.

                                                        // const mergedPointInspection = mergeDeep({ ...postBodyValuesWithoutPhotos }, { ...newValuesWithoutPhotos });
                                                        // eslint-disable-next-line max-len
                                                        const mergedPointInspection = this.mergeTogether({ ...postBodyValuesWithoutPhotos }, { ...newValuesWithoutPhotos }, ['geometrie']);
                                                        const mergedRequest = { ...pileRequete };
                                                        mergedRequest.body = mergedPointInspection;

                                                        shouldBreakupLoop = true;
                                                        return this.dbService.update(StoreName.PILE_REQUETE, mergedRequest);
                                                    }
                                                } else {
                                                    const search = '?action=deplacement';
                                                    if (pileRequete.route.includes(search)) { // Est-ce que la requête de la pile est un déplacement
                                                        // le path de base sans le "?action=deplacement" pour la requête de la pile
                                                        const pileReqRootPath = pileRequete.route.substring(0, pileRequete.route.indexOf(search));

                                                        if (requestToAdd.route.includes(search)) { // Est-ce que la nouvelle requête est un déplacement
                                                            // Le path de base sans le "?action=deplacement" pour la nouvelle requête
                                                            const reqToAddRootPath = requestToAdd.route.substring(0, requestToAdd.route.indexOf(search));

                                                            // Si les 2 path de base sont les mêmes, donc c'est le même object et si les données
                                                            //   sont différentes on update
                                                            if (this.shouldUpdateRequest(pileReqRootPath, pileRequete.body, reqToAddRootPath, requestToAdd.body)) {
                                                                // Update des données qui seront envoyé au backend
                                                                pileRequete.body = this.comparePatch(pileRequete.body, requestToAdd.body);

                                                                shouldBreakupLoop = true;
                                                                // Update de la pile avec les nouvelles données.
                                                                return this.dbService.update(StoreName.PILE_REQUETE, pileRequete);
                                                            }
                                                        } else { // Le nouvelle route n'est pas un déplacement
                                                            // Si les 2 path de base sont les mêmes ont peut dire que c'est le même object donc on doit priorisé les
                                                            //   données du point d'inspection, puis faire le déplacement.
                                                            // Il faut également retirer le "?action=deplacement" de l'url puisqu'il y a un update de données. Les
                                                            //   validations des champs obligatoires pour le point d'inspection sont requises. Les nouvelles coordonnées
                                                            //   de la position du poteau sont rendu seulement de nouvelles propriétés du point d'inspection et sont pris
                                                            //   en charge par le endpoint du backend.
                                                            if (requestToAdd.route === pileReqRootPath) {
                                                                const requeteKeys = this.mergeObjectKeys(pileRequete, requestToAdd);
                                                                requeteKeys.forEach((key: string) => {
                                                                    if (key !== 'body' && ((pileRequete[key] && requestToAdd[key])
                                                                        || (!pileRequete[key] && requestToAdd[key]))) {
                                                                        pileRequete[key] = requestToAdd[key];
                                                                    }
                                                                });
                                                                pileRequete.body = this.comparePatch(requestToAdd.body, pileRequete.body);

                                                                shouldBreakupLoop = true;
                                                                return this.dbService.update(StoreName.PILE_REQUETE, pileRequete);
                                                            }
                                                        }

                                                    } else {// Non ce n'est pas un déplacement
                                                        if (requestToAdd.route.includes(search)) { // Est-ce que la nouvelle requête est-un déplacement
                                                            const reqToAddRootPath = requestToAdd.route.substring(0, requestToAdd.route.indexOf(search));
                                                            if (this.shouldUpdateRequest(pileRequete.route, pileRequete.body, reqToAddRootPath, requestToAdd.body)) {
                                                                pileRequete.body = this.comparePatch(pileRequete.body, requestToAdd.body);

                                                                shouldBreakupLoop = true;
                                                                return this.dbService.update(StoreName.PILE_REQUETE, pileRequete);
                                                            }
                                                        } else { // Aucune des requêtes (de la pile et la nouvelle) ne sont des déplacement
                                                            if (this.shouldUpdateRequest(pileRequete.route, pileRequete.body, requestToAdd.route, requestToAdd.body)) {
                                                                // On met ajour les données qui sont envoyé au backend avec le merge des 2 body
                                                                pileRequete.body = this.comparePatch(pileRequete.body, requestToAdd.body);

                                                                shouldBreakupLoop = true;
                                                                return this.dbService.update(StoreName.PILE_REQUETE, pileRequete);
                                                            }
                                                        }
                                                    }


                                                }

                                            }
                                        } break;
                                    }
                                }
                            }

                            if (shouldBreakupLoop) {
                                break;
                            }
                        }
                    }

                    if (!shouldBreakupLoop && shouldAddRequest) {
                        // Si aucune requetes ne correspond on l'ajoute dans les requetes.
                        return this.addToPileRequest(requestToAdd);
                    }
                    return of(true);
                } else {
                    // Si la pile est vide, on ajoute directement.
                    return this.addToPileRequest(requestToAdd);
                }
            })
        );
    }

    private shouldUpdateRequest(a_route: string, a_body: any, b_route: string, b_body: any) {
        return a_route === b_route && JSON.stringify(a_body) !== JSON.stringify(b_body);
    }

    // On regarde dans la pile de requêtes si la photo existe, si elle existe c'est qu'elle est dans une requête de type post
    // Et comme elle est dans un post, elle n'est pas encore sync, donc on la supprime de la pile pour ne pas envoyer de données pour rien
    private searchAndDeletePhotoInStack(deletedPhoto: PhotoBackend, path: string) {
        return this.dbService.getAll<StackedRequete>(StoreName.PILE_REQUETE).pipe(
            switchMap((requests: StackedRequete[]) => {
                if (requests) {
                    for (let index = 0; index < requests.length; index++) {
                        if (requests[index].route === path && requests[index].body.nomOriginal === deletedPhoto.nomOriginal) {
                            return this.dbService.delete<StackedRequete>(StoreName.PILE_REQUETE, requests[index].id);
                        }
                    }
                }
                return of([]);
            })
        );
    }
    private searchPhotoInArray(searchedPhoto: PhotoBackend, arrayPhotos: PhotoBackend[]) {
        // Comme le but est de savoir si la photo existe déjà, si on trouve on l'ajoute ici
        // Les propriétés à regarder dans l'ordre sont:
        //    id: Il y a un ID seulement une fois que la photo a été sync
        //    nom: Si le nom est différent du nom original, c'est qu'elle a été sync
        //    nomOriginal: le nomOriginal est une valeur obligatoire pour la synchro
        //    photo: La photo est également obligatoire pour la sync
        const foundPhoto: PhotoBackend[] = [];
        for (let index = 0; index < arrayPhotos.length; index++) {
            const aPhoto: PhotoBackend = arrayPhotos[index];

            // On cherche en premier par ID, car s'il y a un ID, c'est que ça a déjà été synchronisé.
            if (aPhoto.id) {
                if (searchedPhoto.id && aPhoto.id === searchedPhoto.id) {
                    foundPhoto.push(aPhoto);
                    break;
                }
            } else {
                if (aPhoto.nom) {
                    // Il est possible que les noms soient identique si la photo n'a pas encore été sync
                    if (aPhoto.nom !== aPhoto.nomOriginal) {
                        if (aPhoto.nom === searchedPhoto.nom) {
                            foundPhoto.push(aPhoto);
                            break;
                        }
                    } else {
                        // Comme le nom et le nomOriginal sont identique, on va comparer la photo au format string
                        if (aPhoto.photo === searchedPhoto.photo) {
                            foundPhoto.push(aPhoto);
                            break;
                        }
                    }
                } else {
                    // Ici on sait que la photo n'a pas encore été sync
                    // On compare les 2 noms originals car les photos pourraient avoir le même nom mais pas la même photo
                    //  ex.: delete d'une photo avec le nom X et ajouter une photo avec le nom X mais c'est une photo différente,
                    if (aPhoto.nomOriginal === searchedPhoto.nomOriginal) {
                        if (aPhoto.photo === searchedPhoto.photo) {
                            foundPhoto.push(aPhoto);
                            break;
                        }
                    }
                }
            }
        }
        // On indique seulement que l'on a trouvé la photo de référence "searchedPhoto" dans la collection "arrayPhotos"
        return foundPhoto.length > 0;
    }

    private getRemainingPhotos(photos: PhotoBackend[] | undefined, deletedPhotos: PhotoBackend[], newPhotos: PhotoBackend[]) {
        const remainingPhoto: PhotoBackend[] = [];
        // On regarde chacune des photos du array "photo"
        // Pour chacune des photos, on regarde si fait parti des photos supprimé "deletedPhoto" ou des nouvelles photos "newPhotos"
        // Si on ne trouve pas la photo c'est qu'elle existait et qu'elle reste associé.
        photos?.forEach((photo: PhotoBackend) => {
            if (!this.searchPhotoInArray(photo, deletedPhotos) && !this.searchPhotoInArray(photo, newPhotos)) {
                remainingPhoto.push(photo);
            }
        });

        // On retourne les photo restantes
        return remainingPhoto;
    }

    private getDeletedPhotos(photos: PhotoBackend[] | undefined, originalPhotos: PhotoBackend[] | undefined) {
        const photosToDelete: PhotoBackend[] = [];
        // On regarde chacune des photo originale.
        // Si on trouve la photo originale dans le array de photos "photos", c'est qu'elle n'a pas été supprimée
        originalPhotos?.forEach((originalPhoto: PhotoBackend) => {
            if (photos && !this.searchPhotoInArray(originalPhoto, photos)) {
                photosToDelete.push(originalPhoto);
            }
        });

        // On retourne les photos à supprimer
        return photosToDelete;
    }

    private getNewPhotos(photos: PhotoBackend[] | undefined, originalPhotos: PhotoBackend[] | undefined, deletedPhotos: PhotoBackend[]) {
        const newPhoto: PhotoBackend[] = [];
        // On regarde chacune des photos du array "photo"
        // Pour chacune des photos, on regarde si elle est dans le array de photo originale "originalPhotos" ou dans le array de photo supprimé "deletedPhotos
        // Si la photo n'est pas trouvé dans les photos originales ou les photos a supprimer, c'est qu'elle est une nouvelle photo
        photos?.forEach((photo: PhotoBackend) => {
            if (!originalPhotos || (originalPhotos && !this.searchPhotoInArray(photo, originalPhotos) && !this.searchPhotoInArray(photo, deletedPhotos))) {
                newPhoto.push(photo);
            }
        });

        // On retourne les nouvelles photos à ajouter.
        return newPhoto;
    }

    // Fonction utile car quans on déconstruit un object, on ne peut pas avoir 2 fois la même constante
    // Comme on n'a pas besoin des photos, on veut juste le reste.
    private removePhotoFromObject(object: any) {
        const { photos, ...autre } = object;
        return autre;
    }

    private addToPileRequest(request: any) {
        return this.dbService.add(StoreName.PILE_REQUETE, request);
    }

    private mergeObjectKeys(previousData: any, nextData: any): string[] {
        const finalKeys = [...Object.keys(previousData)];
        const nextDataKeys = Object.keys(nextData);

        nextDataKeys.forEach((key) => {
            if (!finalKeys.includes(key)) {
                finalKeys.push(key);
            }
        });

        return finalKeys;
    }

    private mergeTogether(previousData: any, nextData: any, propertyToStringify: string[] = []) {
        const final: { [key: string]: any } = {};
        if (previousData !== null && nextData !== null) {
            const finalKeys = this.mergeObjectKeys(previousData, nextData);

            finalKeys.forEach(key => {
                if ((key in nextData) && !(key in previousData)) {
                    final[key] = nextData[key];
                } else if (!(key in nextData) && (key in previousData)) {
                    final[key] = previousData[key];
                } else if ((key in nextData) && (key in previousData)) {
                    // On vérifie 'object' ET null car 'null' est un object
                    if (typeof nextData[key] === 'object' && nextData[key] !== null
                        && typeof previousData[key] === 'object' && previousData[key] !== null) {
                        // Si c'est un des 2 est un array, on conserve le array de la nouvelle valeur
                        if (Array.isArray(nextData[key]) || Array.isArray(previousData[key])) {
                            final[key] = nextData[key];
                        } else {
                            final[key] = this.mergeTogether(previousData[key], nextData[key], propertyToStringify);
                        }
                    } else if (typeof nextData[key] === 'object' && nextData[key] !== null && typeof previousData[key] === 'string') {
                        // Si un des 2 est un array, on conserve le array de la nouvelle valeur
                        if (Array.isArray(nextData[key])) {
                            final[key] = nextData[key];
                        } else {
                            // Si les données sont en string, ex.: Géometrie reçoit ses données du backend en format json.strignify
                            // Comme on veut parse les données, il ne faut pas que ce soit une string vide
                            if (previousData[key] !== '') {
                                final[key] = this.mergeTogether(JSON.parse(previousData[key]), nextData[key], propertyToStringify);
                            } else {
                                // nextData a priorité sur les données précédentes
                                final[key] = nextData[key];
                            }
                        }
                    } else if (typeof previousData[key] === 'object' && previousData[key] !== null && typeof nextData[key] === 'string') {
                        if (nextData[key] !== '') {
                            // Si previousData est un object, donc pour la "key" se sera aussi un objet. Un array est un objet
                            // On pourrait conserver ici la position d'un point d'inpection (longitude, latitude), qui parfois est retourner
                            // du serveur comme un array en string,
                            if (Array.isArray(JSON.parse(nextData[key]))) {
                                // On vérifie seulement nextData, car peu importe, nextData à priorité
                                final[key] = nextData[key];
                            } else {
                                // on parse nextData en JSON car previous est un object, il est non null, donc pour la même clé, ce
                                // doit être un object. Comme c'est 2 objets on appelle "mergeTogether"
                                final[key] = this.mergeTogether(previousData[key], JSON.parse(nextData[key]), propertyToStringify);
                            }
                        } else {
                            // nextData a priorité sur les données précédentes
                            // Donc si nextData[key] est vide, c'est la dernière valeur, elle a priorité
                            final[key] = nextData[key];
                        }
                    } else {
                        // Les deux sont des string, donc la nouvelle donnée prime sur l'ancienne, peu importe si JSON ou non.
                        final[key] = nextData[key];
                    }
                }
            });
            return this.partlyStringifyObject(final, propertyToStringify);
        } else if (previousData === null && nextData !== null) {
            return nextData;
        } else if (previousData !== null && nextData === null) {
            return previousData;
        } else {
            return null;
        }
    }

    // On regarde chacune des propriétés pour savoir si l'on doit les stringifyer ou non selon un liste de clé déterminé
    private partlyStringifyObject(object: any, propertyToStringify: string[] = []) {
        const finalObject: { [key: string]: any } = {};
        const keys = Object.keys(object);

        keys.forEach(key => {
            if (propertyToStringify.includes(key) && typeof object[key] !== 'string') {
                finalObject[key] = object[key] !== undefined && object[key] !== null ? JSON.stringify(object[key]) : object[key];
            } else {
                if (typeof object[key] === 'object' && object[key] !== null) {
                    if (Array.isArray(object[key])) {
                        finalObject[key] = object[key];
                    } else {
                        finalObject[key] = this.partlyStringifyObject(object[key], propertyToStringify);
                    }
                } else {
                    finalObject[key] = object[key];
                }
            }
        });
        return finalObject;
    }

    // On fait la comparaison entre 2 JSON patch
    // un JSONpatch contient un objet {  }
    private comparePatch(originalPatch: JSONPatchObj[], newPatch: JSONPatchObj[]): JSONPatchObj[] {
        const patchedjson = [...originalPatch];
        // Si la comparaison est pareil, on ne change rien
        if (JSON.stringify(originalPatch) !== JSON.stringify(newPatch)) {
            newPatch.forEach((new_pjs: JSONPatchObj) => {
                // On regarde s'il y a une modification sur le même champs
                const foundindex = patchedjson.findIndex((original_pjs: JSONPatchObj) => original_pjs.path === new_pjs.path);
                if (foundindex !== -1) {
                    const originalFound = patchedjson[foundindex];
                    if (originalFound.op === 'add') {
                        originalFound.value = new_pjs.value;
                        patchedjson[foundindex] = originalFound;
                    } else {
                        patchedjson[foundindex] = new_pjs; // Puisque l'on a une nouvelle valeur, l'ancienne n'est plus bonne, on la remplace.
                    }
                } else {
                    patchedjson.push(new_pjs);
                }
            });
        }

        return patchedjson;
    }

    public updateLocalProject = (projet: Projet) => of(projet).pipe(
        switchMap(p => this.filterDeletedAnomaliesProjetInspection(p)),
        switchMap(p => this.dbService.update(StoreName.PROJETS, p))
    );

    private filterDeletedAnomaliesPointInspection(pointInspection: PointInspection) {
        return this.filterDeletedAnomaliesPointsInspection([pointInspection])
            .pipe(map(pointsInspection => pointsInspection[0]));
    }

    private filterDeletedAnomaliesPointsInspection(pointsInspection: PointInspection[]) {
        return this.pointInspectionStore.select(filterDeletedAnomalies(pointsInspection));
    }

    private filterDeletedAnomaliesProjetInspection(projet: Projet) {
        return this.filterDeletedAnomaliesPointsInspection(projet?.pointInspections || [])
            .pipe(map(pointInspections => (!projet ? projet : { ...projet, pointInspections } as Projet)));
    }
}
