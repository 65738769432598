import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../shared/components/abstract-base-component';
import { TableColumn } from '../../../../../shared/models/table-column.model';
import { FinalRecherche, RechercheListe, GroupeFeatures } from '../../../models/recherche-liste.model';
import { FeatureCollection } from 'geojson';
import { RechercheSource } from '../../../models/recherche.enum';
import { featureEach } from '@turf/meta';
import { select, Store } from '@ngrx/store';
import { rechercheZoom } from '../../../state/recherche.actions';
import { Observable, takeUntil, tap } from 'rxjs';
import { getRechercheList, getRechercheZoom } from '../../../state/recherche.selectors';
import { BBox, bbox, } from '@turf/turf';
import { MapLayersSources } from '../../../../../map/models/map-layers-sources.enum';
import { MapService } from '../../../../../map/services/map.service';
import { GeoJSONSource, LngLatBoundsLike, } from 'mapbox-gl';

@Component({
    selector: 'app-recherche-results-ligne',
    templateUrl: './recherche-results-ligne.component.html',
    styleUrls: ['./recherche-results-ligne.component.scss']
})
export class RechercheResultLigneComponent extends BaseComponent implements OnInit {
    public colonnes: TableColumn[] = [];
    public liste: any = { groupeName: [] };
    public premierePage: number = 0;
    public showNoResult = false;
    private _valueHeaderName: string = '';

    public rechercheListe: RechercheListe | null = null;
    public rechercheListe$: Observable<RechercheListe | null> = this.store.select(getRechercheList);

    public rechercheZoom$: Observable<any> = this.store.pipe(
        select(getRechercheZoom),
        tap((value: any) => this.zoomToSelectedLine(value)),
        takeUntil(this.destroyed),
    );

    @Input() public set valueHeaderName(value: string) {
        this._valueHeaderName = value;
        if (value) {
            this.subscribreToRechercheListe();
        }
    }

    public get valueHeaderName(): string {
        return this._valueHeaderName;
    }

    constructor(
        private store: Store,
        private mapService: MapService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.colonnes = [
            { field: 'value', header: this.valueHeaderName },
            { field: 'territoire', header: 'Territoire' },
            { field: 'source', header: 'Source' },
            { field: 'nomProjet', header: 'Projet' },
        ];
        this.rechercheZoom$.subscribe();
    }

    private subscribreToRechercheListe() {
        this.rechercheListe$.pipe(
            takeUntil(this.destroyed)
        ).subscribe((rechercheListe: RechercheListe | null) => {
            if (rechercheListe) {
                this.getGroupes(rechercheListe.sig, RechercheSource.SIG);
            }
        });
    }

    public getGroupes(features: FeatureCollection | undefined, source: string) {
        const listGroupe: GroupeFeatures = { groupeName: [{ value: '', territoire: '', source: '' }] };
        listGroupe.groupeName.splice(0);
        if (features) {
            featureEach(features, (currentFeature: any) => {
                const groupeExistant = listGroupe.groupeName.find(obj => obj.value === currentFeature.properties?.valeur);
                const territoireGroupe = listGroupe.groupeName.find(obj => obj.territoire === currentFeature.properties?.propriete);
                if (!groupeExistant || (groupeExistant && !territoireGroupe)) {
                    listGroupe.groupeName.push({ value: currentFeature.properties!.valeur, territoire: currentFeature.properties!.propriete, source: source });
                }
            });
        }

        listGroupe.groupeName.sort((a: FinalRecherche, b: FinalRecherche) => (a.value && b.value) ? (a.value >= b.value) ? 1 : -1 : 0);
        this.liste = listGroupe;
        this.showNoResult = !(listGroupe.groupeName.length > 0);
    }

    public zoomFeature(event: FinalRecherche) {
        this.store.dispatch(rechercheZoom({ value: event }));
    }

    private zoomToSelectedLine(rechercheList: RechercheListe | null) {
        if (rechercheList && rechercheList.sig) {
            const bounds: BBox = bbox(rechercheList.sig);
            const rechercheFeatures = rechercheList.sig;
            if (rechercheFeatures.features.length > 0) {
                (this.mapService.map.getSource(MapLayersSources.RECHERCHE_LIGNE) as GeoJSONSource).setData(rechercheFeatures);
                this.mapService.map.fitBounds(bounds as LngLatBoundsLike, { padding: 3 });
            }
        }
    }

}
