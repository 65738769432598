export enum RechercheConfig {
    RECHERCHE_CODE_BARRES = 'Code à barres',
    RECHERCHE_LIGNE = 'Ligne',
    RECHERCHE_LCLCL = 'LCLCL',
    RECHERCHE_SQRC = 'SQRC',
}

export enum RechercheBackendValue {
    RECHERCHE_CODE_BARRES = 'cab',
    RECHERCHE_LIGNE = 'ligne',
    RECHERCHE_LCLCL = 'lclcl',
    RECHERCHE_SQRC = 'sqrc',
}

export const rechercheBackendUrlValue = new Map<RechercheConfig, RechercheBackendValue>([
    [RechercheConfig.RECHERCHE_CODE_BARRES, RechercheBackendValue.RECHERCHE_CODE_BARRES],
    [RechercheConfig.RECHERCHE_LIGNE, RechercheBackendValue.RECHERCHE_LIGNE],
    [RechercheConfig.RECHERCHE_LCLCL, RechercheBackendValue.RECHERCHE_LCLCL],
    [RechercheConfig.RECHERCHE_SQRC, RechercheBackendValue.RECHERCHE_SQRC],
]);

export enum RechercheSource {
    CAPTURE = 'Capture',
    SIG = 'SIG',
    LOCAL = 'Local',
    SQRC = 'SQRC',
}
