<ng-container *ngIf="projetInspectionList.length">
    <div *ngIf="projetsDownloadedLoading" class="projet-list-mobile-dialog__loading">
        <app-loading-spinner></app-loading-spinner>
    </div>
    <p-contextMenu #menu [model]="menuItems" triggerEvent="click" [target]="actionsButton"></p-contextMenu>
    <p-table *ngIf="!projetsDownloadedLoading" [value]="projetInspectionList" selectionMode="single"
        [selection]="selectedProjetInspection" (selectionChange)="selectionChanged($event)" dataKey="id"
        (onRowSelect)="onRowSelect(selectedProjetInspection, menu)" (onRowUnselect)="onRowUnselect(menu)"
        responsiveLayout="scroll" styleClass="table-mobile">
        <ng-template pTemplate="header">
            <tr>
                <th scope="col" *ngFor="let col of columns" [style.width]="col.width">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns">
                    <ng-container [ngSwitch]="col.field">
                        <ng-container *ngSwitchCase="'statut'">
                            <span *ngIf="isProjetInspectionActivated(rowData)">
                                <i class="pi pi-check"></i>
                            </span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'downloaded'">
                            <span *ngIf="isProjetInspectionDownloaded(rowData)">
                                <i class="pi pi-check"></i>
                            </span>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{rowData[col.field]}}
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="projet-list-mobile-dialog__info" *ngIf="selectedProjetInspection">
        <div class="info-properties">Projet:
            <div class="info-properties__value">{{selectedProjetInspection.nom}}</div>
        </div>
        <div class="info-properties">Statut:
            <div class="info-properties__value">{{statutProjetValue[selectedProjetInspection.statut]}}</div>
        </div>
        <div class="info-properties">Assigné le:
            <div class="info-properties__value">{{assignationDate | date: 'dd/MM/YYYY' }}
            </div>
        </div>
        <div class="info-properties">Nombre total de poteaux:
            <div class="info-properties__value">{{selectedProjetInspection.nombreTotalPoteaux}}</div>
        </div>
        <div class="info-properties-sm" *ngIf="selectedProjetInspection.typeReseau === typeReseau.TRIPHASE">
            Triphasé seulement</div>
        <button #actionsButton pButton pRipple icon="pi pi-ellipsis-v"
            class="p-button-rounded p-button-secondary actions-button"
            (click)="onActionsButtonClick($event, menu, selectedProjetInspection)"></button>
    </div>
</ng-container>

<p *ngIf="!projetInspectionList.length">
    Aucun projet activé ou téléchargé.
</p>
