<div *ngIf="showForm" class="params__value">
    <form [formGroup]="rechercheForm">
        <div class="value__texte">
            <div class="texte__input">
                <span class="p-input-icon-left p-ml-auto" [style]="{width: '100%'}">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" placeholder="Ex.: DEC 234" formControlName="queryString"
                        (keyup.enter)="onRecherche()" autofocus>
                </span>
            </div>
            <div class="texte__aide">
                <small class="aide__container">
                    <span>Saisir poste et/ou ligne</span>
                </small>
            </div>
        </div>

        <div class="value__checkbox">
            <div class="p-field-checkbox checkbox__container">
                <p-checkbox [formControl]="$any(rechercheForm).controls.includeSig" [binary]="true"></p-checkbox>
                <label [class.disable]="rechercheForm.controls.includeSig.disabled">Inclure SIG</label>
            </div>
        </div>
    </form>
    <app-recherche-results-ligne *ngIf="showResult" [valueHeaderName]="'Poste Ligne'">
    </app-recherche-results-ligne>
</div>
