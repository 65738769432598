<div *ngIf="showForm" class="params__value">
    <form [formGroup]="rechercheForm">
        <div class="value__texte">
            <div class="texte__input">
                <span class="p-input-icon-left p-ml-auto" [style]="{width: '100%'}">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" #queryString [minlength]="inputMinMaxLength"
                        [maxlength]="inputMinMaxLength" placeholder="Ex.: A1B2C" formControlName="queryString"
                        autofocus>
                </span>
            </div>
            <div class="texte__aide">
                <small class="aide__container">
                    <span *ngIf="!displayError || (displayError && rechercheForm.controls.queryString.errors === null)">
                        Min. de {{totalCaracteres}} car.
                    </span>
                    <span *ngIf="displayError && rechercheForm.controls.queryString.errors?.lclcl" class="error">
                        LCLCL invalide
                    </span>
                    <span>{{rechercheForm.controls.queryString.value.length}}/{{totalCaracteres}}</span>
                </small>
            </div>
        </div>

        <div class="value__checkbox">
            <div *ngIf="!isUserMobile" class="p-field-checkbox checkbox__container">
                <p-checkbox [formControl]="$any(rechercheForm).controls.includeSig" [binary]="true"></p-checkbox>
                <label [class.disable]="rechercheForm.controls.includeSig.disabled">Inclure SIG</label>
            </div>
        </div>
    </form>
    <app-recherche-results-cab-lclcl *ngIf="showResult" [isLocalSearch]="isLocalSearch" [isUserMobile]="isUserMobile"
        [rechercheLocalLclcl]="rechercheLocalValue" valueHeaderName="LCLCL">
    </app-recherche-results-cab-lclcl>
</div>
