<p-dialog #dialogue [(visible)]="showCreateProjet" [styleClass]="'custom-dialog'" (onHide)="close()"
    class="p-d-lg-flex p-d-md-flex p-d-none" position="left">
    <ng-template pTemplate="header" class="p-d-flex p-jc-between">
        <div class="p-dialog-title title"> Création de projet</div>
        <div class="bt-groupe"><button type="button" *ngIf="!minimize" class="custom-bt" pButton icon="pi pi-minus"
                (click)="onMinimiseDialogue()"></button>
            <button type="button" *ngIf="minimize" class="custom-bt" pButton icon="pi pi-angle-double-down"
                (click)="onAgrandirDialogue()"></button>
            <button type="button" class="custom-bt" pButton icon="pi pi-times" (click)="close()"></button>
        </div>
    </ng-template>
    <div *ngIf="form" [formGroup]="form" class="p-d-flex p-flex-column">
        <div>
            <div class="p-d-flex p-jc-center p-ai-start">
                <div class="p-d-flex p-flex-column p-mr-1">
                    <div class="p-mb-3">
                        Mode de création&nbsp;<span class="required">*</span>&nbsp;:&nbsp;&nbsp;
                        <p-dropdown [options]="modeCreationList" placeholder="Choisir un mode"
                            formControlName="modeCreation" appendTo="body" optionLabel="label" optionValue="mode"
                            optionDisabled="inactive" (onChange)="onChangeMode()"></p-dropdown>
                    </div>
                    <div class="p-mb-3">
                        Type&nbsp;<span class="required">*</span>&nbsp;:&nbsp;&nbsp;
                        <p-dropdown [options]="projetTypesListe" placeholder="Choisir un type"
                            formControlName="projectType" optionLabel="valeur" optionValue="champ"></p-dropdown>
                    </div>
                    <div class="p-mb-3">
                        Territoire&nbsp;<span class="required">*</span>&nbsp;:&nbsp;&nbsp;
                        <p-dropdown [options]="territoireList" placeholder="Choisir un territoire"
                            formControlName="territory" appendTo="body"></p-dropdown>
                    </div>
                    <ng-container>
                        <div class="p-d-flex p-mb-3">Poste&nbsp;<span class="required">*</span>&nbsp;:&nbsp;&nbsp;
                            <p-inputMask formControlName="poste" placeholder="ABC" mask="aaa"
                                styleClass="input-inline input-border p-text-uppercase" slotChar=""></p-inputMask>
                        </div>
                        <div class="p-d-flex p-mb-3">Ligne&nbsp;<span class="required">*</span>&nbsp;:&nbsp;&nbsp;
                            <p-inputMask formControlName="ligne" placeholder="123" mask="999?*"
                                styleClass="input-inline input-border p-text-uppercase" slotChar=""></p-inputMask>
                        </div>
                        <div class="p-d-flex p-mb-3">
                            Année&nbsp;:&nbsp;&nbsp;
                            <input pInputText type="text" formControlName="annee" class="champ-annee"
                                styleClass="input-inline">
                            <div class="p-ml-3">
                                <button pButton type="button" icon="fas fa-plus"
                                    class="p-button-outlined p-button-sm p-mr-1 p-p-0 button-annee"
                                    (click)="onIncrementAnnee()"></button>
                                <button pButton type="button" icon="fas fa-minus"
                                    class="p-button-outlined p-button-sm p-p-0 button-annee"
                                    (click)="onDecrementAnnee()"></button>
                            </div>
                        </div>
                        <div class="p-d-flex p-mb-3">Nom du projet&nbsp;:&nbsp;&nbsp;
                            <span class="p-mr-0">
                                {{form.controls.territory.value}}_
                            </span>
                            <span class="p-mx-0">
                                {{ form.controls.poste.value ? (form.controls.poste.value|uppercase) : 'ABC'}}_
                            </span>
                            <span class="p-mx-0">
                                {{form.controls.ligne.value ? form.controls.ligne.value: 123}}_
                            </span>
                            <span class="p-ml-0">
                                {{form.controls.annee.value}}
                            </span>
                        </div>
                    </ng-container>
                </div>
                <div class="p-d-flex p-flex-column p-jc-start p-ai-start p-px-2 p-pt-0 p-ml-2">
                    <div class="p-d-flex p-mb-1 p-p-0 p-ai-center">
                        <div class="p-pt-1">Numéro OT&nbsp;:&nbsp;&nbsp;</div>
                        <p-inputMask formControlName="numeroOrdreDeTravail" placeholder="12345678" mask="99999999"
                            class="p-pt-0 p-pb-0" styleClass="input-inline input-border" slotChar=""></p-inputMask>
                    </div>
                    <div class="p-mb-4 p-mt-2">
                        Firme&nbsp;<span *ngIf="form.controls.firme.enabled"
                            class="required">*&nbsp;</span>:&nbsp;&nbsp;
                        <p-dropdown [options]="$any(fournisseurList$|async)" placeholder="Choisir une firme"
                            formControlName="firme" appendTo="body"></p-dropdown>
                    </div>
                    <div class="p-d-flex p-flex-column p-ai-start">
                        <p-checkbox label="Exclure BT" [binary]="true"
                            [formControl]="$any(form.controls['exclureBT'])"></p-checkbox>
                        <p-checkbox *ngIf="form.value.modeCreation === 'ligne'" label="Triphasé seulement"
                            [binary]="true" [formControl]="$any(form.controls['triphase'])"
                            (onChange)="onSelectTriphase()"></p-checkbox>
                    </div>
                    <br>
                    <div class="p-d-flex p-flex-column">
                        <div class="p-mb-1" *ngIf="form.value.modeCreation === 'ligne'">Rayon de la zone
                            tampon&nbsp;:&nbsp;&nbsp; {{form.value.buffer}} m<p-slider formControlName="buffer"
                                (onSlideEnd)="onChangeBuffer()"></p-slider></div>
                        <div class="p-d-flex p-flex-column">
                            <button *ngIf="form.value.modeCreation === 'ligne'" pButton type="button"
                                class="bouton-dessin" label="Rechercher la ligne" icon="fas fa-search"
                                (click)="getLigneFeature()" [disabled]="form.invalid"></button>
                        </div>
                        <div class="p-d-flex p-flex-column p-mb-1">
                            <button *ngIf="form.value.modeCreation === 'polygone'" pButton type="button"
                                class="bouton-dessin" label="Dessiner un polygone" icon="fas fa-draw-polygon"
                                (click)="dessinerPolygone()" [disabled]="form.invalid"></button>
                        </div>
                        <div class="p-d-flex p-flex-column p-mb-1">
                            <button pButton type="button" class="bouton-dessin" label="Visualiser les poteaux"
                                icon="pi pi-eye" (click)="visualise()"
                                [disabled]="disabledBoutonVisualiserPoteaux"></button>
                        </div>
                        <div class="p-d-flex p-flex-column p-mb-1">
                            <button pButton type="button" class="bouton-dessin" label="Effacer" icon="pi pi-undo"
                                (click)="resetResult()"></button>
                        </div>
                        <div *ngIf="nombreDePoteaux && nombreDePoteaux !== null && nombreDePoteaux > 0"
                            class="p-d-flex p-mb-1">
                            <span>
                                Nombre de poteaux inclus: <span class="nbPoteau">{{nombreDePoteaux -
                                    nombrePoteauxExclusManuellement}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-d-flex p-jc-center">
            <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text projet-button"
                (click)="close()"></button>
            <button pButton pRipple label="Sauvegarder le projet" icon="pi pi-save" class="p-button-text"
                (click)="save()"></button>
        </div>
    </div>
</p-dialog>
<p-confirmDialog #cd header="Réinitialisation buffer" icon="pi pi-exclamation-triangle" key="resetBufferConfirm">
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>