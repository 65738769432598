import { Action, createReducer, on } from '@ngrx/store';
import * as RechercheActions from './recherche.actions';
import { RechercheState } from './recherche.state';

export const rechercheInitialState: RechercheState = {
    rechercheIsLoading: false,
    rechercheListe: null,
    rechercheListeSuccess: null,
    rechercheListeError: null,
    rechercheZoom: null,
    rechercheZoomSuccess: null,
    rechercheZoomError: null,
};

export const rechercheReducer = createReducer<RechercheState>(
    rechercheInitialState,
    on(RechercheActions.recherche, (state) => {
        return {
            ...state,
            rechercheIsLoading: true,
            rechercheListe: null,
            rechercheListeSuccess: null,
            rechercheListeError: null,
        };
    }),
    on(RechercheActions.rechercheSuccess, (state, action) => {
        return {
            ...state,
            rechercheIsLoading: false,
            rechercheListe: action.rechercheList,
            rechercheListeSuccess: true,
            rechercheListeError: null,
        };
    }),
    on(RechercheActions.rechercheFailure, (state, action) => {
        return {
            ...state,
            rechercheIsLoading: false,
            rechercheListe: null,
            rechercheListeSuccess: false,
            rechercheListeError: action.error
        };
    }),
    on(RechercheActions.rechercheZoom, (state) => {
        return {
            ...state,
            rechercheIsLoading: true,
            rechercheZoom: null,
            rechercheZoomSuccess: null,
            rechercheZoomError: null,
        };
    }),
    on(RechercheActions.rechercheZoomSuccess, (state, action) => {
        return {
            ...state,
            rechercheIsLoading: false,
            rechercheZoom: action.zoom,
            rechercheZoomSuccess: true,
            rechercheZoomError: null,
        };
    }),
    on(RechercheActions.rechercheZoomFailure, (state, action) => {
        return {
            ...state,
            rechercheIsLoading: false,
            rechercheZoom: null,
            rechercheZoomSuccess: false,
            rechercheZoomError: action.error,
        };
    }),
);

export function reducer(state: any, action: Action) {
    return rechercheReducer(state, action);
}
